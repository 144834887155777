/* You can add global styles to this file, and also import other style files */
/** Colors variables */
/** share button variables */
/** share buttons colors */
/** Colors variables */
/** share button variables */
/** share buttons colors */
@import "./assets/fonts/stylesheet.css";
.sb-group,
.sb-button {
  display: inline-flex;
  align-items: flex-start;
}

.sb-group {
  flex-wrap: wrap;
}

.sb-button {
  margin: var(--sb-margin);
}

/** Default style */
.sb-wrapper {
  font-size: inherit;
  cursor: pointer;
  position: relative;
  outline: 0;
  min-width: 4.125em;
  height: 2.5em;
  border: none;
  border-radius: 1px;
  padding: 0;
  line-height: 2.571em;
  background-color: transparent;
  -webkit-print-color-adjust: exact;
}
.sb-wrapper .sb-icon,
.sb-wrapper .sb-text {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
.sb-wrapper .sb-content {
  flex: 1;
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
}
.sb-wrapper .sb-text {
  flex: 1;
  height: 100%;
  white-space: nowrap;
  padding: 0 0.7em;
}
.sb-wrapper .sb-icon {
  text-align: center;
  width: 100%;
  height: 100%;
  font-size: 1.2em;
  min-width: 2em;
}
.sb-wrapper .sb-text {
  font-weight: bold;
}

.sb-material-dark .sb-wrapper {
  background-color: #2E4057;
  background-color: var(--button-color) !important;
  font-family: "Roboto", sans-serif;
  color: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}
.sb-material-dark .sb-wrapper.sb-facebook {
  background-color: #4267B2;
}
.sb-material-dark .sb-wrapper.sb-twitter {
  background-color: #00acee;
}
.sb-material-dark .sb-wrapper.sb-google {
  background-color: #db4437;
}
.sb-material-dark .sb-wrapper.sb-mix {
  background-color: #ff8226;
}
.sb-material-dark .sb-wrapper.sb-line {
  background-color: #00b900;
}
.sb-material-dark .sb-wrapper.sb-linkedin {
  background-color: #006fa6;
}
.sb-material-dark .sb-wrapper.sb-pinterest {
  background-color: #bd081c;
}
.sb-material-dark .sb-wrapper.sb-reddit {
  background-color: #ff4006;
}
.sb-material-dark .sb-wrapper.sb-tumblr {
  background-color: #36465d;
}
.sb-material-dark .sb-wrapper.sb-whatsapp {
  background-color: #25d366;
}
.sb-material-dark .sb-wrapper.sb-messenger {
  background-color: #0080FF;
}
.sb-material-dark .sb-wrapper.sb-telegram {
  background-color: #0088cc;
}
.sb-material-dark .sb-wrapper.sb-xing {
  background-color: #006567;
}
.sb-material-dark .sb-wrapper.sb-sms {
  background-color: #20c16c;
}
.sb-material-dark .sb-wrapper.sb-email {
  background-color: #FF961C;
}
.sb-material-dark .sb-wrapper.sb-viber {
  background-color: #665ca7;
}
.sb-material-dark .sb-wrapper.sb-vk {
  background-color: #4C75A3;
}
.sb-material-dark .sb-wrapper.sb-copy {
  background-color: #607D8B;
}
.sb-material-dark .sb-wrapper.sb-print {
  background-color: #765AA2;
}
.sb-material-dark .sb-wrapper.sb-expand {
  background-color: #FF6651;
}
.sb-material-dark .sb-wrapper:active {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.sb-material-dark .sb-wrapper.sb-show-icon.sb-show-text {
  padding-left: 0.3em;
}
.sb-material-dark .sb-wrapper.sb-show-icon.sb-show-text .sb-text {
  padding-right: 1em;
  padding-left: 0;
}

/** Colors variables */
/** share button variables */
/** share buttons colors */
/** Colors variables */
/** share button variables */
/** share buttons colors */
.sb-group,
.sb-button {
  display: inline-flex;
  align-items: flex-start;
}

.sb-group {
  flex-wrap: wrap;
}

.sb-button {
  margin: var(--sb-margin);
}

/** Default style */
.sb-wrapper {
  font-size: inherit;
  cursor: pointer;
  position: relative;
  outline: 0;
  min-width: 4.125em;
  height: 2.5em;
  border: none;
  border-radius: 1px;
  padding: 0;
  line-height: 2.571em;
  background-color: transparent;
  -webkit-print-color-adjust: exact;
}
.sb-wrapper .sb-icon,
.sb-wrapper .sb-text {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
.sb-wrapper .sb-content {
  flex: 1;
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
}
.sb-wrapper .sb-text {
  flex: 1;
  height: 100%;
  white-space: nowrap;
  padding: 0 0.7em;
}
.sb-wrapper .sb-icon {
  text-align: center;
  width: 100%;
  height: 100%;
  font-size: 1.2em;
  min-width: 2em;
}
.sb-wrapper .sb-text {
  font-weight: bold;
}

.sb-modern-light .sb-wrapper {
  color: var(--button-color) !important;
  background-color: #fff;
  transition: box-shadow ease-in 150ms, transform ease-in 150ms;
  border-radius: 3px;
  box-shadow: 0 0.1em 0.5em rgba(165, 165, 165, 0.6);
}
.sb-modern-light .sb-wrapper.sb-facebook {
  color: #4267B2;
}
.sb-modern-light .sb-wrapper.sb-twitter {
  color: #00acee;
}
.sb-modern-light .sb-wrapper.sb-google {
  color: #db4437;
}
.sb-modern-light .sb-wrapper.sb-mix {
  color: #ff8226;
}
.sb-modern-light .sb-wrapper.sb-line {
  color: #00b900;
}
.sb-modern-light .sb-wrapper.sb-linkedin {
  color: #006fa6;
}
.sb-modern-light .sb-wrapper.sb-pinterest {
  color: #bd081c;
}
.sb-modern-light .sb-wrapper.sb-reddit {
  color: #ff4006;
}
.sb-modern-light .sb-wrapper.sb-tumblr {
  color: #36465d;
}
.sb-modern-light .sb-wrapper.sb-whatsapp {
  color: #25d366;
}
.sb-modern-light .sb-wrapper.sb-messenger {
  color: #0080FF;
}
.sb-modern-light .sb-wrapper.sb-telegram {
  color: #0088cc;
}
.sb-modern-light .sb-wrapper.sb-xing {
  color: #006567;
}
.sb-modern-light .sb-wrapper.sb-sms {
  color: #20c16c;
}
.sb-modern-light .sb-wrapper.sb-email {
  color: #FF961C;
}
.sb-modern-light .sb-wrapper.sb-viber {
  color: #665ca7;
}
.sb-modern-light .sb-wrapper.sb-vk {
  color: #4C75A3;
}
.sb-modern-light .sb-wrapper.sb-copy {
  color: #607D8B;
}
.sb-modern-light .sb-wrapper.sb-print {
  color: #765AA2;
}
.sb-modern-light .sb-wrapper.sb-expand {
  color: #FF6651;
}
.sb-modern-light .sb-wrapper:active {
  box-shadow: 0 0.4em 1em rgba(165, 165, 165, 0.9);
}
.sb-modern-light .sb-wrapper .sb-icon {
  min-width: 2em;
}
.sb-modern-light .sb-wrapper.sb-show-text {
  padding: 0;
}
.sb-modern-light .sb-wrapper.sb-show-text .sb-icon {
  box-shadow: 1px 0 1px -1px rgba(0, 0, 0, 0.3);
}

body,
.mat-tab-group,
.mat-tab-label,
.mat-expansion-panel-header-title,
.mat-expansion-panel-header-description {
  margin: 0;
  font-family: "Bw Modelica" !important;
}

@font-face {
  font-family: "Bw Modelica";
  src: url("assets/fonts/BwModelica-Black.woff2") format("woff2"), url("assets/fonts/BwModelica-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Bw Modelica";
  src: url("assets/fonts/BwModelica-Bold.woff2") format("woff2"), url("assets/fonts/BwModelica-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Bw Modelica";
  src: url("assets/fonts/BwModelica-Light.woff2") format("woff2"), url("assets/fonts/BwModelica-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Bw Modelica";
  src: url("assets/fonts/BwModelica-Regular.woff2") format("woff2"), url("assets/fonts/BwModelica-Regular.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
* {
  margin: 0;
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  transition: ease-in-out 0.3s;
}

.mat-option,
.mat-tab-label,
.mat-tab-link,
.mat-form-field {
  font-family: "Bw Modelica" !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  color: #202020;
}

label,
span {
  font-size: 14px !important;
}

p {
  color: #a5a5a5;
  font-size: 14px;
}

ul,
ol,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

a,
a:hover,
a:focus {
  cursor: pointer;
  text-decoration: none;
}

.conta_iner {
  width: 95%;
  margin: 0 auto;
  padding: 0;
}

.all_sec {
  padding: 40px 0 0;
}

.blk_span {
  display: block;
}

.mid_sec {
  position: relative;
  width: 100%;
}

figure {
  margin: 0;
}

img {
  max-width: 100%;
}

/*sidebar new scss*/
.sttng_inrwrp {
  width: 100%;
}

.fgf {
  position: absolute;
  left: 15%;
  right: 0;
  top: 5px;
  width: 200px;
  z-index: 9999;
}

.vhl-profile .bck_buttn.rgt_spce {
  display: flex;
  justify-content: end;
}

/*direction RTL scss*/
.direction {
  direction: rtl;
  overflow-x: hidden;
}
.direction .fltr_inrdrp form .srch_dv .cross_icn {
  left: 10px;
  top: 0px;
  right: unset;
}
.direction .tmln_inr .tml_lt .tml_lt_rt .tm_ordr {
  text-align: 12px !important;
}
.direction .tmln_inr::before {
  right: 22px;
  left: unset;
}
.direction .bkodr_trkng_sc .ordr_tmlne {
  padding: 10px;
}
.direction .tmln_inr .tml_lt .tml_lt_rt, .direction .bkdt_usr_dt .bkdt_csmr_lt .csmr_dtl .csmr_dtl_rt {
  padding-left: 0;
  padding-right: 20px;
}
.direction .prnl_pro span.upld_flr {
  right: 38px;
  left: unset;
}
.direction .bdy_wrp.custad_wrp.sttng_wrp {
  width: 12%;
}
.direction .sbs_mn {
  width: 85%;
  margin-left: 0;
  margin-right: auto;
}
.direction .wlk_wrp figure {
  right: -35px;
  top: -35px;
}
.direction .pro_in h6 {
  text-align: right;
  margin-bottom: 10px;
  font-size: 14px;
}
.direction .ad_sm mat-label {
  text-align: right;
  width: 100%;
}
.direction .mat-tab-header {
  flex-direction: row-reverse;
}
.direction .mat-tab-label-container {
  justify-content: end;
}
.direction .rht_cls i.fa.fa-angle-left, .direction i.fa.fa-list {
  transform: rotate(180deg);
}
.direction .rfl-typ {
  display: flex;
  justify-content: start;
}
.direction .rfl-typ span.mat-radio-label-content {
  padding-right: 10px;
  padding-left: 0;
}
.direction .vhl-profile .bck_buttn.rgt_spce {
  display: flex;
  justify-content: end;
}
.direction .pck_drp_inr .pck_lct_dv .ad_stps {
  left: 0;
  right: unset;
}
.direction .pck_drp_inr .pck_lct_dv.drp_lct_dv {
  padding: 30px;
}
.direction .mat-form-field-label-wrapper label {
  right: 6px;
  left: unset;
}
.direction .img-src {
  display: inline-flex;
  justify-content: end;
  width: 100%;
}
.direction .slot-mt {
  flex-direction: row-reverse;
}
.direction .bk_lck_otr .bk_lck_inr::before {
  right: 18px;
  left: unset;
}
.direction .mat-select-arrow {
  position: absolute;
  left: 0;
}
.direction .faeye {
  left: 10px;
  right: unset;
}
.direction .bkdt_usr_dt .bkdt_csmr_lt::after {
  left: 20px;
  right: unset;
}
.direction .form-control,
.direction .slot h1,
.direction .tm-pcr input, .direction .mat-select-value, .direction .bkodr_trkng_sc .ordr_lst_dv .odr_slp_inr h4, .direction .form_control, .direction .pck_drp_inr h3,
.direction .form_control.mat-input-element, .direction .bkdt_usr_dt .bkdt_csmr_lt .csmr_dtl .csmr_dtl_rt h4, .direction .csmr_lt_inr, .direction .tmln_inr .tml_lt .tml_lt_rt .tm_ordr {
  text-align: right;
}
.direction .track_sa {
  gap: 10px;
}
.direction .ad_sm mat-label {
  display: inline-block;
  margin: 10px 0;
}
.direction .pck_drp_inr .pck_lct_dv {
  background-position: right center;
  padding-right: 30px;
}
.direction .sd_br {
  left: unset !important;
  right: 0 !important;
}
.direction nav.pagination,
.direction .fdx {
  justify-content: flex-start;
}
.direction label,
.direction .title-bar_lft h2,
.direction .title-bar_lft p, .direction .bkdt_odr_dt .bkdtl_tp .bk_id h4 {
  text-align: right;
}
.direction .bkdt_usr_dt .bkdt_csmr_lt .cntdtl_inr figure {
  margin-left: 28px;
}
.direction .sb_br_mn {
  width: 8%;
}
.direction .flts_rgt {
  justify-content: start;
}
.direction .right_slide {
  left: 0 !important;
  right: unset !important;
}
.direction .side_br {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  top: 0px;
  right: 100%;
}
.direction .rht_cls {
  top: 0px;
  left: unset;
  right: -36px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.direction .lead {
  margin-right: 10px;
}
.direction .bkng_fd .lft h2 {
  margin-right: 20px;
  justify-content: end;
}
.direction .tm_lt figure {
  margin: 0 0 0 5px;
}
.direction ul.tm_bx li mat-panel-title {
  margin: 0 0 0 10px;
}
.direction .bdy_wrp {
  padding: 0 0 0 20px;
}
.direction .content_wraper {
  margin-right: 184px;
  width: 90%;
  padding: 50px 20px 0px 0px;
}
.direction .sdbr_lt {
  right: 0;
  width: 10.5%;
}
@media (max-width: 1300px) {
  .direction .sdbr_lt {
    width: 15.5%;
  }
}
.direction .cstaed_lt .cstaed_inr h3 {
  text-align: right;
}
.direction div#wrppr_fdx {
  justify-content: end;
}
.direction .fgf {
  right: 15%;
  left: 0;
}
.direction .sidebar_open i.fa.fa-bars {
  right: 13%;
  left: unset !important;
}
.direction .mini_sidebar i.fa.fa-bars {
  right: 6%;
}
.direction .pg_hd .mn_hd h1 {
  text-align: right;
}
.direction .pg_hdbutton {
  flex-direction: unset;
}
.direction .mn_hd p {
  text-align: right;
}
.direction .pcup_cls.bk_shw .bkdtl_tp .cmpltd_btsn {
  padding: 0;
}
.direction .bkng_lstb_dtl .bkng_dtl_inr .bkdtl_tp h2 {
  width: 60%;
}
.direction .bkng_lstb_dtl .bkng_dtl_inr .bkdtl_tp h2 span {
  margin-top: 10px;
}

/*direction RTL scss end*/
/*sidebar new scss end*/
/************************************newbg  css  ************************************/
.cmn_cmnt {
  position: relative;
  z-index: 2;
}

.sdbr_lt mat-nav-list {
  height: 100% !important;
  overflow: auto;
  padding-left: 5px;
}
.sdbr_lt mat-nav-list .lt_sidebar {
  height: 100%;
}
.sdbr_lt mat-nav-list .mat-list-base .mat-list-item,
.sdbr_lt mat-nav-list .mat-list-base .mat-list-option {
  height: 42px;
}

.order_status_wrap {
  background: #fff;
  border-radius: 10px;
  padding: 10px;
  position: relative;
  margin-top: 30px;
}

/************************************newbg  css  ************************************/
.mn_hd h1 {
  font-size: 22px;
  line-height: normal;
  font-weight: bold;
  color: #202020;
}

.pg_hd {
  margin: 10px 0 0;
}

.srch_inpt {
  padding: 13px 10px 13px 50px !important;
}

table.table tr th {
  white-space: nowrap;
}

.btn {
  border: unset;
  box-shadow: unset !important;
  background: #202020;
  color: #fff;
  border-radius: 4px;
  line-height: normal;
  font-weight: 400;
  font-size: 11px;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn.btn-primary {
  background: var(--primaryColor);
  color: #fff;
  white-space: nowrap;
}

.btn.btn-primary:hover {
  background: var(--secondaryColor);
  color: #fff;
}

.btn.btn-secondary {
  background: var(--secondaryColor);
  color: #fff;
}

.btn.btn-secondary:hover {
  background: var(--primaryColor);
  color: #fff;
}

.btn.btn-secondarys {
  background: #2323b3;
  color: #fff;
}

.btn.btn-secondarys:hover {
  background: #5353d7;
  color: #fff;
}

.ad_bkng_btn {
  padding: 5px;
  width: 100%;
}

.bkng_add button {
  background: var(--secondaryColor);
  color: #fff;
}

.bkng_lft .bkng_add {
  padding: 10px;
  width: 90%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 30px;
  z-index: 1;
  margin: 0 auto;
  background: #fff;
}
.bkng_lft .bkng_add button {
  width: auto;
  font-size: 14px;
  font-weight: 500;
  margin: 0 auto;
}

.bkng_map #map > div {
  height: 100vh !important;
}

.mat-menu-content {
  padding-top: 0 !important;
}

.mat-menu-item {
  height: 32px !important;
}

.mat-tab-label {
  border: solid 1px #e5e5e5;
  border-radius: 3px;
  background: #fff;
  padding: 10px;
  margin-right: 10px;
  opacity: 1;
  color: #818181;
  line-height: normal;
  font-weight: 400;
  font-size: 16px;
  text-transform: capitalize;
  padding: 5px 15px !important;
  min-width: auto !important;
  height: auto !important;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: unset;
}

.bkng_tbg {
  margin: 10px 0;
}

mat-ink-bar.mat-ink-bar {
  display: none !important;
}

.mat-tab-label.mat-tab-label-active {
  background: #00649e;
  opacity: 1;
  color: #fff;
  border-color: #00649e;
}

label {
  display: block;
  font-weight: 400;
  color: #202020;
  margin-bottom: 0;
  padding: 10px;
}
label span.red {
  color: red;
  font-weight: bold;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: var(--primaryColor);
}

div#wrppr_fdx {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  position: relative;
}
div#wrppr_fdx app-settings-sidebar {
  width: 20%;
}
div#wrppr_fdx app-general,
div#wrppr_fdx app-customize,
div#wrppr_fdx app-auto-allocate,
div#wrppr_fdx app-plugins,
div#wrppr_fdx app-add-plugins,
div#wrppr_fdx app-api,
div#wrppr_fdx app-web-hooks,
div#wrppr_fdx app-billing,
div#wrppr_fdx app-tags,
div#wrppr_fdx app-documents,
div#wrppr_fdx app-bank-form,
div#wrppr_fdx app-cms,
div#wrppr_fdx app-sub-admin,
div#wrppr_fdx app-walkthrough,
div#wrppr_fdx app-forms,
div#wrppr_fdx app-external-fleet,
div#wrppr_fdx app-fleet-documents,
div#wrppr_fdx app-airport-charge,
div#wrppr_fdx app-b2b-managemant,
div#wrppr_fdx app-question-answer,
div#wrppr_fdx app-vehicle,
div#wrppr_fdx app-tax,
div#wrppr_fdx app-new-plugin {
  width: 75%;
}

.d_wrap_ryt {
  width: 100%;
  padding-top: 16px;
}

.mat-form-field-appearance-fill .mat-form-field-subscript-wrapper,
.mat-form-field-underline {
  display: none;
}

.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0;
  border: unset;
}

.mat-form-field.mat-form-field-appearance-fill .mat-form-field-flex {
  border-radius: 0;
  padding: 0;
  background: transparent;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper {
  padding-bottom: 0;
}

.mat-form-field-label-wrapper label {
  top: unset !important;
  margin: 0 !important;
  left: 6px;
}

.mat-form-field-label-wrapper {
  top: 0;
  padding-top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.bkng_tbg .mat-tab-label .mat-tab-label-content {
  font-size: 12px;
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 0;
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0;
  border: unset;
}

.form_control,
.form_control.mat-input-element {
  font-size: 14px;
  line-height: normal;
  background: #46464617;
  padding: 10px 15px;
  border-radius: 4px;
  width: 100%;
  margin: 0;
  resize: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  color: #202020;
  font-weight: 400;
  border: unset;
  box-sizing: border-box;
}

.ng_pending {
  background-color: #8658c1;
  border: unset;
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
  padding: 5px 10px;
  font-weight: 500;
}

.form_control.mat-input-element.srch_inpt,
.form_control.srch_inpt {
  background: #fff url(assets/images/search.svg);
  background-repeat: no-repeat;
  background-position: left 10px center;
  padding-left: 55px;
  background-size: 22px;
}

td.ac_btn {
  display: flex;
  gap: 5px;
}
td.ac_btn button {
  border-radius: 30px;
  font-size: 12px;
  width: 90px;
  padding: 7px 12px;
}

nav.navbar.navbar-expand-md {
  background: var(--primaryColor);
  padding: 5px 30px;
  max-height: 62px;
  position: fixed;
  width: 100%;
  z-index: 99;
}
nav.navbar.navbar-expand-md .nv_hd_rt {
  justify-content: center;
  position: relative;
}
nav.navbar.navbar-expand-md .nv_hd_rt i.fa.fa-bars {
  position: absolute;
  left: 13%;
  top: 15px;
  font-size: 20px;
  cursor: pointer;
}
nav.navbar.navbar-expand-md .nv_hd_rt .navbar-nav li {
  margin: 0 15px;
}
nav.navbar.navbar-expand-md .nv_hd_rt .navbar-nav li a {
  color: var(--textColor);
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  padding: 15px 10px;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
nav.navbar.navbar-expand-md .nv_hd_rt .navbar-nav li a::before {
  content: "";
  position: absolute;
  bottom: -1px;
  width: auto;
  height: 5px;
  border-radius: 50px;
  left: 5px;
  right: -5px;
  background: transparent;
}
nav.navbar.navbar-expand-md .nv_hd_rt .navbar-nav li a span {
  padding-right: 8px;
}
nav.navbar.navbar-expand-md .nv_hd_rt .navbar-nav li a span svg path {
  stroke: var(--textColor);
}
nav.navbar.navbar-expand-md .nv_hd_rt .navbar-nav li a.active::before,
nav.navbar.navbar-expand-md .nv_hd_rt .navbar-nav li a:hover::before {
  background-color: #fff;
}

.bdy_wrp {
  height: 100%;
  overflow: auto;
  padding: 20px;
}

.status_configuration .col-md-6 {
  margin-bottom: 10px;
}

nav.navbar.navbar-expand-md .nv_hd_rt .navbar-nav li ul li {
  margin: 0 0px 10px;
  padding: 0 10px;
}
nav.navbar.navbar-expand-md .nv_hd_rt .navbar-nav li ul li a {
  color: var(--textColor);
  padding: 0px;
  font-size: 18px;
  font-weight: 400;
}
nav.navbar.navbar-expand-md .nv_hd_rt .navbar-nav li ul li a:hover {
  color: var(--secondaryColor);
}
nav.navbar.navbar-expand-md .nv_hd_rt .navbar-nav li ul li a:before {
  content: unset;
}

/**dashborad page**/
.sidebar_open {
  position: relative;
}
.sidebar_open i.fa.fa-bars {
  position: fixed;
  left: 13%;
  z-index: 999;
  top: 15px;
  font-size: 20px;
  cursor: pointer;
  color: #fff;
}

app-status-configuration {
  width: 80%;
  margin-top: 30px;
}

.count_fdx {
  display: flex;
  align-items: center;
  gap: 15px;
}

li.dash_antk_inr p {
  color: #fff;
  font-size: 18px !important;
  padding-top: 20px;
  font-weight: 500;
}

.dash_anltc {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}
.dash_anltc .dash_antk_inr {
  padding: 20px;
  box-shadow: 0 2px 20px #0000000d;
  width: 18%;
  min-height: 150px;
  border-radius: 14px;
}
.dash_anltc .dash_antk_inr h2 {
  font-size: 24px !important;
  line-height: normal;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.dash_anltc .dash_antk_inr .antk_inrdcrmt {
  display: flex;
  align-items: stretch;
  justify-content: center;
  margin-top: 10px;
}
.dash_anltc .dash_antk_inr .antk_inrdcrmt .ant_updn {
  background: #ddd;
  display: block;
  padding: 7px;
  border-radius: 8px;
  color: #202020;
  margin-right: 10px;
}
.dash_anltc .dash_antk_inr .antk_inrdcrmt .ant_updn:last-child {
  margin-right: 0;
}
.dash_anltc .dash_antk_inr .antk_inrdcrmt .ant_updn.decrement {
  background: #cce0ec;
}
.dash_anltc .dash_antk_inr .antk_inrdcrmt .ant_updn.increment {
  background: #ccd2d6;
}

ul.dash_anltc li.dash_antk_inr h2 {
  color: #fff;
  font-weight: 500;
}

.dsh_mid_mn h3 {
  font-size: 16px;
  line-height: normal;
  font-weight: bold;
  color: #202020;
}
.dsh_mid_mn p {
  font-size: 16px;
  line-height: normal;
  font-weight: 400;
  color: #a5a5a5;
  margin-top: 5px;
}

.dsh_ttl_ern h2 {
  font-size: 40px;
  line-height: normal;
  font-weight: 700;
  margin-bottom: 20px;
}
.dsh_ttl_ern button {
  margin-top: 30px;
}

.dsh_mid_sc {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}
.dsh_mid_sc .dsh_ttl_ern,
.dsh_mid_sc .dsh_dlyrvnu {
  width: 40%;
}
.dsh_mid_sc .dsh_ttl_reven {
  width: 18%;
}

.dsh_dlyrvnu figure {
  margin-top: 13px;
}

.mat_piker {
  position: relative;
  transform: translateY(34px);
}
.mat_piker .mat-date-range-input-container {
  display: flex;
  align-items: center;
  padding: 7px;
}
.mat_piker mat-datepicker-toggle.mat-datepicker-toggle {
  background: #46464617;
  border-radius: 10px;
  width: 100%;
  padding: 8px;
}
.mat_piker button.mat-focus-indicator.mat-icon-button.mat-button-base {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mat_piker mat-date-range-input.mat-date-range-input {
  position: absolute;
  bottom: 41px;
  left: 45px;
  width: 80%;
}

.drp_pci_lst {
  margin-top: 15px;
}
.drp_pci_lst .drp_pci_inr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px #f5f5f5;
  padding: 0 0 10px 0;
  margin-bottom: 10px;
}
.drp_pci_lst .drp_pci_inr h4,
.drp_pci_lst .drp_pci_inr h5 {
  color: #202020;
  font-size: 18px;
  line-height: normal;
  font-weight: 500;
  position: relative;
  z-index: 1;
}
.drp_pci_lst .drp_pci_inr h4 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 15px;
}
.drp_pci_lst .drp_pci_inr h4::before {
  content: "";
  position: absolute;
  left: 0;
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background: #202020;
}
.drp_pci_lst .drpoff h4::before {
  background: #36b368;
}
.drp_pci_lst .pckup h4::before {
  background: #2ca3e2;
}

.actives .sidenav-item h5.lead {
  color: #fff;
  font-weight: 600;
}

.sd_b_pt.actives i {
  color: #fff;
  opacity: 100;
  filter: revert;
}

.dash_wdb_sc {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}
.dash_wdb_sc .wrng_dv,
.dash_wdb_sc .dhbkn_dv {
  width: 45%;
  max-height: 500px;
}
.dash_wdb_sc .dhdrvr_dv {
  width: 53%;
  max-height: 500px;
}

.bkng_grp_lst {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.dhdrvr_ntn {
  margin-top: 30px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column-reverse;
}
.dhdrvr_ntn figure {
  text-align: center;
}
.dhdrvr_ntn .ntn_cntn {
  font-size: 22px;
  line-height: normal;
  font-weight: bold;
  margin-right: 20px;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 26px;
}
.dhdrvr_ntn .ntn_cntn::before {
  content: "";
  position: absolute;
  left: 0;
  height: 14px;
  width: 14px;
  background: #000;
  border-radius: 50%;
}
.dhdrvr_ntn .idel span {
  color: #f06353;
}
.dhdrvr_ntn .idel span::before {
  background: #f06353;
}
.dhdrvr_ntn .onroute span {
  color: #005365;
}
.dhdrvr_ntn .onroute span::before {
  background: #005365;
}
.dhdrvr_ntn .logedin h3 span {
  color: #44929f;
}
.dhdrvr_ntn .logedin h3 span::before {
  background: #44929f;
}
.dhdrvr_ntn .logedoff h3 span {
  color: #c29400;
}
.dhdrvr_ntn .logedoff h3 span::before {
  background: #c29400;
}
.dhdrvr_ntn .ntn_inr {
  margin-bottom: 10px;
}
.dhdrvr_ntn .ntn_inr h3 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.ntn_lst {
  margin-top: 20px;
}

.dhbkn_dv figure,
.dhbkn_dv figure img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.wrng_dh_inr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f8f8f8;
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 20px;
}
.wrng_dh_inr:last-child {
  margin-bottom: 0;
}
.wrng_dh_inr h3 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.wrng_dh_inr h3 figure {
  background: #e9e9e9;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.wrng_dh_rt {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100px;
}

.ttl_ntfy.count {
  color: #f06353;
}

.ttl_ntfy {
  background: #fff;
  height: 30px;
  width: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #a5a5a5;
  font-size: 16px;
  line-height: normal;
  font-weight: 400;
  margin-right: 10px;
}

.wrng_dh_mn {
  margin-top: 30px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}
.wrng_dh_mn .wrng_dh_lft {
  width: 50%;
  border-right: solid 2px #efefef;
  padding-right: 15px;
}
.wrng_dh_mn .wrng_dh_rgt {
  width: 50%;
  /* padding-left: 15px; */
  padding: 0px 10px;
  max-height: 380px;
  overflow-y: auto;
}

.signup_frm .frm_btn button {
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.2px;
}

.wrng_dh_cntn {
  display: flex;
  background: #fdf3f2;
  padding: 15px;
  border-radius: 6px;
  flex-wrap: wrap;
  gap: 8px;
}
.wrng_dh_cntn:last-child {
  margin-bottom: 0;
}
.wrng_dh_cntn h4,
.wrng_dh_cntn p {
  color: #f06353;
  line-height: normal;
  font-weight: 400;
  font-size: 16px;
}
.wrng_dh_cntn h4 {
  width: 70%;
}
.wrng_dh_cntn h4 span {
  margin-top: 5px;
}
.wrng_dh_cntn p {
  width: 28%;
  text-align: right;
}

/**booking page css**/
.drvlst_tpdv {
  background: #002033;
  border-radius: 14px;
  padding: 30px 30px;
  width: 400px;
  min-height: 120px;
}
.drvlst_tpdv ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.drvlst_tpdv ul li {
  color: #fff;
  font-size: 26px;
  line-height: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.drvlst_tpdv ul li span {
  font-size: 14px;
  line-height: normal;
  font-weight: 400;
  padding-left: 6px;
}

.drvlst_info {
  background: #f8f8f8;
  padding: 20px;
  border-radius: 6px;
  width: 95%;
  margin: 0 auto 0 0;
}
.drvlst_info .drv_infotp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.drvlst_info .drv_infotp figure {
  height: 50px;
  width: 50px;
  overflow: hidden;
  border-radius: 50%;
}
.drvlst_info .drv_infotp figure img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: top;
}
.drvlst_info .drv_infotp .drv_tp_rt {
  margin-left: 15px;
}
.drvlst_info .drv_infotp .drv_tp_rt h3 {
  font-size: 20px;
  line-height: normal;
  font-weight: 400;
  margin-bottom: 2px;
}
.drvlst_info .drv_infotp .drv_tp_rt h4 {
  font-size: 16px;
  line-height: normal;
  font-weight: 400;
  position: relative;
  z-index: 1;
  color: #202020;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 15px;
}
.drvlst_info .drv_infotp .drv_tp_rt h4::before {
  content: "";
  position: absolute;
  left: 0;
  height: 10px;
  width: 10px;
  background: #202020;
  border-radius: 50%;
}
.drvlst_info .drv_infobttm {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin-top: 15px;
}
.drvlst_info .drv_infobttm h4 {
  color: #202020;
  line-height: normal;
  font-weight: 400;
  font-size: 18px;
}
.drvlst_info .drv_infobttm h4 span {
  color: #a5a5a5;
  line-height: normal;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 2px;
}

.slotModall2 {
  width: 35vw !important;
}

.drvlst_inr {
  background: #fff;
  padding: 20px;
  border-radius: 14px;
  box-shadow: 0 2px 20px #0000000d;
  position: relative;
  top: -40px;
}
.drvlst_inr .drvlst_hdmn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.drvlst_inr .drvlst_hdmn h3 {
  font-size: 20px;
  line-height: normal;
  font-weight: 400;
  color: #202020;
}
.drvlst_inr .drvlst_hdmn a.view_all {
  font-size: 16px;
  font-weight: bold;
  line-height: normal;
  color: #202020;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 22px;
}
.drvlst_inr .drvlst_hdmn a.view_all::after {
  content: "";
  position: absolute;
  right: 0;
  border-top: solid 8px #c4c4c4;
  border-left: solid 8px transparent;
  border-right: solid 8px transparent;
}

.bkng_sc {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  /* height: calc(100vh - 60px);*/
}
.bkng_sc .bkng_map {
  height: 100%;
}
.bkng_sc .crt_bkng .bkng_sc .bkng_lft {
  width: 25%;
  background: #fff;
}
.bkng_sc .bkcrt_dtl {
  padding: 20px;
}
.bkng_sc .bkng_rgt {
  position: relative;
  z-index: 1;
  width: 75%;
}
.bkng_sc .bkng_rgt .bkng_drvlst {
  top: 10px;
  position: absolute;
  transform: translate(-600px, 10px);
  right: 100px;
}
.bkng_sc .bkng_rgt .bkng_map figure {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.bkng_sc .bkng_rgt .bkng_map figure img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/*new slide css*/
.side_br {
  background: #ffffff;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  color: #0d0505;
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: -35px;
}

/*new slide css end*/
.src_fltr_otr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.src_fltr_otr .srch_dv {
  width: 90%;
}
.src_fltr_otr .srch_dv mat-form-field.mat-form-field {
  width: 100%;
}
.src_fltr_otr a.flt_inr {
  height: 50px;
  width: 50px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.form_control.srch_inpt + span.mat-form-field-label-wrapper label {
  left: 55px;
}

.bkdrv_dtl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.bkdrv_dtl figure {
  height: 60px;
  width: 60px;
  overflow: hidden;
  border-radius: 50%;
}
.bkdrv_dtl figure img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.bkng_lstb_dtl {
  margin-top: 30px;
  max-height: calc(100vh - 130px);
  overflow-y: auto;
  padding-right: 5px;
}
.bkng_lstb_dtl .bkng_dtl_inr {
  background: #fff;
  padding: 15px;
  border-radius: 7px;
  cursor: pointer;
  box-shadow: 0px 0px 3px 1px #0000001f;
  margin: 5px 0px 15px 10px;
  position: relative;
  cursor: pointer;
}
.bkng_lstb_dtl .bkng_dtl_inr .bkdtl_tp {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #dedede61;
  position: relative;
}
.bkng_lstb_dtl .bkng_dtl_inr .bkdtl_tp h2 {
  color: #000;
  line-height: normal;
  margin-bottom: 10px;
  font-size: 15px !important;
  font-weight: 600;
}
.bkng_lstb_dtl .bkng_dtl_inr .bkdtl_tp h2 span {
  display: block;
  margin-top: 10px;
  font-size: 12px !important;
}
.bkng_lstb_dtl .bkng_dtl_inr .bkdtl_tp .bk_id h4 {
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin-top: 3px;
  color: #202020;
}
.bkng_lstb_dtl .bkng_dtl_inr .bkdtl_tp .bk_id h3 {
  font-size: 14px;
  line-height: normal;
  font-weight: 700;
  color: #202020;
}
.bkng_lstb_dtl .bkng_dtl_inr .lst_chd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0px 0px;
  width: 100%;
}
.bkng_lstb_dtl .bkng_dtl_inr .lst_chd .asgn_lft {
  width: 90%;
}
.bkng_lstb_dtl .bkng_dtl_inr .lst_chd .asgn_lft .mat-form-field {
  width: 100%;
}
.bkng_lstb_dtl .bkng_dtl_inr .lst_chd .asgn_lft .mat-select {
  padding: 10px 5px !important;
  border-radius: 7px;
}
.bkng_lstb_dtl .bkng_dtl_inr .lst_chd .asgn_ryt {
  width: 12%;
  margin-right: 5px;
}
.bkng_lstb_dtl .bkng_dtl_inr .lst_chd .asgn_ryt .bk_tsh {
  background: #fff;
  border-radius: 50%;
  box-shadow: 0px 0px 7px 4px #00000014;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
}

.bkng_fd .lft h2 {
  font-size: 14px !important;
  font-weight: 600;
  display: flex;
  align-items: flex-start;
  gap: 5px;
  width: 108px;
}

.bkng_fd .mid p {
  font-size: 13px !important;
  color: #000;
  font-weight: 400;
  line-height: 18px;
}

.bkng_fd .ryt h2 {
  font-size: 15px !important;
  font-weight: 600;
  color: #000;
}

.bkng_fd {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  border-bottom: 1px solid #dedede61;
  padding: 10px 0px;
  width: 100%;
}
.bkng_fd .lft {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 75%;
  gap: 10px;
}
.bkng_fd .lft figure {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.bkng_fd .mid {
  width: 40%;
}
.bkng_fd .mid p span {
  display: block;
}
.bkng_fd .ryt {
  width: 18%;
  text-align: right;
}

.bk_lck_otr {
  margin-bottom: 20px;
}
.bk_lck_otr .bk_lck_inr {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: 20px;
  position: relative;
  z-index: 1;
  font-size: 12px;
  line-height: normal;
  font-weight: 400;
  color: #202020;
}
.bk_lck_otr .bk_lck_inr:last-child {
  padding: 0;
}
.bk_lck_otr .bk_lck_inr .bk_dte {
  line-height: normal;
  color: #a5a5a5;
  font-size: 14px;
  font-weight: 400;
  padding-right: 40px;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  min-width: 110px;
}
.bk_lck_otr .bk_lck_inr .bk_dte::after {
  content: "";
  position: absolute;
  right: 15px;
  height: 8px;
  width: 8px;
  background: #202020;
  border-radius: 50%;
  top: 5px;
}
.bk_lck_otr .bk_lck_inr::before {
  content: "";
  position: absolute;
  left: 90px;
  top: 16px;
  width: 1px;
  background: #202020;
  bottom: 0px;
}
.bk_lck_otr .bk_lck_inr:last-child::before {
  content: unset;
}
.bk_lck_otr .bk_lck_inr.drpof .bk_dte::after {
  background: #002033;
}

.bk_drv_asgn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bk_drv_asgn .trash_spn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background: #fff;
  box-shadow: 0 2px 20px #0000000d;
  border-radius: 50%;
  cursor: pointer;
}

.bk_stus button.btn {
  padding: 5px 15px;
}

.bk_stus.cancel button.btn {
  /* background: #FF5252; */
  color: #fff;
}

.cdk-overlay-container .cdk-overlay-pane .mat-select-panel.drvr_optn .mat-optgroup-label,
.cdk-overlay-container .cdk-overlay-pane .mat-select-panel.drvr_optn .mat-option {
  font-size: inherit;
  line-height: normal !important;
  height: auto !important;
  padding: 0;
}

.bkdrv_dtl_rt {
  width: 90%;
  padding-left: 15px;
}
.bkdrv_dtl_rt h3 {
  font-size: 16px;
  line-height: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bkdrv_dtl_rt h3 .drv_stts {
  color: #36b368;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  line-height: normal;
  padding-left: 12px;
}
.bkdrv_dtl_rt h3 .drv_stts::before {
  content: "";
  position: absolute;
  left: 0;
  height: 8px;
  width: 8px;
  background: #36b368;
  border-radius: 50%;
}
.bkdrv_dtl_rt h4 {
  font-size: 14px;
  line-height: normal;
  font-weight: 400;
  margin-top: 5px;
}
.bkdrv_dtl_rt h4 span {
  color: #a5a5a5;
  padding-right: 5px;
}

.drv_ens_sc .mat-tab-label.mat-tab-label-active {
  background: #000 !important;
}

.asgn_drv {
  width: 100%;
  position: relative;
}
.asgn_drv mat-form-field.mat-form-field {
  width: 100%;
}
.asgn_drv mat-form-field.mat-form-field mat-select {
  /* background: #F8F8F8; */
  background: #46464617;
  padding: 15px 40px;
}
.asgn_drv .bk_tsh {
  position: absolute;
  top: 8px;
  right: 5px;
}
.asgn_drv .bk_tsh .trash_spn {
  background: none;
  box-shadow: unset;
  width: 30px;
  height: 30px;
}

figure.nv_logo {
  width: 100px;
}
figure.nv_logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  border-radius: 7px;
}

button.btn.auto_assign {
  background: #36b368;
  color: #fff;
  padding: 4px 7px;
  font-size: 12px;
}

.drvlst_info.online h4.drv_stus {
  color: #36b368;
}
.drvlst_info.online h4.drv_stus::before {
  background: #36b368;
}

.fltr_inrdrp .from-group.dtpcik_dv .date_hdr_dv .mat-form-field-flex {
  padding: 0;
  background: transparent;
  border-radius: 0;
}
.fltr_inrdrp .from-group.dtpcik_dv .date_hdr_dv {
  /* background: #f8f8f8; */
  background: #46464617;
}

.fltr_inrdrp {
  right: 0;
  background: #fff;
  border-radius: 5px;
  padding: 5px;
  z-index: 1;
}
.fltr_inrdrp .from-group.dtpcik_dv .mat-form-field-flex {
  padding: 15px 15px;
  background: #f8f8f8;
  border-radius: 14px;
}
.fltr_inrdrp .from-group {
  width: 46%;
}
.fltr_inrdrp form {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fltr_inrdrp form .srch_dv {
  width: 90%;
  position: relative;
}
.fltr_inrdrp form .srch_dv mat-form-field.mat-form-field {
  width: 100%;
}
.fltr_inrdrp form .srch_dv input {
  background-color: unset;
}
.fltr_inrdrp form .srch_dv .cross_icn {
  position: absolute;
  right: 10px;
  top: 8px;
  cursor: pointer;
}
.fltr_inrdrp li.inte_inr figure {
  width: 100px;
  height: 50px;
  transform: translate(0px, -10px);
}
.fltr_inrdrp .from-group .form_control {
  background: #46464617;
}
.fltr_inrdrp .apply_btn_inr {
  border: unset;
  background: #002033;
  height: 43px;
  width: 52px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fltr_inrdrp .from-group.apply_btn {
  width: 50px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.fltr_inrdrp .from-group mat-form-field.mat-form-field {
  width: 100%;
}

.fltr_inrdrp .from-group {
  position: relative;
}
.fltr_inrdrp .from-group label {
  position: absolute;
  top: -30px;
}

.bkdt_odr_dt {
  background: #f8f8f8;
  padding: 20px;
  border-radius: 14px;
}
.bkdt_odr_dt .bkdtl_tp {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
}
.bkdt_odr_dt .bkdtl_tp .bk_id h3 {
  font-size: 20px;
}
.bkdt_odr_dt .bkdtl_tp .bk_id h4 {
  font-size: 18px;
  line-height: normal;
  font-weight: 400;
  margin-top: 10px;
}

ul.inte_otr figure {
  width: 100px;
  height: 50px;
}

.bkdt_usr_dt {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin: 30px 0px;
}
.bkdt_usr_dt .bkdt_csmr_lt {
  width: 30%;
  position: relative;
  z-index: 1;
}
.bkdt_usr_dt .bkdt_csmr_lt .csmr_dtl {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}
.bkdt_usr_dt .bkdt_csmr_lt .csmr_dtl figure {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
}
.bkdt_usr_dt .bkdt_csmr_lt .csmr_dtl figure img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.bkdt_usr_dt .bkdt_csmr_lt .csmr_dtl .csmr_dtl_rt {
  padding-left: 20px;
}
.bkdt_usr_dt .bkdt_csmr_lt .csmr_dtl .csmr_dtl_rt h3 {
  font-size: 20px;
}
.bkdt_usr_dt .bkdt_csmr_lt .csmr_dtl .csmr_dtl_rt h4 {
  font-size: 16px;
  line-height: normal;
  font-weight: 400;
  margin-top: 5px;
  width: 230px;
}
.bkdt_usr_dt .bkdt_csmr_lt .cntdtl_inr {
  margin-bottom: 15px;
  font-size: 18px;
  line-height: normal;
  font-weight: 400;
  color: #202020;
  display: flex;
  contain: content;
  justify-content: flex-start;
}
.bkdt_usr_dt .bkdt_csmr_lt .cntdtl_inr:last-child {
  margin-bottom: 0;
}
.bkdt_usr_dt .bkdt_csmr_lt .cntdtl_inr figure {
  margin-right: 8px;
}
.bkdt_usr_dt .bkdt_csmr_lt::after {
  content: "";
  position: absolute;
  right: 20px;
  background: #ddd;
  width: 1px;
  top: 0;
  bottom: 0;
}
.bkdt_usr_dt .bkdt_csmr_lt:last-child::after {
  content: unset;
}

.bkodr_trkng_sc {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}
.bkodr_trkng_sc .ordr_lst_dv {
  width: 28%;
}
.bkodr_trkng_sc .ordr_lst_dv .odr_slp_inr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.bkodr_trkng_sc .ordr_lst_dv .odr_slp_inr h4 {
  width: 50%;
  font-size: 16px !important;
  line-height: normal;
  font-weight: 400;
}
.bkodr_trkng_sc .ordr_lst_dv .odr_slp_inr h4:last-child {
  text-align: right;
}
.bkodr_trkng_sc .ordr_lst_dv .odr_slp_inr:last-child {
  margin-bottom: 0;
}
.bkodr_trkng_sc .ordr_lst_dv .odr_slp_inr.ttl_amnt {
  border-top: solid 1px #a5a5a5;
  padding-top: 16px;
  margin-top: 20px;
}
.bkodr_trkng_sc .ordr_lst_dv .odr_slp_inr.ttl_amnt h3 {
  font-size: 20px;
  font-weight: bold;
  width: 50%;
}
.bkodr_trkng_sc .ordr_lst_dv .odr_slp_inr.ttl_amnt h3:last-child {
  text-align: right;
}
.bkodr_trkng_sc .ordr_tmlne {
  width: 25%;
  border: 1px solid #a3a3a31a;
  padding: 5px;
  border-radius: 7px;
}
.bkodr_trkng_sc .ord_tck_dv {
  width: 42%;
  border: 1px solid #a3a3a31a;
  padding: 5px;
  border-radius: 7px;
}

.tmln_otr {
  padding-top: 15px;
}

.tmln_inr {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  z-index: 1;
  padding-bottom: 25px;
}
.tmln_inr figure {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  background: #efefef;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tmln_inr .tml_lt {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.tmln_inr .tml_lt .tml_lt_rt {
  padding-left: 20px;
}
.tmln_inr .tml_lt .tml_lt_rt .tm_ordr {
  display: block;
  color: #a5a5a5;
  line-height: normal;
  margin-bottom: 2px;
  font-size: 14px;
}
.tmln_inr .tml_lt .tml_lt_rt h3 {
  font-size: 16px;
  line-height: normal;
  font-weight: 600;
}
.tmln_inr .tml_rt {
  width: 20%;
  text-align: right;
}
.tmln_inr::before {
  content: "";
  position: absolute;
  left: 22px;
  top: 0px;
  bottom: 0;
  background: #dbdbdb;
  width: 2px;
  z-index: -1;
}
.tmln_inr:last-child::before {
  content: unset;
}

.tmln_inr.active figure {
  background: #00649e;
}
.tmln_inr.active figure img {
  filter: grayscale(1) brightness(10);
}
.tmln_inr.active::before {
  background: #031844;
}

.trp_dtl_otr {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.trp_dtl_otr .trp_dtl_rt {
  width: 30%;
}
.trp_dtl_otr .trp_dtl_rt mat-form-field.mat-form-field {
  width: 100%;
}
.trp_dtl_otr .trp_dtl_rt mat-select {
  background: #eaeaea;
  padding: 10px;
}

/**create booking page**/
.bkcrt_dtl {
  max-height: calc(100vh - 230px);
  overflow-y: auto;
  margin-top: 20px;
  overflow-x: hidden;
  padding-right: 20px;
}
.bkcrt_dtl .bk_chscst_dv {
  margin-bottom: 30px;
}
.bkcrt_dtl .bk_chscst_dv mat-form-field.mat-form-field {
  width: 100%;
}

.booking_page_main {
  padding-left: 30px;
}
.booking_page_main .vecle_otr_inr {
  text-align: center;
}
.booking_page_main .vecle_otr_inr h3 {
  font-size: 18px;
  line-height: normal;
  margin-top: 10px;
}
.booking_page_main .vecle_otr_inr figure {
  height: 70px;
  width: 70px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: solid 1px transparent;
  border-radius: 10px;
  margin: 0 auto;
}

.vecle_otr_inr.selected figure {
  background: #ffffff;
  border: solid 1px #00649e;
}
.vecle_otr_inr.selected h3 {
  font-weight: bold;
}

.pck_drp_inr {
  background: #fff;
  padding: 20px;
  border-radius: 14px;
}
.pck_drp_inr h3 {
  font-size: 18px;
  line-height: normal;
  font-weight: bold;
  margin-bottom: 15px;
}
.pck_drp_inr .pck_lct_dv {
  width: 100%;
  border-bottom: solid 1px #ddd;
  background: url(assets/images/locatn_pin.svg);
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 30px;
  padding-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: 20px;
}
.pck_drp_inr .pck_lct_dv .ad_stps {
  position: absolute;
  right: 0;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  color: #202020;
}
.pck_drp_inr .pck_lct_dv.drp_lct_dv {
  padding-right: 80px;
}
.pck_drp_inr .pck_lct_otr .dtl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.pck_drp_inr .pck_lct_otr .dtl p {
  font-size: 14px;
  color: #000;
}
.pck_drp_inr .pck_lct_otr .dtl .btn {
  background: #999;
  color: #fff;
  font-size: 30px;
  border-radius: 5px;
  font-weight: 700;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  width: 40px;
  line-height: 30px;
}
.pck_drp_inr .pck_lct_otr .cstm_clps {
  margin-bottom: 20px;
}
.pck_drp_inr .pck_lct_otr .cstm_clps .sv_btn {
  text-align: right;
}
.pck_drp_inr .pck_lct_otr .cstm_clps .pck_lct_dv {
  background: none;
  padding-left: 0px;
  padding-bottom: 0px;
}

.ext_stp_tp {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ext_stp_tp h3 {
  font-size: 18px;
  line-height: normal;
  font-weight: bold;
}
.ext_stp_tp a {
  font-size: 16px;
  line-height: normal;
  font-weight: 400;
  color: #a5a5a5;
}

.extra_stops {
  margin-top: 20px;
}
.extra_stops .ext_stp_lst {
  position: relative;
  border-bottom: solid 1px #ddd;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;
}
.extra_stops .ext_stp_lst:last-child {
  margin-bottom: 0;
}
.extra_stops .ext_stp_lst .close {
  position: absolute;
  right: 0;
  opacity: 1;
}
.extra_stops .ext_stp_lst h4 {
  font-size: 16px;
  font-weight: bold;
  line-height: normal;
  margin-right: 10px;
}

.ad_ints_dv {
  padding: 30px 0px;
}
.ad_ints_dv mat-form-field.mat-form-field {
  width: 100%;
}

li.notes mat-form-field {
  width: 100% !important;
}

textarea.form_control,
textarea.form_control.mat-input-element {
  height: 100px;
}

textarea.form_control + .mat-form-field-label-wrapper label,
textarea.form_control.mat-input-element + .mat-form-field-label-wrapper label {
  top: 15px !important;
}

.ssde.sucs_bkng_otr {
  text-align: center;
}

.sucs_bkng_otr h3 {
  font-size: 22px;
  font-weight: bold;
  line-height: normal;
  margin: 25px 0 6px;
}
.sucs_bkng_otr p {
  margin: 0px 0 20px;
  font-size: 18px;
  line-height: normal;
  font-weight: 400;
}
.sucs_bkng_otr button {
  width: 100%;
}

ul.inte_otr figure img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

.flts_rgt {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
}
.flts_rgt .srch_dv {
  width: 300px;
  display: flex;
}
.flts_rgt .srch_dv mat-form-field.mat-form-field {
  width: 100%;
}
.flts_rgt .srch_dv .form_control,
.flts_rgt .srch_dv .form_control.mat-input-element {
  padding: 10px 15px;
  border-radius: 8px;
  border: 1px solid #f3f3f3;
}

table.table.table-responsive,
table {
  background: #fff;
  border-radius: 14px;
  overflow: hidden;
  box-shadow: 0 2px 20px #0000000d;
}

.table td,
.table th,
table td,
table th {
  border: unset;
  padding: 10px 8px;
  vertical-align: middle;
}

.table th,
table th {
  background: #fff;
  font-weight: 600;
  line-height: normal;
  font-size: 14px;
  color: #202020;
  padding: 15px 10px;
  text-align: center;
}

.table td,
table td {
  line-height: normal;
  font-size: 14px;
  font-weight: 400;
  color: #202020;
  text-align: center;
}

.table tr:hover td,
table tr:hover td {
  background: #fff;
}

table.table tr:first-child th {
  border-bottom: solid 1px #f1ebeb;
}

.ad_sm ngx-intl-tel-input.ng-dirty .iti__selected-flag {
  width: 50px;
}

.usr_pic {
  height: 40px;
  width: 40px;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto;
}
.usr_pic img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ffffff;
}

.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #00649e;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #002033;
}

.action {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.action .actn_icn {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 3px 3px #0000000f;
}
.action .clone_icn {
  background: #10858421;
}
.action .clone_icn i.fa-solid.fa-clone {
  color: #108584;
}
.action .edit_icn {
  background: #0080002e;
}
.action .edit_icn i.fa-solid.fa-pen {
  color: #0080009c;
}
.action .trash_icn {
  background: #ff00002e;
}
.action .trash_icn i.fa-solid.fa-trash {
  color: #ff0000e3;
}
.action i {
  font-size: 12px;
}

.custad_wrp {
  background: #fff;
}

.custmr_inrwrp {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
}

.custad_wrp {
  background: #ffffff;
  height: calc(100vh - 54px);
}

.cstaed_lt {
  padding: 0px;
  border-radius: 14px;
  width: 35%;
}
.cstaed_lt h2 {
  font-size: 22px;
  line-height: normal;
  font-weight: 600;
  padding: 20px;
}
.cstaed_lt .cstaed_inr {
  padding: 20px;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
.cstaed_lt .cstaed_inr::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: transparent;
  width: 3px;
  border-radius: 50px;
}
.cstaed_lt .cstaed_inr h3 {
  font-size: 14px !important;
  font-weight: 600;
  color: #202020;
  line-height: normal;
}
.cstaed_lt .cstaed_inr:hover::before,
.cstaed_lt .cstaed_inr.active::before {
  background: #00649e;
}

.cstaed_mn {
  height: calc(100vh - 174px);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 30px;
}

.pro_in {
  position: relative !important;
  z-index: 1;
}
.pro_in figure {
  width: 110px;
  height: 110px;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid #dedede;
}
.pro_in figure img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.pro_in input {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.cstaed_rt {
  width: 62%;
  border-radius: 14px;
}

.cstm_tbl {
  margin: 20px 0px;
}
.cstm_tbl table {
  width: 100%;
}
.cstm_tbl table tr th {
  white-space: nowrap;
}
.cstm_tbl table tr td .action {
  justify-content: center;
  gap: 10px;
}
.cstm_tbl table .evnts span {
  display: block;
  white-space: nowrap;
}

.cstaed_dtl_lft {
  width: 50%;
  padding-right: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}

.dtl_lft_tp,
.dtl_lft_bttm {
  width: 100%;
}

.cstaed_dtl_rt {
  width: 50%;
  border-left: solid 1px #eee;
  padding-left: 20px;
}

.prnl_pro {
  padding: 0px 20px 30px;
}
.prnl_pro span.upld_flr {
  background: #fff;
  padding: 10px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 6px 1px #0000007a;
  position: absolute;
  bottom: -16px;
  left: 38px;
  cursor: pointer;
}

.pro_flds_inr {
  background: #46464617;
  padding: 10px 20px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
}
.pro_flds_inr mat-form-field.mat-form-field {
  width: 100%;
}
.pro_flds_inr .form_control,
.pro_flds_inr .form_control.mat-input-element {
  background: transparent;
  padding: 0;
  border-radius: 0;
}
.pro_flds_inr i {
  font-size: 18px;
  color: #002033;
  padding-right: 15px;
}
.pro_flds_inr .form_control + .mat-form-field-label-wrapper label,
.pro_flds_inr .form_control.mat-input-element + .mat-form-field-label-wrapper label {
  left: 0;
}

.cstaed_dtl {
  height: calc(100vh - 240px);
  padding-right: 10px;
  overflow-y: auto;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

.update_pro {
  padding: 40px 0px 0px 20px;
}
.update_pro button {
  width: 100%;
}

.clrs_ovrvw {
  padding: 20px;
}
.clrs_ovrvw .clrovw_lst {
  position: relative;
  z-index: 1;
  color: #202020;
  line-height: normal;
  font-weight: 400;
  margin-bottom: 10px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 20px;
}
.clrs_ovrvw .clrovw_lst::before {
  content: "";
  position: absolute;
  left: 0;
  height: 12px;
  width: 12px;
  background: #000;
  border-radius: 50%;
}
.clrs_ovrvw .clrovw_lst.completed:before {
  background: #4ebb7a;
}
.clrs_ovrvw .clrovw_lst.ongoing:before {
  background: #00b5e0;
}
.clrs_ovrvw .clrovw_lst.canceled:before {
  background: #ee3c3c;
}

.cstaed_dtl h3 {
  font-size: 20px;
  line-height: normal;
  font-weight: 600;
}

.cstaed_dtl.cstaed_ads {
  padding: 0 20px;
  display: inherit;
}

.adrs_flds_inr .form_control,
.adrs_flds_inr .form_control.mat-input-element {
  background: #46464617;
}
.adrs_flds_inr mat-form-field.mat-form-field {
  width: 100%;
}
.adrs_flds_inr .adrs_flds_inr {
  margin-bottom: 20px;
}

.cstaed_dtl_adds {
  margin-top: 30px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  height: calc(100vh - 310px);
}

.adrs_flds_inr {
  margin-bottom: 20px;
}

.cmbng_dv {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 12px;
}
.cmbng_dv .adrs_flds_inr {
  width: 49%;
}
.cmbng_dv span img {
  width: 15px;
  height: 15px;
}

.ads_lst {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
}
.ads_lst .ads_inr {
  width: 49%;
  background: #46464617;
  border-radius: 14px;
  padding: 20px;
  margin-bottom: 15px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
}
.ads_lst .ads_inr .ads_inr_rt {
  width: 80%;
  padding-left: 10px;
}
.ads_lst .ads_inr .ads_inr_rt h4 {
  font-size: 20px;
  line-height: normal;
  font-weight: 600;
  margin-bottom: 2px;
}
.ads_lst .ads_inr .ads_inr_rt p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 6px;
}
.ads_lst .ads_inr .ads_inr_rt .ads_tmr {
  font-size: 14px;
  line-height: normal;
  font-weight: 400;
  color: #202020;
}
.ads_lst .ads_inr.active {
  background: #fff;
  box-shadow: 0 2px 20px #0000000d;
  cursor: default;
}

.date_hdr_dv {
  background: #ffffff;
  box-shadow: 0 2px 20px #0000000d;
  border-radius: 10px;
  padding: 5px 10px 10px 50px;
  position: relative;
  z-index: 1;
}
.date_hdr_dv::before {
  content: "";
  position: absolute;
  left: 0;
  background: url(assets/images/date_pickr_icn.svg);
  background-repeat: no-repeat;
  height: 40px;
  width: 40px;
  background-position: left 10px center;
}
.date_hdr_dv h4 {
  font-size: 18px;
  line-height: normal;
  font-weight: 600;
  margin-bottom: 1px;
}
.date_hdr_dv .mat-form-field-type-mat-date-range-input .mat-form-field-infix {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.date_hdr_dv .mat-form-field-type-mat-date-range-input .mat-form-field-infix span.mat-form-field-label-wrapper label {
  left: 0;
}
.date_hdr_dv span.mat-ripple.mat-button-ripple.mat-button-ripple-round {
  display: none;
}
.date_hdr_dv span.mat-button-wrapper {
  opacity: 0;
}
.date_hdr_dv button.mat-focus-indicator.mat-icon-button.mat-button-base {
  position: relative;
  top: -6px;
}
.date_hdr_dv button.mat-focus-indicator.mat-icon-button.mat-button-base::after {
  content: "";
  position: absolute;
  left: 0;
  border-top: solid 8px #ddd;
  border-left: solid 8px transparent;
  border-right: solid 8px transparent;
}
.date_hdr_dv span.mat-button-focus-overlay {
  display: none;
}

.date_hdr_dvs {
  background: #ffffff;
  box-shadow: 0 2px 20px #0000000d;
  border-radius: 10px;
  position: relative;
  z-index: 1;
}
.date_hdr_dvs::before {
  content: "";
  position: absolute;
  left: 0;
  background-repeat: no-repeat;
  height: 40px;
  width: 40px;
  background-position: left 10px center;
}
.date_hdr_dvs h4 {
  font-size: 18px;
  line-height: normal;
  font-weight: 600;
  margin-bottom: 1px;
}
.date_hdr_dvs .mat-form-field-type-mat-date-range-input .mat-form-field-infix {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.date_hdr_dvs .mat-form-field-type-mat-date-range-input .mat-form-field-infix span.mat-form-field-label-wrapper label {
  left: 0;
}
.date_hdr_dvs span.mat-ripple.mat-button-ripple.mat-button-ripple-round {
  display: none;
}
.date_hdr_dvs span.mat-button-wrapper {
  opacity: 0;
}
.date_hdr_dvs button.mat-focus-indicator.mat-icon-button.mat-button-base {
  position: relative;
  top: -6px;
}
.date_hdr_dvs button.mat-focus-indicator.mat-icon-button.mat-button-base::after {
  content: "";
  position: absolute;
  left: 0;
  border-top: solid 8px #ddd;
  border-left: solid 8px transparent;
  border-right: solid 8px transparent;
}
.date_hdr_dvs span.mat-button-focus-overlay {
  display: none;
}
.date_hdr_dvs .form_control,
.date_hdr_dvs .form_control.mat-input-element {
  font-size: 14px;
  line-height: normal;
  /* background: #fff; */
  background: #ffffff;
  padding: 15px 15px;
  border-radius: 10px;
  width: 100%;
  margin: 0;
  resize: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  color: #202020;
  font-weight: 400;
  border: unset;
  width: 100% !important;
}

.date_hdr_dvk {
  background: #ffffff;
  box-shadow: 0 2px 20px #0000000d;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  padding: 5px 10px 10px 50px;
}
.date_hdr_dvk::before {
  content: "";
  position: absolute;
  left: 0;
  background: url(assets/images/filter-solid.svg);
  background-repeat: no-repeat;
  height: 23px !important;
  width: 40px;
  background-position: left 10px center;
}
.date_hdr_dvk h4 {
  font-size: 18px;
  line-height: normal;
  font-weight: 600;
  margin-bottom: 1px;
}
.date_hdr_dvk .mat-form-field-type-mat-date-range-input .mat-form-field-infix {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.date_hdr_dvk .mat-form-field-type-mat-date-range-input .mat-form-field-infix span.mat-form-field-label-wrapper label {
  left: 0;
}
.date_hdr_dvk span.mat-ripple.mat-button-ripple.mat-button-ripple-round {
  display: none;
}
.date_hdr_dvk span.mat-button-wrapper {
  opacity: 0;
}
.date_hdr_dvk button.mat-focus-indicator.mat-icon-button.mat-button-base {
  position: relative;
  top: -6px;
}
.date_hdr_dvk button.mat-focus-indicator.mat-icon-button.mat-button-base::after {
  content: "";
  position: absolute;
  left: 0;
  border-top: solid 8px #ddd;
  border-left: solid 8px transparent;
  border-right: solid 8px transparent;
}
.date_hdr_dvk span.mat-button-focus-overlay {
  display: none;
}
.date_hdr_dvk .form_control,
.date_hdr_dvk .form_control.mat-input-element {
  font-size: 14px;
  line-height: normal;
  background: #ffffff;
  padding: 15px 15px;
  border-radius: 10px;
  width: 100%;
  margin: 0;
  resize: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  color: #202020;
  font-weight: 400;
  border: unset;
  width: 100% !important;
}

.right_slide .date_hdr_dv::before {
  content: unset;
}

.date_hdr_dv2::before {
  height: 25px;
}
.date_hdr_dv2 button.mat-focus-indicator.mat-icon-button.mat-button-base {
  top: 7px;
}

.date_hdr_dv3 {
  background: #46464617;
  padding: 10px;
}
.date_hdr_dv3 .mat-form-field {
  width: 100%;
}
.date_hdr_dv3::before {
  height: 45px;
  background: none;
}
.date_hdr_dv3 button.mat-focus-indicator.mat-icon-button.mat-button-base {
  top: 0px;
}
.date_hdr_dv3 button.mat-focus-indicator.mat-icon-button.mat-button-base::after {
  content: unset;
}
.date_hdr_dv3 span.mat-button-wrapper {
  opacity: 1;
}
.date_hdr_dv3 .mat-select {
  padding: 0px;
}

.scdl_nw_mn {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.scdl_nw_mn .date_hdr_dv3 {
  width: 48%;
}

.drvr_lstng .table-responsive {
  margin-top: 20px;
}

.drvrvw_mn {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin-top: 30px;
}
.drvrvw_mn .drvr_info_lt {
  width: 43%;
}
.drvrvw_mn .drvr_info_lt .drvr_info_inr {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.drvrvw_mn .drvr_info_lt .drvr_info_inr figure {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  overflow: hidden;
}
.drvrvw_mn .drvr_info_lt .drvr_info_inr figure img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.drvrvw_mn .drvr_info_lt .drvr_info_inr .drvr_cnt_rt {
  width: 90%;
  padding-left: 20px;
}
.drvrvw_mn .drvr_info_lt .drvr_info_inr .drvr_cnt_rt h4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  font-weight: bold;
  line-height: normal;
  position: relative;
}
.drvrvw_mn .drvr_info_lt .drvr_info_inr .drvr_cnt_rt h4 .drv_staus {
  display: flex;
  background: #edf8f1;
  color: #36b368;
  border-radius: 14px;
  padding: 7px 20px 7px 25px;
  font-weight: 400;
  font-size: 15px;
  position: absolute;
  right: 0;
  top: 0;
  align-items: center;
  justify-content: center;
}
.drvrvw_mn .drvr_info_lt .drvr_info_inr .drvr_cnt_rt h4 .drv_staus::before {
  content: "";
  position: relative;
  left: -5px;
  height: 10px;
  width: 10px;
  background: #36b368;
  border-radius: 50%;
}
.drvrvw_mn .drvr_info_lt .drvr_info_inr .drvr_cnt_rt h4 .drv_staus_off {
  color: #f50808;
}
.drvrvw_mn .drvr_info_lt .drvr_info_inr .drvr_cnt_rt h4 .drv_staus_off::before {
  background: #f50808;
}
.drvrvw_mn .drvr_info_lt .drvr_info_inr .drvr_cnt_rt h5 {
  font-size: 16px;
  line-height: normal;
  font-weight: 400;
  margin-top: 2px;
}
.drvrvw_mn .drvr_info_lt .drvr_vclinfo {
  background: #f8f8f8;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 20px 80px;
  border-radius: 14px;
  margin: 30px 0 20px;
}
.drvrvw_mn .drvr_info_lt .drvr_vclinfo .vclinfo_inr h4 {
  font-size: 20px;
  line-height: normal;
  font-weight: 600;
  margin-bottom: 5px;
}
.drvrvw_mn .drvr_info_lt .drvr_vclinfo .vclinfo_inr span {
  font-size: 18px;
  line-height: normal;
  font-weight: 400;
  color: #a5a5a5;
}
.drvrvw_mn .drvr_info_lt .crntbk_sts {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}
.drvrvw_mn .drvr_info_lt .crntbk_sts p {
  font-size: 18px;
  line-height: normal;
  font-weight: 400;
  padding-right: 30px;
}
.drvrvw_mn .drvr_info_lt .crntbk_sts p span {
  color: #202020;
  padding-left: 10px;
  font-weight: 600;
}
.drvrvw_mn .drvr_info_lt .crntbk_sts .bkng_fnlsts {
  background: #002033;
  color: #ffffff;
  display: inline-block;
  padding: 6px 15px;
  border-radius: 50px;
  font-size: 14px;
  line-height: normal;
  font-weight: 400;
}
.drvrvw_mn .drvr_info_lt .cntdtl_inr {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 11px;
  font-size: 18px;
  line-height: normal;
  font-weight: 400;
  color: #202020;
}
.drvrvw_mn .drvr_info_lt .cntdtl_inr figure {
  margin-right: 8px;
}
.drvrvw_mn .drvr_info_rt {
  width: 55%;
  border-radius: 14px;
  overflow: hidden;
}

.drv_ens_sc {
  margin-top: 30px;
}
.drv_ens_sc .table-responsive {
  margin-top: 20px;
}

.drv_prnl_otr {
  padding: 0 20px;
  width: 100%;
}
.drv_prnl_otr .drv_prnlin {
  width: 100%;
}
.drv_prnl_otr .drv_prn_flds .cmbng_dv .pro_flds_inr {
  width: 49%;
}
.drv_prnl_otr .drv_prn_flds .cmbng_dv .pro_flds_inr ngx-intl-tel-input {
  height: 19px;
}
.drv_prnl_otr .drv_prn_flds .cmbng_dv .pro_flds_inr .iti input,
.drv_prnl_otr .drv_prn_flds .cmbng_dv .pro_flds_inr .iti input[type=text],
.drv_prnl_otr .drv_prn_flds .cmbng_dv .pro_flds_inr .iti input[type=tel] {
  background: transparent;
  padding-top: 0;
  padding-bottom: 0;
  height: 100%;
}

.doc_inr {
  width: 49%;
}
.doc_inr .pro_in {
  width: 100%;
}
.doc_inr .pro_in figure {
  width: 100%;
  height: 100%;
  border-radius: 14px;
  position: relative;
}
.doc_inr .pro_in .upld_flr {
  right: 0;
  bottom: -20px;
  left: 0;
  margin: 0 auto;
}
.doc_inr .pro_in .upld_flr i.fa.fa-camera {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: auto;
  box-shadow: 0px 0px 6px 1px #0000007a;
}
.doc_inr .form_control,
.doc_inr .form_control.mat-input-element {
  background: #46464617;
}
.doc_inr .doc_inr {
  margin-bottom: 19px;
}

.trpl_dv {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.trpl_dv .doc_inr {
  width: 32%;
  margin-bottom: 19px;
}
.trpl_dv .doc_inr mat-form-field.mat-form-field {
  width: 100%;
}

.drv_prn_flds .trpl_dv {
  margin-top: 40px;
}

.dtepc_inr .mat-form-field-flex {
  background: #46464617;
  padding: 15px;
  border-radius: 10px;
}
.dtepc_inr .mat-form-field-flex .mat-form-field-label-wrapper label {
  left: 0;
}
.dtepc_inr .mat-form-field-flex .mat-form-field-prefix .mat-icon-button,
.dtepc_inr .mat-form-field-flex .mat-form-field-suffix .mat-icon-button {
  height: 19px;
}

.zone_rgt {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
  margin: 0 0 10px;
}
.zone_rgt .form_control,
.zone_rgt .form_control.mat-input-element {
  padding: 10px 15px;
}

.zone_data {
  position: relative;
}

.zn_inpt mat-form-field.mat-form-field {
  width: 100%;
  margin-bottom: 0px;
}

.zn_inpt.zone_mp_lct mat-form-field {
  width: 100%;
  margin-bottom: 0;
}

.adtm_sc h3 {
  margin-top: 0;
}

.ad_sm mat-form-field.mat-form-field {
  width: 100%;
  margin-bottom: 10px;
}
.ad_sm mat-form-field.mat-form-field input {
  /* background: #F9F9F9; */
  background: #46464617;
}
.ad_sm button {
  width: auto;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.adcsmt_otr form {
  margin-top: 20px;
}

/**login page**/
.auth_sc {
  background: url(assets/images/login_bg.jpg);
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  height: 100vh;
  overflow-y: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.auth_sc:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: url(assets/images/lgn_vctor.png);
  background-position: bottom center;
  height: 500px;
  background-size: 100%;
  background-repeat: no-repeat;
  z-index: -1;
}
.auth_sc .signup_frm form {
  margin-top: 15px;
}
.auth_sc .signup_frm p a,
.auth_sc .signup_frm p a:not([href]):not([class]) {
  cursor: pointer;
  color: #00649e;
  line-height: normal;
  font-weight: 600;
}
.auth_sc .signup_frm h2 {
  font-size: 30px;
  line-height: normal;
  font-weight: bold;
  margin-bottom: 2px;
}
.auth_sc .auth_inr {
  width: 568px;
  background: #fff;
  padding: 40px 40px;
  border-radius: 14px;
  box-shadow: 0 10px 60px #0000002e;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-height: 700px;
  overflow: auto;
}
.auth_sc .auth_inr .signup_frm {
  width: 100%;
}
.auth_sc .auth_inr .frm_frp {
  margin-bottom: 10px;
}
.auth_sc .auth_inr .frm_frp mat-form-field.mat-form-field {
  width: 100%;
}
.auth_sc .auth_inr .frm_frp .form_control,
.auth_sc .auth_inr .frm_frp .form_control.mat-input-element {
  background: #f2fcfe;
}
.auth_sc .auth_inr .frm_frp label {
  margin-bottom: 5px;
}
.auth_sc .auth_inr .cmbng_dv .frm_frp {
  width: 49%;
}
.auth_sc .auth_inr .psw_vw {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
}
.auth_sc .auth_inr .psw_vw i {
  position: absolute;
  right: 10px;
  color: #008bf2;
  top: 15px;
}
.auth_sc .auth_inr .psw_vw .form_control,
.auth_sc .auth_inr .psw_vw .form_control.mat-input-element {
  padding-right: 45px;
}

.frm_btn {
  margin-top: 30px;
}
.frm_btn button {
  width: 100%;
}

.signup_frm.succs_msg {
  text-align: center;
}
.signup_frm.succs_msg h2 {
  margin-top: 30px;
  font-size: 26px;
}
.signup_frm.succs_msg p {
  font-size: 18px;
  line-height: normal;
  color: #00649e;
}

.dfdfffd {
  display: flex;
  padding: 20px 0 0;
  align-items: center;
  justify-content: center;
}
.dfdfffd button {
  margin: 0 15px 0px 0px;
}
.dfdfffd button:last-child {
  margin-right: 0;
}

.modal_wrp {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 10px;
  width: 100%;
}
.modal_wrp li {
  width: 32%;
}
.modal_wrp li mat-label {
  line-height: 40px;
}

/*---------------table---------------------*/
.slct .mat-select-arrow {
  color: var(--primaryColor);
}

/*----------------------------------------*/
.ng_inputel .dropdown-menu.country-dropdown.show {
  inset: 70% 20% auto -300% !important;
}

.ng_inputell .dropdown-menu.country-dropdown.show {
  inset: 70% 20% auto -540% !important;
}

.drv_ad .form_control,
.drv_ad .form_control.mat-input-element {
  background: #46464617;
}

.fjkjf_otr {
  max-height: 300px;
  overflow-y: auto;
  padding-right: 20px;
  min-height: 131px;
}

.fjkjf {
  display: flex;
  justify-content: space-between;
}
.fjkjf .fjkjf_inr {
  margin-right: 10px;
}

.gmnoprint {
  left: 0px !important;
}

.sttng_dtl .pro_flds_inr {
  width: 49%;
}

.logn_wth_dtl {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
.logn_wth_dtl label {
  margin-bottom: 0;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #002033;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #00649e;
}

.logn_wth {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.logn_wth label {
  margin-bottom: 0;
  display: inline-flex;
  align-items: center;
  gap: 10px;
}

.wht_lbl_dv {
  height: unset;
  overflow-y: auto;
}
.wht_lbl_dv .cmbng_dv .form-group {
  width: 49%;
}
.wht_lbl_dv .cmbng_dv .form-group .form_control,
.wht_lbl_dv .cmbng_dv .form-group .form_control.mat-input-element {
  background: #46464617;
}
.wht_lbl_dv .cmbng_dv .form-group mat-form-field.mat-form-field {
  width: 100%;
}
.wht_lbl_dv .cmbng_dv .logo_strfnt {
  width: 32%;
  margin-bottom: 20px;
}
.wht_lbl_dv .cmbng_dv .logo_strfnt .pro_in {
  width: 100%;
}
.wht_lbl_dv .cmbng_dv .logo_strfnt .pro_in figure {
  width: 100%;
  height: 300px;
  border-radius: 14px;
  background: #f1f1f1;
}
.wht_lbl_dv .cmbng_dv .logo_strfnt .pro_in figure img {
  object-fit: scale-down;
}
.wht_lbl_dv .cmbng_dv .logo_strfnt .pro_in .upld_flr {
  bottom: -15px;
  left: 0;
  margin: 0 auto;
}
.wht_lbl_dv label {
  font-size: 14px !important;
  font-weight: 600;
  color: #292929;
}

.Team,
.qestion-dialog {
  width: 100% !important;
  max-width: 30vw !important;
}
.Team .mat-dialog-container,
.qestion-dialog .mat-dialog-container {
  position: relative;
  overflow: inherit;
}

.doc_class {
  width: 100% !important;
  max-width: 50vw !important;
  max-height: 32vw;
  overflow: auto;
}
.doc_class .mat-dialog-container {
  position: relative;
  overflow: inherit;
}
.doc_class h2 {
  font-size: 18px;
  font-weight: 600;
  margin: 10px 0;
}

.mat-form-field {
  width: 100%;
}

.clrs_sec .clr_fm {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.clrs_sec .clr_fm li {
  width: 32%;
  margin-right: 15px;
  height: 288px;
}

.whte_lble .save_bttn {
  padding: 20px 0 10px;
  text-align: center;
  width: 92px;
}
.whte_lble .save_bttn button {
  width: 100%;
}

.lg_chk mat-radio-button {
  margin-right: 20px;
}

.jhd {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 20px 0;
}
.jhd .trckng_wth {
  width: 24%;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #00000040;
  padding: 12px 10px;
  margin: 5px;
}
.jhd .trckng_wth label {
  padding: 0;
  margin-bottom: 10px;
}

.trckng_chck {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.trckng_chck h4 {
  font-size: 14px;
  font-weight: bold;
  padding-right: 20px;
}
.trckng_chck .lg_chk label {
  margin-bottom: 0;
  margin-right: 15px;
}

.integ_lble {
  padding: 0 20px;
}

.inte_otr {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 12px;
}
.inte_otr .inte_inr {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  width: 32%;
  position: relative;
  border: solid 1px #ddd;
}
.inte_otr .inte_inr:nth-child(3n) {
  margin-right: 0;
}
.inte_otr .inte_inr h3 {
  font-size: 20px;
  line-height: normal;
  font-weight: 600;
  margin: 20px 0 10px;
}
.inte_otr .inte_inr .mtsld_dv {
  position: absolute;
  top: 20px;
  right: 20px;
}
.inte_otr .inte_inr a,
.inte_otr .inte_inr a:not([href]):not([tabindex]) {
  color: #00649e;
  cursor: pointer;
}

ul.cstaed_lst li {
  box-shadow: 0 2px 20px #0000000d;
  border-radius: 12px;
  margin-bottom: 12px;
}

.sttng_inrwrp .cstaed_lt {
  position: sticky;
  width: 100%;
  top: 10px;
}
.sttng_inrwrp .cstaed_rt {
  width: 70%;
  background: #fff;
}

.integ_lble {
  padding: 0 20px;
  height: calc(100vh - 90px);
  overflow-y: auto;
}

.usr_profle a {
  color: var(--textColor) !important;
  line-height: normal;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.usr_profle a figure {
  height: 40px;
  width: 40px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
}
.usr_profle a figure img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.usr_profle .dropdown-menu {
  left: unset;
  right: 0;
  min-width: 200px;
}
.usr_profle .dropdown-menu li a {
  color: var(--textColor) !important;
  padding: 4px 20px;
  font-size: 16px;
  line-height: normal;
  font-weight: 400;
}

.myprfl_wrp .cstaed_rt {
  background: #fff;
  padding: 30px;
}
.myprfl_wrp .cstaed_rt .cstaed_dtl_lft {
  width: 100%;
  padding-right: 0;
}
.myprfl_wrp .pro_flds_inr {
  width: 48%;
  border-radius: 14px;
}
.myprfl_wrp .pro_flds_inr mat-form-field.mat-form-field {
  padding-left: 0;
}
.myprfl_wrp .ad_pro_flds {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
}
.myprfl_wrp .ad_pro_flds .mat-form-field {
  display: inline-block;
  position: relative;
  text-align: left;
  width: 300px;
}

.iti input,
.iti input[type=text],
.iti input[type=tel] {
  background: #46464617;
  border: unset;
  border-radius: 10px;
  padding-top: 14px;
  padding-bottom: 14px;
  height: auto !important;
  width: 100%;
  box-shadow: unset !important;
}

ngx-intl-tel-input {
  width: 100%;
  display: block;
}

.iti.iti--allow-dropdown {
  width: 100%;
}

nav.pagination {
  justify-content: flex-end;
}

.vecle_otr_inr img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.drv_prnl_otr .vecle_otr_inr figure {
  height: 100px;
  width: 110px;
}
.drv_prnl_otr .vecle_otr_inr figure img {
  height: 50px;
}
.drv_prnl_otr .bk_chscst_dv {
  margin-bottom: 40px;
}

.cstomr_inpt .iti input,
.cstomr_inpt .iti input[type=text],
.cstomr_inpt .iti input[type=tel].form-control {
  height: auto !important;
  line-height: normal;
  padding-top: 0;
  padding-bottom: 0;
  background: none;
}
.cstomr_inpt .iti__selected-flag {
  padding-left: 0px;
}

.cstaed_dtl_lft.cstm_lftdv {
  width: 100%;
  padding: 0 30px;
}
.cstaed_dtl_lft.cstm_lftdv form {
  width: 100%;
}

.update_pro {
  width: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.update_pro button {
  margin-right: 20px;
}
.update_pro button:last-child {
  margin-right: 0;
}

.sucs_bkng_otr.adtm_sc.adtrm_otr {
  width: 300px;
  padding: 30px 0;
}
.sucs_bkng_otr.adtm_sc.adtrm_otr .ad_sm {
  margin-top: 20px;
}

.frgot_pw {
  text-align: right;
}
.frgot_pw a,
.frgot_pw a:not([href]):not([tabindex]) {
  color: #00649e;
}

.pck_lct_dv input {
  border: none;
  width: 100%;
}

.ext_stp_lst input {
  border: none;
  width: 75%;
}

.gnrl_btm_btn button {
  width: 10%;
  margin: 20px 0px 10px;
}

.crt_bkng .bkng_sc .bkng_lft {
  width: 30%;
  padding: 10px;
  background: #fff;
}

.bkcrt_dtl .bk_chscst_dv2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.bkcrt_dtl .bk_chscst_dv2 label {
  width: 100%;
}
.bkcrt_dtl .bk_chscst_dv2 .btn {
  width: 28%;
  height: 45px;
  font-size: 15px;
}
.bkcrt_dtl .bk_chscst_dv2 .btn:hover, .bkcrt_dtl .bk_chscst_dv2 .btn:focus {
  color: #fff;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--primaryColor) !important;
}

.mat-slide-toggle-thumb {
  border: 2px solid #fff !important;
}

.mat-slide-toggle-thumb {
  background-color: #d0d0d0 !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: var(--primaryColor) !important;
}

.mat-slide-toggle-bar {
  background-color: #d0d0d0 !important;
}

.slct span {
  font-size: 12px !important;
}

.blkr td {
  font-size: 12px !important;
}

.date_hdr_dv button.mat-focus-indicator.mat-icon-button.mat-button-base:after {
  border-top: solid 5px var(--primaryColor);
  border-left: solid 5px transparent;
  border-right: solid 5px transparent;
}

.mat-select-arrow {
  color: var(--primaryColor) !important;
}

.mat-tab-label.mat-tab-label-active {
  background: black;
  border-color: black;
  color: var(--primaryColor);
}

.drvr_lstng .table-responsive {
  margin-top: 5px;
  margin-bottom: 20px;
}

.mid_sec .mat-tab-header {
  top: 7px;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: var(--primaryColor);
}

.slotModal {
  width: 100%;
}

.slotModall {
  width: 30vw !important;
  position: fixed !important;
  right: 145px;
  max-height: 540px;
  top: 129px;
}
.slotModall .mat-dialog-container {
  background: white;
  padding: 10px;
  border-radius: 4px;
  overflow: auto !important;
}

.documentModal {
  position: fixed !important;
  top: 40px;
  right: 0px;
  height: 100vh;
  width: 40vw;
}

.drv_ens_sc .mat-tab-header {
  bottom: -4px;
}

.picr .mat-checkbox-inner-container {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0 !important;
}

.picr .mat-checkbox-layout {
  gap: 5px;
  padding: 5px;
}

.adsdriver {
  width: 55vw !important;
}
.adsdriver .mat-dialog-container {
  position: relative;
  overflow: inherit;
}

.fx-wallet {
  width: 300 !important;
}

.sos_map .mat-dialog-container,
.class .mat-dialog-container {
  position: relative;
  overflow: inherit;
}

.mat-error {
  font-size: 12px;
  padding: 5px 10px;
}

.detil .iti input {
  background: #fff !important;
  padding-top: 14px;
  padding-bottom: 14px;
  height: auto !important;
  width: 100%;
  border: none;
  font-size: 12px;
  box-shadow: 0 2px 20px #0000000d !important;
  border-radius: 5px;
  font-weight: 400;
  color: #0009;
}

.detil .dropdown-menu.country-dropdown {
  width: 300px;
}

button.btn-primary.green-bg {
  border: none !important;
}

.view_icn {
  background-color: #ff980026;
}
.view_icn i {
  color: #ff9800;
}

.form-group .mat-select {
  border: none;
  font-size: 12px;
  padding: 10px;
  height: 40px;
  box-shadow: 0 2px 20px #0000000d;
  border-radius: 5px;
  font-weight: 400;
  color: #0009;
  background: #fff !important;
}

.mat-button-wrapper svg {
  width: 16px;
  height: 16px;
}

.drv_ens_sc .table-responsive {
  margin-top: 0px !important;
}

.btn:hover {
  color: #fff !important;
  text-decoration: none;
}

.mat-tab-label {
  border: solid 1px var(--primaryColor);
  background: var(--primaryColor);
  border-radius: 4px;
  padding: 9px 16px !important;
  font-size: 12px !important;
  font-weight: 600;
  opacity: 1 !important;
  color: black;
}

.mid_sec {
  position: relative;
  width: 100%;
  margin-top: 20px;
}

.date_hdr_dv2 .mat-date-range-input-start-wrapper .mat-date-range-input-inner {
  left: 4px !important;
}

.tm-slot .mat-expansion-panel {
  box-shadow: none;
}

.tm-slot .mat-expansion-indicator::after {
  display: none;
}

.mat-expansion-panel .mat-expansion-panel-header:hover {
  background: none !important;
}

.tm-slot .mat-expansion-panel-header {
  height: 35px;
  background: #fff;
}

.tm-slot .mat-expansion-panel-body {
  padding: 10px;
}

.cstm_frm ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.cstm_frm h5 {
  font-size: 16px;
  font-weight: 400;
  color: #202020;
  margin-bottom: 8px;
}
.cstm_frm li {
  width: 48%;
}
.cstm_frm .ad_instrctn {
  width: 100%;
}
.cstm_frm .ad_instrctn mat-form-field {
  width: 100%;
}
.cstm_frm .btns {
  width: 100%;
  text-align: right;
  margin: 10px 0px;
}
.cstm_frm .btns .btn {
  background: green;
  color: #fff;
  font-size: 16px;
  padding: 5px 10px;
}
.cstm_frm .btns .dlt_btn {
  background: red;
  margin-left: 10px;
}

.scdl_nw mat-radio-group {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0px;
}
.scdl_nw mat-radio-group mat-radio-button {
  margin-right: 15px;
}
.scdl_nw mat-radio-group mat-radio-button:last-child {
  margin-right: 0px;
}
.scdl_nw .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #00649e;
}
.scdl_nw .mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #00649e;
}

.cstm_frm .btns {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.cstm_hd {
  padding: 10px;
}
.cstm_hd h1 {
  margin-left: 8px;
}

.sttng_wrp,
.cstaed_mn {
  height: unset;
  margin-top: 15px;
}

.title-bar_lft h2 {
  font-size: 21px;
}

.alct_lst {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  margin-top: 30px;
}
.alct_lst li {
  width: 24%;
  border: solid transparent 2px;
  border-radius: 10px;
  padding: 10px;
  background: #46464617;
}
.alct_lst li figure {
  height: 200px;
}
.alct_lst li figure img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.alct_lst li .cntnt {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
}
.alct_lst li .cntnt h3 {
  font-size: 16px;
}
.alct_lst li .cntnt i {
  display: none;
  font-size: 20px;
  color: var(--primaryColor);
}
.alct_lst .active {
  border: solid var(--primaryColor) 2px;
  background: #fff;
}
.alct_lst .active .cntnt i {
  display: block;
}

.logn_wth.wbhk_evnt {
  flex-wrap: wrap;
}
.logn_wth.wbhk_evnt .logn_wth_dtl {
  width: 100%;
  padding: 0;
  flex-wrap: wrap;
}
.logn_wth.wbhk_evnt .logn_wth_dtl li {
  width: 100%;
  border-bottom: solid 1px #ededed;
  padding: 10px 0 10px;
}

.lg_chk.rqrd_dv {
  align-items: center;
  display: flex;
  padding-right: 0;
}
.lg_chk.rqrd_dv label {
  padding-right: 15px;
}

.wht_lb_flds {
  width: 49%;
}
.wht_lb_flds label {
  font-size: 14px;
}
.wht_lb_flds .pro_flds_inr {
  width: 100%;
}

@media (max-width: 1700px) {
  .mini_sidebar i.fa.fa-bars {
    left: 6%;
  }

  .bk_lck_otr .bk_lck_inr {
    font-size: 14px;
  }

  .fltr_inrdrp form .srch_dv {
    width: 100%;
  }

  .fltr_inrdrp .from-group {
    width: 56%;
  }

  .bk_lck_otr .bk_lck_inr .bk_dte {
    font-size: 14px;
  }

  .bkng_lstb_dtl .bkng_dtl_inr .bkdtl_tp .bk_id h3 {
    font-size: 16px;
  }

  .bkng_lstb_dtl .bkng_dtl_inr .bkdtl_tp .bk_id h4 {
    font-size: 14px;
  }

  .bk_stus button.btn {
    padding: 6px 10px;
  }

  .btn {
    font-size: 15px;
  }

  .drvrvw_mn .drvr_info_lt .drvr_vclinfo {
    padding: 20px 8px 3px;
    width: 106%;
    display: flex;
    flex-direction: column;
  }

  .drvrvw_mn .drvr_info_lt .drvr_vclinfo .vclinfo_inr h4 {
    font-size: 18px;
  }

  .drvrvw_mn .drvr_info_lt .drvr_vclinfo .vclinfo_inr span {
    font-size: 16px;
  }
}
.highlight {
  background-color: green;
}

.cross_fixed {
  position: absolute;
  top: 16px;
  right: 22px;
}

#map * {
  -moz-transition: none;
  -webkit-transition: none;
  -o-transition: all 0s ease;
  transition: none;
}

.flter_box {
  font-size: 13px;
  font-weight: 400;
  color: #000;
  line-height: 40px;
}
.flter_box .mat-form-field {
  background-color: #ffffff;
}

.bkng_map {
  width: 100%;
  height: 100vh;
  position: relative;
}

.ct_ng_wrp {
  top: 62px;
  left: 0px;
  display: block;
  align-items: stretch;
  justify-content: space-between;
  height: calc(100vh - 62px);
  box-shadow: -10px 0px 3px 0px #ddd;
  width: 100%;
}

.bkng_wrp {
  overflow: hidden;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  height: calc(100vh - 42px);
  width: 100%;
  position: relative;
}

.sd_br {
  position: absolute;
  z-index: 9;
  cursor: pointer;
  top: 0px;
}

.lft_bar .mat-drawer-inner-container {
  height: 100vh !important;
}

.mat-drawer-opened {
  max-width: 100%;
  position: relative !important;
  width: 320px !important;
}

.hme_wpr .mat-drawer-opened {
  width: 263px !important;
}

.bk_mn .mat-drawer-side.mat-drawer-end {
  border-left: unset !important;
}

.bkng_lft.bkng_rgt {
  background: #f8f8f8;
  box-shadow: 0px 3px 99px #8a8a8a12;
  padding: 10px;
  position: relative;
  height: 100%;
}

.rht_cls i.fa.fa-angle-left,
i.fa.fa-list {
  background-color: #ffffff;
  color: #000;
  display: flex;
  font-size: 18px;
  border-radius: 7px 0px 0px 7px;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
}

.right_slide .mat-drawer {
  background-color: unset;
}

.right_slide .mat-drawer-container {
  background-color: unset !important;
}

.filters_outer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
}

.trial_pck {
  width: 17% !important;
  transform: translate(-65px, 0px);
}
.trial_pck span {
  font-size: 16px;
  font-weight: 600;
  color: var(--textColor);
}

.veh-img {
  width: 40px;
  height: 40px;
  margin: 0 auto;
}
.veh-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 7px;
}

#dialog {
  width: 500px !important;
}

.custom-style {
  background-color: brown;
  color: white;
}

.custom-styles {
  background-color: #f6ff00;
  color: black;
}

a[href^="http://maps.google.com/maps"] {
  display: none !important;
}

a[href^="https://maps.google.com/maps"] {
  display: none !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}

.image_class {
  max-width: 24vw !important;
  pointer-events: auto;
  position: static;
}

.mat-accordion {
  position: relative;
  bottom: 0px;
  left: 0;
  right: 0;
}

.mat-step-icon .mat-icon {
  font-size: 16px;
  height: 24px !important;
  width: 16px;
}

/*introduction css*/
.intro_wrp {
  background: url(assets/images/login_bg.jpg);
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  height: 100vh;
  overflow-y: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.intro_wrp .main_inr {
  max-width: 100%;
  width: 80%;
}
.intro_wrp .frm_wrp {
  margin: 10px 0px;
}
.intro_wrp .frm_wrp mat-form-field {
  width: 100%;
}
.intro_wrp .frm_wrp .mat-select {
  background: #46464617;
  padding: 15px 15px;
}
.intro_wrp .loc_wp {
  position: relative;
  margin: 10px 0px;
}
.intro_wrp .loc_wp svg {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 15px;
  top: 12px;
  background: #bbd6e9;
  padding: 3px;
  cursor: pointer;
  border-radius: 2px;
  fill: #4e4a4a;
}

/*introduction css end*/
.ap_id span {
  width: 130px;
  background: #f8f8f8;
  padding: 7px 10px;
  color: #000000de;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 18px !important;
  font-weight: 500;
  border-radius: 5px;
  display: block;
}

.mn_fdx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.mn_fdx .mn_inr {
  width: 100%;
  box-shadow: 0px 2px 20px #0000001c;
  padding: 5px 15px;
  margin-bottom: 15px;
  border-radius: 10px;
}
.mn_fdx .app_fdx {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.mn_fdx .app_fdx .social_lnks {
  display: flex;
  gap: 10px;
  padding-top: 20px;
}
.mn_fdx .app_fdx .social_lnks figure {
  width: 130px;
}

.slick-prev:before,
.slick-next:before {
  color: red;
}

.btn_rw {
  display: flex;
  justify-content: right;
  gap: 10px;
  margin-top: 30px;
}

.bbbt {
  margin: 0 auto;
  width: 168px;
  padding-top: 28px;
}

.btn_rw {
  display: flex;
  justify-content: right;
  gap: 10px;
  margin-top: 30px;
}

.crtbkng button {
  width: 100%;
}

.bkng_add button {
  width: 260px;
}

.signup_frm h2 {
  font-size: 20px !important;
}

.signup_frm p {
  font-size: 16px !important;
}

li.dash_antk_inr span {
  font-size: 16px !important;
}

.bkng_fd .lft p {
  width: 200px;
}

.bkdtl_tp .cmpltd_btsn {
  padding-right: 59px;
}

.brdrn {
  border: unset !important;
}

.bk_tsh {
  position: absolute;
  top: 10px;
  right: 5px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0px 0px 7px 4px #00000014;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
}

.cmpltd_btsn .view_icns.shr_dt {
  right: -10px;
}

.pg_hd .mn_hd h1 {
  padding-left: 12px;
  font-weight: 600;
  margin-bottom: 10px;
}

.dasd span {
  color: #000 !important;
  font-size: 14px !important;
}
.dasd .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #dedede;
}

.mat-select-arrow {
  color: black;
}

.ryt_btm {
  padding: 0px 10px;
}
.ryt_btm h3 {
  margin-bottom: 10px;
}

.sub_wp .role_slc {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 12px 0px;
  margin-top: 10px;
}
.sub_wp .role_slc mat-option {
  padding: 0 !important;
  font-size: 14px;
  font-weight: 500;
  color: #000 !important;
  height: 30px;
}
.sub_wp .role_slc .loginform {
  width: 49%;
}
.sub_wp .role_slc .loginform .bck-btn {
  width: 25%;
  margin: 10px auto 0px;
}
.sub_wp .role_slc .role_sel {
  width: 49% !important;
  margin-left: auto;
}
.sub_wp .mat-tab-label-container {
  margin-top: 20px !important;
}
.sub_wp .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--primaryColor) !important;
}
.sub_wp .mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: var(--primaryColor);
}
.sub_wp .table-responsive table {
  width: 100%;
}
.sub_wp .form-group {
  width: 100%;
}
.sub_wp .loginform .mat-form-field {
  width: 100% !important;
}

.mat-select-arrow {
  color: black;
}

.dtl_lft_tp .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--primaryColor);
}
.dtl_lft_tp .mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: var(--primaryColor);
}

.infix .mat-form-field-infix {
  width: 100% !important;
}

.infix input {
  border: 1px solid #a3a3a3;
  padding: 4px 10px;
  border-radius: 3px;
}

.mat-list-text {
  white-space: nowrap;
}

.cntnt span.mat-radio-outer-circle {
  border-color: var(--primaryColor) !important;
}

.cntnt span.mat-radio-inner-circle {
  background-color: var(--primaryColor) !important;
}

.infix .mat-chip.mat-standard-chip .mat-chip-remove {
  color: red;
  opacity: 1.4;
  border: none;
}
.infix .mat-standard-chip .mat-chip-remove,
.infix .mat-standard-chip .mat-chip-trailing-icon {
  width: 18px;
  height: 18px;
  cursor: pointer;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.drv_prnl_otr .drv_prn_flds .cmbng_dv .pro_flds_inrtags {
  width: 100%;
}

.hd_input {
  position: relative;
}
.hd_input input {
  width: 130px;
  font-size: 18px;
  font-weight: 600;
  padding: 5px 10px;
  border: unset;
}
.hd_input i.fa.fa-pencil {
  position: absolute;
  top: 10px;
  right: 5px;
}
.hd_input i.fa.fa-sign-in {
  color: var(--primaryColor);
}

.mn_hd {
  width: 100%;
}
.mn_hd i.fa.fa-pencil {
  color: var(--secondaryColor);
  margin-left: 5px;
}

.pg_hdbutton {
  display: flex;
  margin-bottom: 10px;
  flex-direction: row-reverse;
}

.right_slide .srch_inpt {
  padding: 8px 10px 8px 36px !important;
}

li.inte_inr:hover {
  box-shadow: 0px 0px 0px 2px #00000026;
  cursor: pointer;
}

.mtsld_dv span {
  background: linear-gradient(45deg, #4ab74e, #486c49e0);
  color: #ffffffc7;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px !important;
  font-weight: 600;
}

.add_zonenma {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  justify-content: center;
  margin: 15px 0;
}
.add_zonenma form {
  width: 100%;
}
.add_zonenma .zn_inpt {
  width: 49%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  gap: 20px;
}
.add_zonenma .zn_inpt mat-label {
  line-height: 40px;
}
.add_zonenma button {
  width: 30%;
}

.logn_wth_dtl .mat-list-base {
  display: flex;
  align-items: center;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #f3d739;
}

.template_class {
  width: 100%;
  max-height: 617px;
}

.cms_class,
.class {
  width: 50%;
  max-height: 40vw;
  overflow: auto;
}
.cms_class .mat-dialog-container,
.class .mat-dialog-container {
  position: relative;
  overflow: inherit;
}

.bank_class {
  max-height: 501px;
  overflow: auto;
  width: 42vw;
}
.bank_class .mat-dialog-container {
  position: relative;
  overflow: inherit;
}

.fleet_class {
  width: 55%;
  max-height: 44vw;
  overflow: scroll;
}
.fleet_class .mat-dialog-container {
  position: relative;
  overflow: inherit;
}

.conductor_class {
  width: 55%;
  max-height: 25vw;
  overflow: auto;
}
.conductor_class .mat-dialog-container {
  position: relative;
  overflow: inherit;
}

.showDriverByVehicle {
  width: 50%;
}

.sos_map mat-dialog-container {
  padding: 10px;
}
.sos_map mat-dialog-container div#map {
  width: 500px !important;
  min-height: 500px !important;
}

.sos_alert {
  position: relative !important;
}
.sos_alert mat-dialog-container {
  max-width: 100%;
  padding: 10px;
  width: 400px;
  height: 370px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
}
.sos_alert mat-dialog-container app-notification-modal {
  width: 100%;
}
.sos_alert mat-dialog-container {
  transition: none;
}

.fullPay_class {
  width: 27%;
}

.prdf {
  width: 85%;
}

.subAdmin_class {
  width: 48%;
}

.ck .ck-editor__main {
  max-height: 217px;
  overflow-y: scroll;
}

.ck.ck-editor__main > .ck-editor__editable {
  height: 200px;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #f3d739;
}

/* sunanda */
.tm_lft figure {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.tm_lft figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tm_mn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
}

.dta_bx {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 10px;
}

.team_crd {
  background: #ffffff;
  box-shadow: 0px 3px 24px #aaaaaa24;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 0px;
  padding: 10px 20px;
}

.tm_rt h2 {
  font-size: 16px;
  line-height: normal;
  font-weight: 500;
}

.dta_lst h3 {
  font-size: 12px !important;
  font-weight: 400;
  color: #a5a5a5;
}
.dta_lst p {
  color: #000;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 24px;
}

.content_wraper {
  width: 89%;
  padding: 50px 0px 0px 10px;
  background: #f9f9f9;
  margin-left: auto;
}
.content_wraper .bkng_map div#map {
  height: 100%;
}

ul.tm_bx li mat-panel-title {
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  color: #000;
}

.sdbr_lt {
  height: 100% !important;
  width: 11.5%;
  padding-bottom: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: #fff;
  transition: all 0.2s ease;
  box-shadow: 2px 0 2.94px 0.06px rgba(4, 26, 55, 0.04);
  z-index: 100;
}
@media (max-width: 1600px) {
  .sdbr_lt {
    width: 14.5%;
  }
}
.sdbr_lt .mat-list-base .mat-list-item {
  height: 42px;
}

.sdbr_mn {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.pages_content_wrapper {
  position: relative;
  overflow: hidden;
}

.logomain figure {
  margin: 10px auto;
  width: 100px;
  height: 50px;
  overflow: hidden;
}
.logomain figure img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.mini_sidebar .logomain figure {
  width: 60px;
}

.mini_sidebar .mat-nav-list .mat-list-item-content {
  justify-content: center;
}

.tm_lt {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  gap: 5px;
  padding: 25px 0px 0px;
  flex-direction: column;
}
.tm_lt figure {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.tm_lt figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.tm_lt .lt_inr {
  width: 30px;
  height: 30px;
  margin: 0;
}

.active p {
  color: #000;
  font-weight: 500;
  line-height: normal;
}

.rrst_sc {
  z-index: 999;
  position: absolute;
  top: 122px;
  width: 25%;
  border-radius: 10px;
  padding: 20px;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 99px #8a8a8a12;
}

.hm_mn {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.add-q-wrp h2 {
  font-size: 26px;
  font-weight: 600;
  line-height: normal;
  color: #000;
}
.add-q-wrp mat-label {
  line-height: 40px;
}

.lead {
  font-size: 13px !important;
  font-family: "Bw Modelica" !important;
  font-weight: 500;
  line-height: normal;
  margin-left: 3px;
  color: #000;
}
.lead:hover {
  margin-left: 4px;
}

.sd_b_pt {
  border-bottom: 1px solid #dedede33;
  filter: drop-shadow(0px 0px 0px #dedede1a);
}
.sd_b_pt span i {
  filter: opacity(0.7);
  font-size: 16px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sd_b_pt:last-child {
  border-bottom: unset;
}

.nw_bkng h2 img {
  background: rgba(251, 176, 59, 0.3);
  padding: 2px;
  border-radius: 5px;
}
.nw_bkng .btn.auto_assign.crtd_btn {
  background: #faaf3b;
  border-radius: 20px;
}
.nw_bkng .right_slide .btn {
  padding: 5px 25px !important;
}

.bkng_lstb_dtl .bkng_dtl_inr .tm_rcd {
  border: unset;
  padding-bottom: 5px;
}

.tm_record {
  max-height: unset;
}
.tm_record .bkng_dtl_inr {
  width: 25%;
}
.tm_record button.btn.auto_assign {
  background: #faaf3b;
  border-radius: 20px;
  padding: 5px 25px;
}
.tm_record h2 img {
  background: rgba(251, 176, 59, 0.3);
  padding: 2px;
  border-radius: 5px;
}
.tm_record .bkng_fd .lft p {
  color: #000;
}
.tm_record .lft p,
.tm_record .bkd_lt p {
  color: #000000 !important;
}
.tm_record .lft h2 {
  color: #aaaaaa;
}

.asgn-ryt {
  right: 5px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0px 0px 7px 4px #00000014;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
}

/*sunanda */
section.all_sec.g_dv_sc {
  background: #1b1f24;
}

/*sunanda*/
ul.tm_bx {
  padding-bottom: 30px;
}
ul.tm_bx .team_crd {
  margin-bottom: 10px;
}
ul.tm_bx .mat-accordion {
  width: 100%;
}
ul.tm_bx .mat-expansion-panel {
  width: 100%;
  box-shadow: unset !important;
  background: unset !important;
}
ul.tm_bx .mat-expansion-panel-header,
ul.tm_bx .mat-expansion-panel-body {
  padding: 0 10px;
}
ul.tm_bx .bkng_dtl_inr {
  margin: 0;
}
ul.tm_bx .tm_record .bkng_dtl_inr {
  width: 100%;
}
ul.tm_bx .cmpltd_btsn {
  padding: 0;
}
ul.tm_bx .bkng_fd .lft {
  width: 85%;
}
ul.tm_bx span.lnk_act {
  width: 40px;
  margin-left: auto;
  background: #faaf3b;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
}
ul.tm_bx span.lnk_act i.fa.fa-angle-double-right {
  font-size: 20px;
  color: #fff;
}

.mini_sidebar .sdbr_lt {
  width: 80px;
}
.mini_sidebar h5.lead {
  display: none;
}
.mini_sidebar .content_wraper {
  width: 96%;
}
.mini_sidebar i.fa.fa-bars {
  left: 6% !important;
}

.frm_wrpp .fdx {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-top: 20px;
}
.frm_wrpp .fdx .lftdx {
  width: 24%;
  margin-top: 20px;
}
.frm_wrpp .fdx .midx {
  width: 48%;
}
.frm_wrpp .fdx .midx h2 {
  font-size: 22px !important;
  border: 2px solid #e9e9e9;
  padding: 10px;
  background: #e9e9e9;
  border-radius: 7px 7px 0px 0px;
}
.frm_wrpp .fdx .midx form {
  padding: 10px;
  box-shadow: 0px 2px 20px #0000001c;
  border-radius: 7px;
}
.frm_wrpp .fdx .midx form .mat-form-field {
  width: 100%;
}
.frm_wrpp .fdx .rgtx {
  width: 24%;
}
.frm_wrpp .fdx .rgtx h2 {
  font-size: 18px !important;
  border: 2px solid #e9e9e9;
  padding: 10px;
  background: #e9e9e9;
  border-radius: 7px 7px 0px 0px;
}
.frm_wrpp .fdx .rgtx form {
  padding: 10px;
  box-shadow: 0px 2px 20px #0000001c;
  border-radius: 7px;
}
.frm_wrpp .fdx .rgtx form .mat-form-field {
  width: 100%;
}
.frm_wrpp .mat-expansion-panel {
  background: #e9e9e96b;
}
.frm_wrpp .fields_outr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.frm_wrpp .fields_outr .fields_inr {
  width: 40%;
  margin-bottom: 10px;
}
.frm_wrpp .fields_outr .fields_inr h5 {
  font-size: 16px !important;
  font-weight: 300;
  white-space: nowrap;
  color: #000;
  cursor: pointer;
}

.drv_lst {
  width: 600px;
  max-height: 530px;
  height: 530px;
}
.drv_lst .bkng_dtl_inr {
  width: 90%;
}
.drv_lst .bkng_lstb_dtl {
  overflow-x: hidden;
}
.drv_lst .bkng_lstb_dtl .bkng_dtl_inr {
  margin: 5px 0px 15px 1px;
  overflow-x: hidden;
}
.drv_lst .txt_fdx {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  width: 100%;
}

.gm-style-iw-d {
  overflow: hidden !important;
  padding: 2px 14px 8px 3px;
}

.gm-style .gm-style-iw-c {
  position: absolute;
  box-sizing: border-box;
  overflow: hidden;
  top: 0;
  left: 0;
  transform: translate3d(-50%, -100%, 0);
  background-color: var(--primaryColor);
  border-radius: 8px;
  padding: 23px 15px;
  box-shadow: -2px 4px 7px 1px rgba(0, 0, 0, 0.3);
}

/*15/11/22*/
.s_brs .usr_profle a {
  justify-content: flex-end;
  width: 100%;
}

.s_brs .navbar {
  display: flex;
  justify-content: flex-end;
}

.btn_asng {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  background: #fff;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding-bottom: 30px;
}
.btn_asng .scd_bt {
  background: #ccc;
}

.drv_lst app-driver-list {
  position: relative;
}

.drv_lst .txt_fdx {
  height: 100%;
}

.drv_lst mat-dialog-container {
  padding-bottom: 0px;
  overflow: hidden;
}

.bk_t_g {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 70px;
}

.dr_pop {
  background: #fff;
  padding: 10px;
  border-radius: 7px;
  cursor: pointer;
  box-shadow: 0px 0px 3px 1px #0000001f;
  margin: 0px;
  position: relative;
  cursor: pointer;
  height: 80px;
}

.drv_lst app-booking-listings {
  position: relative;
}

.bkng_lstb_dtl.tm_record.txt_fdx {
  align-items: flex-start;
}

.ld_chkbx mat-radio-group {
  display: flex;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  grid-gap: 15px;
}

.ld_chkbx mat-radio-group .mat-radio-label {
  white-space: unset;
}

.s_brs .trial_pck {
  transform: unset;
  text-align: right;
}

.bkng_lft.bkng_rgt {
  margin-bottom: 15px;
}

.lnk_act {
  width: 40px;
  margin-left: auto;
  background: #faaf3b;
  display: inline-block;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  color: #fff;
  padding: 3px;
}
.lnk_act .fa {
  font-size: 20px;
}

.actives {
  background: var(--primaryColor);
  position: relative;
}

.sd_b_pt:hover {
  background: #0000ff0f;
  position: relative;
}

.stg_bt {
  position: fixed;
  bottom: 0;
  cursor: pointer;
}
.stg_bt .fa {
  background: #fff;
  box-shadow: 0 6px 10px 0 rgba(32, 46, 77, 0.08);
  padding: 10px;
  font-size: 20px;
}

.tm_lt .mat-expansion-panel-header.mat-expanded {
  height: auto;
  padding: 10px;
  border: 1px solid;
}

ul.tm_bx .mat-expansion-panel-body {
  padding: 0 10px;
}

.tm_lt .mat-expansion-panel-body {
  padding: 0 !important;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  overflow-x: scroll;
}

.bdy_wrp.custad_wrp.sttng_wrp {
  max-height: 600px;
  position: fixed;
  top: 43px;
}

.bdy_wrp.custad_wrp.sttng_wrp .cstaed_mn {
  height: 100%;
}

.sbs_mn {
  width: 85%;
  margin-left: auto;
}
.sbs_mn .d_fdx {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.sb_br_mn {
  width: 11%;
}

.bkng_map button.gm-control-active.gm-fullscreen-control {
  top: unset !important;
  right: 0px;
  bottom: 90px !important;
}

.bkng_map .gmnoprint {
  top: 50px !important;
  right: unset !important;
}

.slt_pt {
  display: flex;
  margin-bottom: 10px;
}

.pcup_cls .bkng_fd {
  position: relative;
  flex-wrap: wrap;
}

.pcup_cls .bkng_fd .lft {
  width: 100%;
  flex-wrap: wrap;
}

.pcup_cls .bkng_fd .ryt {
  text-align: right;
  position: absolute;
  right: 0;
  width: auto;
}

.pcup_cls .bkdtl_tp .cmpltd_btsn {
  padding-right: 0;
}

.tm_lt .mat-accordion .mat-expansion-panel.br_bkg {
  border: 1px solid #ccc;
}

.bkg_rd .bkng_dtl_inr {
  padding: 0;
  box-shadow: unset;
}

.cmn_cmnt .cstaed_lt {
  width: 20%;
}

.cmn_cmnt .cstaed_rt {
  width: 78%;
}

.pcup_cls.bk_shw .bkdtl_tp .cmpltd_btsn {
  padding-right: 60px;
}

.drv_prnl_otr .prn_nfo .drv_prn_flds .cmbng_dv .pro_flds_inr {
  width: 100%;
}

.doc_inr label {
  text-align: center;
}

.doc_inr .pro_in figure {
  width: 350px;
  height: 170px;
  margin: 0 auto;
}

.rpt_sbr,
.rpt_sbr .cstaed_mn {
  height: calc(100vh - 54px);
}

.ctm_fm form {
  box-shadow: 0px 2px 20px #0000001c;
  padding: 5px 15px;
  margin-bottom: 15px;
  border-radius: 10px;
}

.ctm_fm form .save_bttn {
  padding-bottom: 20px;
}

.srg_mns .add_zonenma {
  position: unset;
}

.srg_mns .zone_data {
  height: auto;
}

.srg_mns .add_zonenma {
  position: unset;
  margin-top: 40px;
  flex-wrap: wrap;
}

.srg_mns .add_zonenma form {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.srg_mns .add_zonenma form .zn_inpt {
  width: 49%;
}

.sbt_bt .btn {
  margin: 0 auto;
}

.sbt_bt {
  width: 100%;
  text-align: center;
}

.vw_bkg {
  height: 100%;
}

body.settings {
  overflow: hidden;
}

.settings div#wrppr_fdx {
  min-height: 500px;
  overflow-y: scroll;
  max-height: calc(100vh - 90px);
}

.dwn_ld {
  position: absolute;
  bottom: -9px;
  right: 0px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0px 0px 7px 4px #00000014;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
}

.cm-data {
  padding: 10px;
}

.ad_sm .iti--allow-dropdown .iti__flag-container,
.ad_sm .iti--separate-dial-code .iti__flag-container {
  right: auto;
  left: 0;
  right: 0;
}
.ad_sm .dropdown-menu.country-dropdown.show.ng-star-inserted {
  width: 100%;
  left: 0 !important;
  right: 0 !important;
}

.ad_ints_dv textarea.form_control + .mat-form-field-label-wrapper label,
.ad_ints_dv textarea.form_control.mat-input-element + .mat-form-field-label-wrapper label {
  top: 5px !important;
}

.date_hdr_dv2 .mat-date-range-input-start-wrapper {
  position: unset;
}

.date_hdr_dv2 .mat-date-range-input-start-wrapper .mat-date-range-input-inner {
  left: -14px;
}

.drv_wp .pg_hd {
  flex-wrap: wrap;
}
.drv_wp .pg_hd .mn_hd {
  margin-bottom: 10px;
}

.bkg_mn_lt .bkng_rgt {
  overflow: hidden;
}

.bkg_mn_lt .bkng_lstb_dtl {
  max-height: calc(100vh - 260px);
}

.frm_cst mat-form-field {
  width: 100%;
}

.frm_cst {
  overflow: hidden;
}

.btn_info {
  background: transparent;
  border: none;
}

.mom tr {
  text-align: center;
}

.alignSpace {
  gap: 20px;
  justify-content: flex-start;
  padding: 0px 17px;
}

.slot_wrp .add_fdx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}
.slot_wrp .add_fdx p {
  font-size: 16px !important;
  font-weight: 500;
  color: #000 !important;
}
.slot_wrp .row.col-md-12 {
  padding: 0;
  align-items: flex-end;
}

.tm_lt mat-expansion-panel-header {
  background: #fff;
}

.add_vhl ul.modal_wrp {
  flex-wrap: nowrap;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
}

.labl_fdx {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fleet_swt {
  width: 50vw !important;
}

.actn_icn button.btn.btn-primary,
.actn_icn .btn.btn-secondary {
  font-size: 12px;
  border-radius: 30px;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.vl-wrp {
  flex-wrap: wrap !important;
}
.vl-wrp .flds {
  position: relative;
}
.vl-wrp .flds i.fa.fa-minus {
  position: absolute;
  right: 12px;
  cursor: pointer;
}

.cst {
  max-height: 700px !important;
  overflow: auto !important;
}

.mat-date-range-input {
  font-size: 12px !important;
}

.showRating-dialog {
  width: 100%;
  max-width: 40vw !important;
}

/*plugin module*/
.pulg-fdx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  margin: 5px 0;
}
.pulg-fdx .plug-in {
  width: 24%;
  border: 1px solid #00000014;
  height: 190px;
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0 0 2px 2px #0000000d;
  position: relative;
}
.pulg-fdx .plug-in .feature_top {
  margin-bottom: 30px;
}
.pulg-fdx .plug-in .feature_top figure {
  width: 100%;
}
.pulg-fdx .plug-in .feature_top figure img {
  width: 25%;
  object-fit: cover;
  object-fit: contain;
  height: 100%;
}
.pulg-fdx .plug-in .feature_top p {
  position: absolute;
  top: 4px;
  right: 4px;
  color: #fff;
  background: var(--primaryColor);
  width: 50px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  border-radius: 3px;
}
.pulg-fdx .plug-in .feature_center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pulg-fdx .plug-in .feature_center h6 {
  font-weight: 700;
  line-height: normal;
  font-size: 16px;
}
.pulg-fdx .plug-in .feature_center li.tg-list-item mat-slide-toggle {
  margin-bottom: 10px;
}
.pulg-fdx .plug-in .feature_bottom {
  padding: 10px 0;
}
.pulg-fdx .plug-in .feature_bottom p {
  font-size: 12px;
  line-height: normal;
  font-weight: 500;
}

/*plugin module end*/
.page-item.active .page-link {
  background-color: var(--primaryColor);
  border-color: #007bff;
}

.page-link {
  color: #000;
}

.mat-tab-label .mat-tab-label-content {
  color: #fff;
  font-weight: 500;
  font-size: 14px;
}

.bon mat-dialog-actions {
  gap: 10px;
}

.wlk_wrp {
  position: relative;
}
.wlk_wrp figure {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #000000ba;
  box-shadow: 0px 0px 4px 0px #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -14px;
  top: -15px;
  z-index: 100000000;
  cursor: pointer;
  color: #fff;
}

.mn_inr .mat-tab-header {
  margin-bottom: 15px;
}

/*zone map*/
.zone_dataa {
  max-height: 600px;
}
.zone_dataa div#map {
  height: 500px;
}

.bdy_wrp::-webkit-scrollbar {
  width: 12px;
  height: 8px;
  background-color: #f5f5f5;
}

.mtrx_tb .table-responsive {
  display: block;
  width: auto;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.mtrx_tb .table-responsive .sotre_id_wrap .mat-dialog-content {
  max-height: unset !important;
}

.bkcrt_dtl .btns {
  margin: 0 !important;
}

/*country flag css*/
.ad_sl .pro_flds_inr ngx-intl-tel-input .dropdown-menu.country-dropdown {
  width: 300px;
}
.ad_sl .pro_flds_inr ngx-intl-tel-input .dropdown-menu.country-dropdown .iti__country-list {
  overflow-x: hidden;
}
.ad_sl .pro_flds_inr ngx-intl-tel-input .dropdown-menu.country-dropdown .iti__country-list li {
  width: 100%;
}

/**********************************************responsive screen start*****************************************************************************/
@media (max-width: 2000px) {
  div#wrppr_fdx app-settings-sidebar {
    width: 15%;
  }

  div#wrppr_fdx app-general,
div#wrppr_fdx app-customize,
div#wrppr_fdx app-auto-allocate,
div#wrppr_fdx app-plugins,
div#wrppr_fdx app-add-plugins,
div#wrppr_fdx app-api,
div#wrppr_fdx app-web-hooks,
div#wrppr_fdx app-billing,
div#wrppr_fdx app-tags,
div#wrppr_fdx app-documents,
div#wrppr_fdx app-bank-form,
div#wrppr_fdx app-cms,
div#wrppr_fdx app-sub-admin,
div#wrppr_fdx app-walkthrough,
div#wrppr_fdx app-forms,
div#wrppr_fdx app-external-fleet,
div#wrppr_fdx app-fleet-documents,
div#wrppr_fdx app-airport-charge,
div#wrppr_fdx app-b2b-managemant,
div#wrppr_fdx app-question-answer,
div#wrppr_fdx app-vehicle,
div#wrppr_fdx app-tax,
div#wrppr_fdx app-new-plugin {
    width: 83%;
  }
}
@media (max-width: 1800px) {
  .add_zonenma {
    bottom: 0;
  }

  .bkng_fd .lft {
    width: 78%;
  }

  .mat-drawer-opened {
    width: 410px !important;
  }

  .bk_tsh {
    width: 32px;
    height: 32px;
  }

  .bk_tsh img {
    max-width: 20px;
  }

  .bkng_lstb_dtl {
    margin-top: 10px;
  }

  h2,
h3,
h4,
h5 {
    font-size: 14px !important;
  }

  .bkng_lstb_dtl .bkng_dtl_inr .bkdtl_tp h2 span {
    margin-top: 3px;
    color: #afafaf;
    font-weight: 400;
  }

  .bkng_fd .ryt h2 {
    font-size: 14px !important;
    font-weight: 500;
  }

  .bkng_lstb_dtl .bkng_dtl_inr .bkdtl_tp h2 {
    margin-bottom: 5px;
  }
}
@media (max-width: 1700px) {
  .add_zonenma {
    bottom: 0;
  }

  .bkdtl_tp h2 {
    font-size: 14px !important;
  }

  .bkng_lstb_dtl .bkng_dtl_inr .bkdtl_tp h2 span {
    font-size: 13px !important;
  }

  .mat-drawer-opened {
    width: 289px !important;
  }

  .bkng_fd .lft {
    width: 80%;
  }

  .bkng_fd .lft h2 {
    font-size: 13px !important;
  }

  .bkng_fd .ryt h2 {
    font-size: 13px !important;
  }

  .bkng_fd .lft p {
    line-height: 20px;
    font-size: 13px !important;
  }

  .bk_tsh img {
    max-width: 18px;
  }

  .bk_tsh {
    width: 26px;
    height: 26px;
    top: 5px;
  }
  .bk_tsh img {
    max-width: 18px;
  }

  .bkng_lstb_dtl .bkng_dtl_inr {
    margin: 5px 0px 12px 10px;
  }

  .bkng_fd {
    padding: 8px 0px;
  }

  .brdrn {
    border-bottom: unset !important;
    padding: 8px 0px 0px !important;
  }

  button.btn.auto_assign {
    padding: 3px 6px;
    font-size: 13px;
    font-weight: 400;
  }

  .bkng_lstb_dtl .bkng_dtl_inr {
    padding: 10px;
  }

  .bkng_fd .lft p {
    line-height: 22px;
  }
}
@media (max-width: 1600px) {
  div#wrppr_fdx app-settings-sidebar {
    width: 18%;
  }

  div#wrppr_fdx app-general,
div#wrppr_fdx app-customize,
div#wrppr_fdx app-auto-allocate,
div#wrppr_fdx app-plugins,
div#wrppr_fdx app-add-plugins,
div#wrppr_fdx app-api,
div#wrppr_fdx app-web-hooks,
div#wrppr_fdx app-billing,
div#wrppr_fdx app-tags,
div#wrppr_fdx app-documents,
div#wrppr_fdx app-bank-form,
div#wrppr_fdx app-cms,
div#wrppr_fdx app-sub-admin,
div#wrppr_fdx app-walkthrough,
div#wrppr_fdx app-forms,
div#wrppr_fdx app-external-fleet,
div#wrppr_fdx app-fleet-documents,
div#wrppr_fdx app-airport-charge,
div#wrppr_fdx app-b2b-managemant,
div#wrppr_fdx app-question-answer,
div#wrppr_fdx app-vehicle,
div#wrppr_fdx app-tax,
div#wrppr_fdx app-add-role-configuration,
div#wrppr_fdx app-new-plugin {
    width: 80%;
  }
}
@media (max-width: 1500px) {
  .bkg_mn_lt .bkng_lstb_dtl {
    max-height: calc(100vh - 220px);
  }

  .sbs_mn {
    width: 79%;
  }

  .sb_br_mn {
    width: 17%;
  }

  .flts_rgt .srch_dv {
    width: 280px;
  }

  div#wrppr_fdx app-settings-sidebar {
    width: 24%;
  }

  app-status-configuration {
    width: 78%;
  }

  .add_zonenma {
    width: 90%;
  }

  .add_zonenma {
    bottom: 0;
  }

  .bkng_wrp .ryt_btm {
    max-height: 423px;
    overflow-y: scroll;
  }

  .bkng_fd .lft {
    width: 85%;
  }

  .mat-drawer-opened {
    width: 270px !important;
  }

  .fltr_inrdrp form {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
  }

  .fltr_inrdrp form .srch_dv {
    width: 100%;
  }

  .fltr_inrdrp .from-group {
    width: 46%;
  }

  .vecle_otr_inr figure {
    height: 70px;
    width: 70px;
  }

  nav.navbar.navbar-expand-md .nv_hd_rt .navbar-nav li {
    margin: 0 5px;
  }

  nav.navbar.navbar-expand-md .nv_hd_rt .navbar-nav li a {
    font-size: 14px;
    font-weight: 600;
    color: #000;
  }

  .bkdtl_tp h2 {
    font-size: 14px !important;
    font-weight: 500;
  }
  .bkdtl_tp h2 span {
    font-size: 13px !important;
    font-weight: 400 !important;
  }

  .bkng_lstb_dtl .bkng_dtl_inr {
    padding: 8px;
  }

  .bkng_fd .ryt h2 {
    font-size: 13px !important;
  }

  button.btn.auto_assign {
    font-size: 12px;
  }

  .pg_hd .mn_hd h1 {
    padding-left: 12px;
    font-size: 18px !important;
  }
}
@media (max-width: 1600px) {
  .content_wraper {
    width: 87%;
  }
}
@media (max-width: 1400px) {
  div#wrppr_fdx app-settings-sidebar {
    width: 22%;
  }

  div#wrppr_fdx app-general,
div#wrppr_fdx app-customize,
div#wrppr_fdx app-auto-allocate,
div#wrppr_fdx app-plugins,
div#wrppr_fdx app-add-plugins,
div#wrppr_fdx app-api,
div#wrppr_fdx app-web-hooks,
div#wrppr_fdx app-billing,
div#wrppr_fdx app-tags,
div#wrppr_fdx app-documents,
div#wrppr_fdx app-bank-form,
div#wrppr_fdx app-cms,
div#wrppr_fdx app-sub-admin,
div#wrppr_fdx app-walkthrough,
div#wrppr_fdx app-forms,
div#wrppr_fdx app-external-fleet,
div#wrppr_fdx app-fleet-documents,
div#wrppr_fdx app-airport-charge,
div#wrppr_fdx app-b2b-managemant,
div#wrppr_fdx app-question-answer,
div#wrppr_fdx app-vehicle,
div#wrppr_fdx app-tax,
div#wrppr_fdx app-new-plugin {
    width: 75%;
  }

  .lead {
    font-size: 12px !important;
  }

  .pulg-fdx .plug-in {
    width: 32%;
  }

  .table td,
table td,
.table th,
table th {
    font-size: 12px;
  }

  app-status-configuration {
    width: 76%;
  }

  h2,
h3,
h4,
h5 {
    font-size: 14px !important;
  }

  ul.tm_bx .bkng_fd .lft {
    width: 100%;
  }

  .tm_record button.btn.auto_assign {
    width: 70px;
  }

  .mat-drawer-opened {
    width: 300px !important;
  }

  .add_zonenma {
    width: 90%;
  }

  body.mini_sidebar .content_wraper {
    width: 95%;
  }

  body.mini_sidebar i.fa.fa-bars {
    left: 10% !important;
  }
}
@media (max-width: 1200px) {
  div#wrppr_fdx app-settings-sidebar {
    width: 23%;
  }

  app-status-configuration {
    width: 75%;
  }

  .content_wraper {
    width: 85%;
  }

  .sdbr_lt {
    width: 17.5%;
  }
}
.NgxEditor {
  background: white;
  color: #000;
  background-clip: padding-box;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  position: relative;
  max-height: 389px;
  overflow: auto;
}

.NgxEditor__Content p {
  margin: 0 0 10px;
  color: black;
}

/*****************************************************************************responsive screen end*******************************************************************************************************************/