/* You can add global styles to this file, and also import other style files */
@import "./assets/global/variable.scss";
@import "~ngx-sharebuttons/themes/material/material-dark-theme";
@import "~ngx-sharebuttons/themes/modern/modern-light-theme";
@import "./assets/fonts/stylesheet.css";

body,
.mat-tab-group,
.mat-tab-label,
.mat-expansion-panel-header-title,
.mat-expansion-panel-header-description {
  margin: 0;
  font-family: "Bw Modelica" !important;
}

@font-face {
  font-family: "Bw Modelica";
  src: url("assets/fonts/BwModelica-Black.woff2") format("woff2"),
    url("assets/fonts/BwModelica-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Bw Modelica";
  src: url("assets/fonts/BwModelica-Bold.woff2") format("woff2"),
    url("assets/fonts/BwModelica-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Bw Modelica";
  src: url("assets/fonts/BwModelica-Light.woff2") format("woff2"),
    url("assets/fonts/BwModelica-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Bw Modelica";
  src: url("assets/fonts/BwModelica-Regular.woff2") format("woff2"),
    url("assets/fonts/BwModelica-Regular.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

$primary_color: $primaryColor;
$secondary_color: $secondaryColor;
$text_Color: $textColor;
$label_color: #818181;
$theme_black: #202020;
$red_color: #ff5252;
$ongoing_status: #00b5e0;
$success_color: #02a70f;
$white: #fff;
$border_radius: 14px;
$para_color: #a5a5a5;
$increment: #ccd2d6;
$decrement: #cce0ec;
$box_shadow: 0 2px 20px #0000000d;
$font_family: "Bw Modelica";
$border_color: #e5e5e5;

* {
  margin: 0;
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  transition: ease-in-out 0.3s;
}

.mat-option,
.mat-tab-label,
.mat-tab-link,
.mat-form-field {
  font-family: $font_family !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  color: $theme_black;
}

label,
span {
  font-size: 14px !important;
}

p {
  color: $para_color;
  font-size: 14px;
}

ul,
ol,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

a,
a:hover,
a:focus {
  cursor: pointer;
  text-decoration: none;
}

.conta_iner {
  width: 95%;
  margin: 0 auto;
  padding: 0;
}

.all_sec {
  padding: 40px 0 0;
}

.blk_span {
  display: block;
}

.mid_sec {
  position: relative;
  width: 100%;
}

figure {
  margin: 0;
}

img {
  max-width: 100%;
}

/*sidebar new scss*/
.sttng_inrwrp {
  width: 100%;
}

.fgf {
  position: absolute;
  left: 15%;
  right: 0;
  top: 5px;
  width: 200px;
  z-index: 9999;
}
.vhl-profile {
  .bck_buttn.rgt_spce {
    display: flex;
    justify-content: end;
  }
}
/*direction RTL scss*/

.direction {
  direction: rtl;
  overflow-x: hidden;

  .fltr_inrdrp form .srch_dv .cross_icn {
    left: 10px;
    top: 0px;
    right: unset;
}




.tmln_inr .tml_lt .tml_lt_rt .tm_ordr{
  text-align: 12px !important;
}
.tmln_inr::before{
  right: 22px;
  left: unset;
}
.bkodr_trkng_sc .ordr_tmlne {
  padding: 10px;
}
.tmln_inr .tml_lt .tml_lt_rt,.bkdt_usr_dt .bkdt_csmr_lt .csmr_dtl .csmr_dtl_rt {
  padding-left: 0;
  padding-right: 20px;
}

  .prnl_pro span.upld_flr {
    right: 38px;
    left: unset;
  }
  .bdy_wrp.custad_wrp.sttng_wrp {
    width: 12%;
}

  .sbs_mn {
    width: 85%;
    margin-left: 0;
    margin-right: auto;
}
.wlk_wrp figure{
  right: -35px;
  top: -35px;
}
.pro_in h6 {
  text-align: right;
  margin-bottom: 10px;
  font-size: 14px;
}

.ad_sm{
  mat-label{
    text-align: right;
    width: 100%;
  }
}

  .mat-tab-header{
    flex-direction: row-reverse;
  }
  .mat-tab-label-container{
    justify-content: end;
  }
  .rht_cls i.fa.fa-angle-left, i.fa.fa-list{
    transform: rotate(180deg);
  }

  .rfl-typ{
    display: flex;
    justify-content: start;
    span.mat-radio-label-content {
      padding-right: 10px;
      padding-left: 0;
  }
  }

  .vhl-profile {
    .bck_buttn.rgt_spce {
      display: flex;
      justify-content: end;
    }
  }
  .pck_drp_inr .pck_lct_dv .ad_stps{
    left: 0;
    right: unset;
  }
  .pck_drp_inr .pck_lct_dv.drp_lct_dv {
    padding: 30px;
}
.mat-form-field-label-wrapper label{
  right: 6px;
  left: unset;
}

  .img-src {
    display: inline-flex;
    justify-content: end;
    width: 100%;
  }
  .slot-mt {
    flex-direction: row-reverse;
  }
  .bk_lck_otr .bk_lck_inr::before{
    right: 18px;
    left: unset;
  }

  .mat-select-arrow {
    position: absolute;
    left: 0;
  }
  .faeye {
    left: 10px;
    right: unset;
  }
  .bkdt_usr_dt .bkdt_csmr_lt::after{
    left: 20px;
    right: unset;
  }
  .form-control,
  .slot h1,
  .tm-pcr input,.mat-select-value,.bkodr_trkng_sc .ordr_lst_dv .odr_slp_inr h4 ,  .form_control,.pck_drp_inr h3,
  .form_control.mat-input-element ,.bkdt_usr_dt .bkdt_csmr_lt .csmr_dtl .csmr_dtl_rt h4, .csmr_lt_inr,.tmln_inr .tml_lt .tml_lt_rt .tm_ordr {
    text-align: right;
  }
  .track_sa{
    gap: 10px;
  }

  .ad_sm mat-label {
    display: inline-block;
    margin: 10px 0;
}

.pck_drp_inr .pck_lct_dv {
  background-position: right center;
  padding-right: 30px;

}

  .sd_br {
    left: unset !important;
    right: 0 !important;
  }
  nav.pagination,
  .fdx {
    justify-content: flex-start;
  }
  label,
  .title-bar_lft h2,
  .title-bar_lft p,.bkdt_odr_dt .bkdtl_tp .bk_id h4 {
    text-align: right;
  }
  .bkdt_usr_dt .bkdt_csmr_lt .cntdtl_inr figure {
    margin-left: 28px;
}
  .sb_br_mn {
    width: 8%;
  }
  .flts_rgt {
    justify-content: start;
  }
  .right_slide {
    left: 0 !important;
    right: unset !important;
  }
  .side_br {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    top: 0px;
    right: 100%;
  }
  .rht_cls {
    top: 0px;
    left: unset;
    right: -36px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .lead {
    margin-right: 10px;
  }
  .bkng_fd .lft h2 {
    margin-right: 20px;
    justify-content: end;
  }
  .tm_lt figure {
    margin: 0 0 0 5px;
  }
  ul.tm_bx li mat-panel-title {
    margin: 0 0 0 10px;
  }
  .bdy_wrp {
    padding: 0 0 0 20px;
  }
  .content_wraper {
    margin-right: 184px;
    width: 90%;
    padding: 50px 20px 0px 0px;
  }
  .sdbr_lt {
    right: 0;
    width: 10.5%;
    @media (max-width: 1300px) {
      width: 15.5%;
    }
  }
  .cstaed_lt .cstaed_inr h3 {
    text-align: right;
  }

  div#wrppr_fdx {
    justify-content: end;
  }

  .fgf {
    right: 15%;
    left: 0;
  }
  .sidebar_open i.fa.fa-bars {
    right: 13%;
    left: unset !important;
  }
  .mini_sidebar i.fa.fa-bars {
    right: 6%;
  }
  .pg_hd .mn_hd h1 {
    text-align: right;
  }
  .pg_hdbutton {
    flex-direction: unset;
  }
  .mn_hd p {
    text-align: right;
  }
  .pcup_cls.bk_shw .bkdtl_tp .cmpltd_btsn{
    padding: 0;
  }
  .bkng_lstb_dtl .bkng_dtl_inr .bkdtl_tp h2 {
    width: 60%;
}
.bkng_lstb_dtl .bkng_dtl_inr .bkdtl_tp h2 span{
  margin-top: 10px;
}



}

/*direction RTL scss end*/

/*sidebar new scss end*/

/************************************newbg  css  ************************************/

.cmn_cmnt {
  position: relative;
  z-index: 2;
}

.sdbr_lt mat-nav-list {
  height: 100% !important;
  overflow: auto;
  padding-left: 5px;
  .lt_sidebar {
    height: 100%;
  }
  .mat-list-base .mat-list-item,
  .mat-list-base .mat-list-option {
    height: 42px;
  }
}

.order_status_wrap {
  background: #fff;
  border-radius: 10px;
  padding: 10px;
  position: relative;
  margin-top: 30px;
}
/************************************newbg  css  ************************************/

.mn_hd h1 {
  font-size: 22px;
  line-height: normal;
  font-weight: bold;
  color: #202020;
}

.pg_hd {
  margin: 10px 0 0;
}

.srch_inpt {
  padding: 13px 10px 13px 50px !important;
}

table.table tr th {
  white-space: nowrap;
}

.btn {
  border: unset;
  box-shadow: unset !important;
  background: #202020;
  color: #fff;
  border-radius: 4px;
  line-height: normal;
  font-weight: 400;
  font-size: 11px;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn.btn-primary {
  background: $primary_color;
  color: #fff;
  white-space: nowrap;
}

.btn.btn-primary:hover {
  background: $secondary_color;
  color: #fff;
}

.btn.btn-secondary {
  background: $secondary_color;
  color: #fff;
}

.btn.btn-secondary:hover {
  background: $primary_color;
  color: #fff;
}

.btn.btn-secondarys {
  background: #2323b3;
  color: #fff;
}

.btn.btn-secondarys:hover {
  background: #5353d7;
  color: #fff;
}

.ad_bkng_btn {
  padding: 5px;
  width: 100%;
}

.bkng_add button {
  background: var(--secondaryColor);
  color: #fff;
}

.bkng_lft {
  .bkng_add {
    padding: 10px;
    width: 90%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 30px;
    z-index: 1;
    margin: 0 auto;
    background: #fff;

    button {
      width: auto;
      font-size: 14px;
      font-weight: 500;
      margin: 0 auto;
    }
  }
}

.bkng_map #map > div {
  height: 100vh !important;
}

.mat-menu-content {
  padding-top: 0 !important;
}

.mat-menu-item {
  height: 32px !important;
}

.mat-tab-label {
  border: solid 1px #e5e5e5;
  border-radius: 3px;
  background: #fff;
  padding: 10px;
  margin-right: 10px;
  opacity: 1;
  color: #818181;
  line-height: normal;
  font-weight: 400;
  font-size: 16px;
  text-transform: capitalize;
  padding: 5px 15px !important;
  min-width: auto !important;
  height: auto !important;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: unset;
}

.bkng_tbg {
  margin: 10px 0;
}

mat-ink-bar.mat-ink-bar {
  display: none !important;
}

.mat-tab-label.mat-tab-label-active {
  background: #00649e;
  opacity: 1;
  color: #fff;
  border-color: #00649e;
}

label {
  display: block;
  font-weight: 400;
  color: #202020;
  margin-bottom: 0;
  padding: 10px;

  span.red {
    color: red;
    font-weight: bold;
  }
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: var(--primaryColor);
}

div#wrppr_fdx {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  position: relative;
  app-settings-sidebar {
    width: 20%;
  }
  app-general,
  app-customize,
  app-auto-allocate,
  app-plugins,
  app-add-plugins,
  app-api,
  app-web-hooks,
  app-billing,
  app-tags,
  app-documents,
  app-bank-form,
  app-cms,
  app-sub-admin,
  app-walkthrough,
  app-forms,
  app-external-fleet,
  app-fleet-documents,
  app-airport-charge,
  app-b2b-managemant,
  app-question-answer,
  app-vehicle,
  app-tax,
  app-new-plugin {
    width: 75%;
  }
}

.d_wrap_ryt {
  width: 100%;
  padding-top: 16px;
}

.mat-form-field-appearance-fill .mat-form-field-subscript-wrapper,
.mat-form-field-underline {
  display: none;
}

.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0;
  border: unset;
}

.mat-form-field.mat-form-field-appearance-fill {
  .mat-form-field-flex {
    border-radius: 0;
    padding: 0;
    background: transparent;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
}

.mat-form-field-label-wrapper label {
  top: unset !important;
  margin: 0 !important;
  left: 6px;
}

.mat-form-field-label-wrapper {
  top: 0;
  padding-top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.bkng_tbg .mat-tab-label .mat-tab-label-content {
  font-size: 12px;
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 0;
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0;
  border: unset;
}

.form_control,
.form_control.mat-input-element {
  font-size: 14px;
  line-height: normal;
  background: #46464617;
  padding: 10px 15px;
  border-radius: 4px;
  width: 100%;
  margin: 0;
  resize: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  color: #202020;
  font-weight: 400;
  border: unset;
  box-sizing: border-box;
}
.ng_pending {
  background-color: #8658c1;
  border: unset;
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
  padding: 5px 10px;
  font-weight: 500;
}

.form_control.mat-input-element.srch_inpt,
.form_control.srch_inpt {
  background: #fff url(assets/images/search.svg);
  background-repeat: no-repeat;
  background-position: left 10px center;
  padding-left: 55px;
  background-size: 22px;
}

td.ac_btn {
  display: flex;
  gap: 5px;
  button {
    border-radius: 30px;
    font-size: 12px;
    width: 90px;
    padding: 7px 12px;
  }
}

nav.navbar.navbar-expand-md {
  background: $primary_color;
  padding: 5px 30px;
  max-height: 62px;
  position: fixed;
  width: 100%;
  z-index: 99;

  .nv_hd_rt {
    justify-content: center;
    position: relative;
    i.fa.fa-bars {
      position: absolute;
      left: 13%;
      top: 15px;
      font-size: 20px;
      cursor: pointer;
    }

    .navbar-nav {
      li {
        margin: 0 15px;

        a {
          color: $text_Color;
          font-size: 16px;
          font-weight: 500;
          line-height: normal;
          padding: 15px 10px;
          position: relative;
          z-index: 1;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          &::before {
            content: "";
            position: absolute;
            bottom: -1px;
            width: auto;
            height: 5px;
            border-radius: 50px;
            left: 5px;
            right: -5px;
            background: transparent;
          }

          span {
            padding-right: 8px;
            svg {
              path {
                stroke: $text_Color;
              }
            }
          }
        }

        a.active,
        a:hover {
          &::before {
            background-color: #fff;
          }
        }
      }
    }
  }
}

.bdy_wrp {
  height: 100%;
  overflow: auto;
  padding: 20px;
}

.status_configuration {
  .col-md-6 {
    margin-bottom: 10px;
  }
}

nav.navbar.navbar-expand-md .nv_hd_rt .navbar-nav li ul li {
  margin: 0 0px 10px;
  padding: 0 10px;

  a {
    color: $text_Color;
    padding: 0px;
    font-size: 18px;
    font-weight: 400;

    &:hover {
      color: $secondary_color;
    }

    &:before {
      content: unset;
    }
  }
}

/**dashborad page**/

.sidebar_open {
  position: relative;
  i.fa.fa-bars {
    position: fixed;
    left: 13%;
    z-index: 999;
    top: 15px;
    font-size: 20px;
    cursor: pointer;
    color: #fff;
  }
}

app-status-configuration {
  width: 80%;
  margin-top: 30px;
}

.count_fdx {
  display: flex;
  align-items: center;
  gap: 15px;
}

li.dash_antk_inr p {
  color: #fff;
  font-size: 18px !important;
  padding-top: 20px;
  font-weight: 500;
}

.dash_anltc {
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  .dash_antk_inr {
    padding: 20px;
    box-shadow: $box_shadow;
    width: 18%;
    min-height: 150px;
    border-radius: 14px;

    h2 {
      font-size: 24px !important;
      line-height: normal;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .antk_inrdcrmt {
      display: flex;
      align-items: stretch;
      justify-content: center;
      margin-top: 10px;

      .ant_updn {
        background: #ddd;
        display: block;
        padding: 7px;
        border-radius: 8px;
        color: #202020;
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
      }

      .ant_updn.decrement {
        background: $decrement;
      }

      .ant_updn.increment {
        background: $increment;
      }
    }
  }
}

ul.dash_anltc li.dash_antk_inr h2 {
  color: #fff;
  font-weight: 500;
}

.dsh_mid_mn {
  h3 {
    font-size: 16px;
    line-height: normal;
    font-weight: bold;
    color: $theme_black;
  }

  p {
    font-size: 16px;
    line-height: normal;
    font-weight: 400;
    color: $para_color;
    margin-top: 5px;
  }
}

.dsh_ttl_ern {
  h2 {
    font-size: 40px;
    line-height: normal;
    font-weight: 700;
    margin-bottom: 20px;
  }

  button {
    margin-top: 30px;
  }
}

.dsh_mid_sc {
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  .dsh_ttl_ern,
  .dsh_dlyrvnu {
    width: 40%;
  }

  .dsh_ttl_reven {
    width: 18%;
  }
}

.dsh_dlyrvnu figure {
  margin-top: 13px;
}

.mat_piker {
  position: relative;
  transform: translateY(34px);

  .mat-date-range-input-container {
    display: flex;
    align-items: center;
    padding: 7px;
  }
  mat-datepicker-toggle.mat-datepicker-toggle {
    background: #46464617;
    border-radius: 10px;
    width: 100%;
    padding: 8px;
  }
  button.mat-focus-indicator.mat-icon-button.mat-button-base {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  mat-date-range-input.mat-date-range-input {
    position: absolute;
    bottom: 41px;
    left: 45px;
    width: 80%;
  }
}

.drp_pci_lst {
  margin-top: 15px;

  .drp_pci_inr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px #f5f5f5;
    padding: 0 0 10px 0;
    margin-bottom: 10px;

    h4,
    h5 {
      color: #202020;
      font-size: 18px;
      line-height: normal;
      font-weight: 500;
      position: relative;
      z-index: 1;
    }

    h4 {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 15px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        height: 7px;
        width: 7px;
        border-radius: 50%;
        background: $theme_black;
      }
    }
  }

  .drpoff {
    h4 {
      &::before {
        background: #36b368;
      }
    }
  }

  .pckup {
    h4 {
      &::before {
        background: #2ca3e2;
      }
    }
  }
}

.actives .sidenav-item h5.lead {
  color: #fff;
  font-weight: 600;
}
.sd_b_pt.actives i {
  color: #fff;
  opacity: 100;
  filter: revert;
}

.dash_wdb_sc {
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  .wrng_dv,
  .dhbkn_dv {
    width: 45%;
    max-height: 500px;
  }

  .dhdrvr_dv {
    width: 53%;
    max-height: 500px;
  }
}

.bkng_grp_lst {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.dhdrvr_ntn {
  margin-top: 30px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column-reverse;

  figure {
    text-align: center;
  }

  .ntn_cntn {
    font-size: 22px;
    line-height: normal;
    font-weight: bold;
    margin-right: 20px;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 26px;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      height: 14px;
      width: 14px;
      background: #000;
      border-radius: 50%;
    }
  }

  .idel span {
    color: #f06353;

    &::before {
      background: #f06353;
    }
  }

  .onroute span {
    color: #005365;

    &::before {
      background: #005365;
    }
  }

  .logedin h3 span {
    color: #44929f;

    &::before {
      background: #44929f;
    }
  }

  .logedoff h3 span {
    color: #c29400;

    &::before {
      background: #c29400;
    }
  }

  .ntn_inr {
    margin-bottom: 10px;

    h3 {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
}

.ntn_lst {
  margin-top: 20px;
}

.dhbkn_dv figure,
.dhbkn_dv figure img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.wrng_dh_inr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f8f8f8;
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  h3 {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    figure {
      background: #e9e9e9;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
    }
  }
}

.wrng_dh_rt {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100px;
}

.ttl_ntfy.count {
  color: #f06353;
}

.ttl_ntfy {
  background: #fff;
  height: 30px;
  width: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #a5a5a5;
  font-size: 16px;
  line-height: normal;
  font-weight: 400;
  margin-right: 10px;
}

.wrng_dh_mn {
  margin-top: 30px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  .wrng_dh_lft {
    width: 50%;
    border-right: solid 2px #efefef;
    padding-right: 15px;
  }

  .wrng_dh_rgt {
    width: 50%;
    /* padding-left: 15px; */
    padding: 0px 10px;
    max-height: 380px;
    overflow-y: auto;
  }
}

.signup_frm .frm_btn button {
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.2px;
}

.wrng_dh_cntn {
  display: flex;
  background: #fdf3f2;
  padding: 15px;
  border-radius: 6px;
  flex-wrap: wrap;
  gap: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  h4,
  p {
    color: #f06353;
    line-height: normal;
    font-weight: 400;
    font-size: 16px;
  }

  h4 {
    width: 70%;
    span {
      margin-top: 5px;
    }
  }

  p {
    width: 28%;
    text-align: right;
  }
}

/**booking page css**/
.drvlst_tpdv {
  background: #002033;
  border-radius: 14px;
  padding: 30px 30px;
  width: 400px;
  min-height: 120px;

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;

    li {
      color: #fff;
      font-size: 26px;
      line-height: normal;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      span {
        font-size: 14px;
        line-height: normal;
        font-weight: 400;
        padding-left: 6px;
      }
    }
  }
}

.drvlst_info {
  background: #f8f8f8;
  padding: 20px;
  border-radius: 6px;
  width: 95%;
  margin: 0 auto 0 0;

  .drv_infotp {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    figure {
      height: 50px;
      width: 50px;
      overflow: hidden;
      border-radius: 50%;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: top;
      }
    }

    .drv_tp_rt {
      margin-left: 15px;

      h3 {
        font-size: 20px;
        line-height: normal;
        font-weight: 400;
        margin-bottom: 2px;
      }

      h4 {
        font-size: 16px;
        line-height: normal;
        font-weight: 400;
        position: relative;
        z-index: 1;
        color: #202020;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 15px;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          height: 10px;
          width: 10px;
          background: #202020;
          border-radius: 50%;
        }
      }
    }
  }

  .drv_infobttm {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin-top: 15px;

    h4 {
      color: #202020;
      line-height: normal;
      font-weight: 400;
      font-size: 18px;

      span {
        color: #a5a5a5;
        line-height: normal;
        font-weight: 400;
        font-size: 16px;
        margin-bottom: 2px;
      }
    }
  }
}

.slotModall2 {
  width: 35vw !important;
}

.drvlst_inr {
  background: #fff;
  padding: 20px;
  border-radius: 14px;
  box-shadow: $box_shadow;
  position: relative;
  top: -40px;

  .drvlst_hdmn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    h3 {
      font-size: 20px;
      line-height: normal;
      font-weight: 400;
      color: #202020;
    }

    a.view_all {
      font-size: 16px;
      font-weight: bold;
      line-height: normal;
      color: #202020;
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-right: 22px;

      &::after {
        content: "";
        position: absolute;
        right: 0;
        border-top: solid 8px #c4c4c4;
        border-left: solid 8px transparent;
        border-right: solid 8px transparent;
      }
    }
  }
}

.bkng_sc {
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  /* height: calc(100vh - 60px);*/
  .bkng_map {
    height: 100%;
  }

  .crt_bkng .bkng_sc .bkng_lft {
    width: 25%;
    background: #fff;
  }

  .bkcrt_dtl {
    padding: 20px;
  }

  .bkng_rgt {
    position: relative;
    z-index: 1;
    width: 75%;

    .bkng_drvlst {
      top: 10px;
      position: absolute;
      transform: translate(-600px, 10px);
      right: 100px;
    }

    .bkng_map {
      figure {
        height: 100%;
        width: 100%;
        object-fit: cover;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

/*new slide css*/

.side_br {
  background: #ffffff;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  color: #0d0505;
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: -35px;
}

/*new slide css end*/

.src_fltr_otr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  .srch_dv {
    width: 90%;

    mat-form-field.mat-form-field {
      width: 100%;
    }
  }

  a.flt_inr {
    height: 50px;
    width: 50px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
}

.form_control.srch_inpt + span.mat-form-field-label-wrapper label {
  left: 55px;
}

.bkdrv_dtl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;

  figure {
    height: 60px;
    width: 60px;
    overflow: hidden;
    border-radius: 50%;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.bkng_lstb_dtl {
  margin-top: 30px;
  max-height: calc(100vh - 130px);
  overflow-y: auto;
  padding-right: 5px;

  .bkng_dtl_inr {
    background: #fff;
    padding: 15px;
    border-radius: 7px;
    cursor: pointer;
    box-shadow: 0px 0px 3px 1px #0000001f;
    margin: 5px 0px 15px 10px;
    position: relative;
    cursor: pointer;

    .bkdtl_tp {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      border-bottom: 1px solid #dedede61;
      position: relative;

      h2 {
        color: #000;
        line-height: normal;
        margin-bottom: 10px;
        font-size: 15px !important;
        font-weight: 600;

        span {
          display: block;
          margin-top: 10px;
          font-size: 12px !important;
        }
      }

      .bk_id {
        h4 {
          font-size: 14px;
          font-weight: 400;
          line-height: normal;
          margin-top: 3px;
          color: #202020;
        }

        h3 {
          font-size: 14px;
          line-height: normal;
          font-weight: 700;
          color: #202020;
        }
      }
    }

    .lst_chd {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 0px 0px;
      width: 100%;

      .asgn_lft {
        width: 90%;

        .mat-form-field {
          width: 100%;
        }

        .mat-select {
          padding: 10px 5px !important;
          border-radius: 7px;
        }
      }

      .asgn_ryt {
        width: 12%;
        margin-right: 5px;

        .bk_tsh {
          background: #fff;
          border-radius: 50%;
          box-shadow: 0px 0px 7px 4px #00000014;
          padding: 3px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 25px;
          height: 25px;
        }
      }
    }
  }
}

.bkng_fd .lft h2 {
  font-size: 14px !important;
  font-weight: 600;
  display: flex;
  align-items: flex-start;
  gap: 5px;
  width: 108px;
}

.bkng_fd .mid p {
  font-size: 13px !important;
  color: #000;
  font-weight: 400;
  line-height: 18px;
}

.bkng_fd .ryt h2 {
  font-size: 15px !important;
  font-weight: 600;
  color: #000;
}

.bkng_fd {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  border-bottom: 1px solid #dedede61;
  padding: 10px 0px;
  width: 100%;

  .lft {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 75%;
    gap: 10px;

    figure {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }
  }

  .mid {
    width: 40%;

    p span {
      display: block;
    }
  }

  .ryt {
    width: 18%;
    text-align: right;
  }
}

.bk_lck_otr {
  margin-bottom: 20px;

  .bk_lck_inr {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 20px;
    position: relative;
    z-index: 1;
    font-size: 12px;
    line-height: normal;
    font-weight: 400;
    color: #202020;

    &:last-child {
      padding: 0;
    }

    .bk_dte {
      line-height: normal;
      color: #a5a5a5;
      font-size: 14px;
      font-weight: 400;
      padding-right: 40px;
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
      min-width: 110px;

      &::after {
        content: "";
        position: absolute;
        right: 15px;
        height: 8px;
        width: 8px;
        background: #202020;
        border-radius: 50%;
        top: 5px;
      }
    }

    &::before {
      content: "";
      position: absolute;
      left: 90px;
      top: 16px;
      width: 1px;
      background: #202020;
      bottom: 0px;
    }

    &:last-child::before {
      content: unset;
    }
  }

  .bk_lck_inr.drpof {
    .bk_dte {
      &::after {
        background: #002033;
      }
    }
  }
}

.bk_drv_asgn {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .trash_spn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    background: #fff;
    box-shadow: $box_shadow;
    border-radius: 50%;
    cursor: pointer;
  }
}

.bk_stus button.btn {
  padding: 5px 15px;
}

.bk_stus.cancel button.btn {
  /* background: #FF5252; */
  color: #fff;
}

.cdk-overlay-container
  .cdk-overlay-pane
  .mat-select-panel.drvr_optn
  .mat-optgroup-label,
.cdk-overlay-container
  .cdk-overlay-pane
  .mat-select-panel.drvr_optn
  .mat-option {
  font-size: inherit;
  line-height: normal !important;
  height: auto !important;
  padding: 0;
}

.bkdrv_dtl_rt {
  width: 90%;
  padding-left: 15px;

  h3 {
    font-size: 16px;
    line-height: normal;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .drv_stts {
      color: #36b368;
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 14px;
      line-height: normal;
      padding-left: 12px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        height: 8px;
        width: 8px;
        background: #36b368;
        border-radius: 50%;
      }
    }
  }

  h4 {
    font-size: 14px;
    line-height: normal;
    font-weight: 400;
    margin-top: 5px;

    span {
      color: #a5a5a5;
      padding-right: 5px;
    }
  }
}

.drv_ens_sc {
  .mat-tab-label.mat-tab-label-active {
    background: #000 !important;
  }
}

.asgn_drv {
  width: 100%;
  position: relative;

  mat-form-field.mat-form-field {
    width: 100%;

    mat-select {
      /* background: #F8F8F8; */
      background: #46464617;
      padding: 15px 40px;
    }
  }

  .bk_tsh {
    position: absolute;
    top: 8px;
    right: 5px;

    .trash_spn {
      background: none;
      box-shadow: unset;
      width: 30px;
      height: 30px;
    }
  }
}

figure.nv_logo {
  width: 100px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    border-radius: 7px;
  }
}

button.btn.auto_assign {
  background: #36b368;
  color: #fff;
  padding: 4px 7px;
  font-size: 12px;
}

.drvlst_info.online {
  h4.drv_stus {
    color: #36b368;

    &::before {
      background: #36b368;
    }
  }
}

.fltr_inrdrp {
  .from-group.dtpcik_dv {
    .date_hdr_dv .mat-form-field-flex {
      padding: 0;
      background: transparent;
      border-radius: 0;
    }

    .date_hdr_dv {
      /* background: #f8f8f8; */
      background: #46464617;
    }
  }
}

.fltr_inrdrp {
  right: 0;
  background: #fff;
  border-radius: 5px;
  padding: 5px;
  z-index: 1;

  .from-group.dtpcik_dv .mat-form-field-flex {
    padding: 15px 15px;
    background: #f8f8f8;
    border-radius: 14px;
  }

  .from-group {
    width: 46%;
  }

  form {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .srch_dv {
      width: 90%;
      position: relative;
      mat-form-field.mat-form-field {
        width: 100%;
      }
      input{
        background-color: unset;
      }

      .cross_icn {
        position: absolute;
        right: 10px;
        top: 8px;
        cursor: pointer;
      }
    }
  }

  li.inte_inr figure {
    width: 100px;
    height: 50px;
    transform: translate(0px, -10px);
  }

  .from-group .form_control {
    background: #46464617;
  }

  .apply_btn_inr {
    border: unset;
    background: #002033;
    height: 43px;
    width: 52px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .from-group.apply_btn {
    width: 50px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .from-group mat-form-field.mat-form-field {
    width: 100%;
  }
}

.fltr_inrdrp {
  .from-group {
    position: relative;
    label {
      position: absolute;
      top: -30px;
    }
  }
}

.bkdt_odr_dt {
  background: #f8f8f8;
  padding: 20px;
  border-radius: 14px;

  .bkdtl_tp {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 20px;

    .bk_id {
      h3 {
        font-size: 20px;
      }

      h4 {
        font-size: 18px;
        line-height: normal;
        font-weight: 400;
        margin-top: 10px;
      }
    }
  }
}

ul.inte_otr figure {
  width: 100px;
  height: 50px;
}

.bkdt_usr_dt {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin: 30px 0px;

  .bkdt_csmr_lt {
    width: 30%;
    position: relative;
    z-index: 1;

    .csmr_dtl {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 20px;

      figure {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        overflow: hidden;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .csmr_dtl_rt {
        padding-left: 20px;
        h3 {
          font-size: 20px;
        }

        h4 {
          font-size: 16px;
          line-height: normal;
          font-weight: 400;
          margin-top: 5px;
          width: 230px;
        }
      }
    }

    .cntdtl_inr {
      margin-bottom: 15px;
      font-size: 18px;
      line-height: normal;
      font-weight: 400;
      color: #202020;
      display: flex;
      contain: content;
      justify-content: flex-start;

      &:last-child {
        margin-bottom: 0;
      }

      figure {
        margin-right: 8px;
      }
    }

    &::after {
      content: "";
      position: absolute;
      right: 20px;
      background: #ddd;
      width: 1px;
      top: 0;
      bottom: 0;
    }

    &:last-child::after {
      content: unset;
    }
  }
}

.bkodr_trkng_sc {
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  .ordr_lst_dv {
    width: 28%;

    .odr_slp_inr {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      h4 {
        width: 50%;
        font-size: 16px !important;
        line-height: normal;
        font-weight: 400;

        &:last-child {
          text-align: right;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .odr_slp_inr.ttl_amnt {
      border-top: solid 1px #a5a5a5;
      padding-top: 16px;
      margin-top: 20px;

      h3 {
        font-size: 20px;
        font-weight: bold;
        width: 50%;

        &:last-child {
          text-align: right;
        }
      }
    }
  }

  .ordr_tmlne {
    width: 25%;
    border: 1px solid #a3a3a31a;
    padding: 5px;
    border-radius: 7px;
  }

  .ord_tck_dv {
    width: 42%;
    border: 1px solid #a3a3a31a;
    padding: 5px;
    border-radius: 7px;
  }
}

.tmln_otr {
  padding-top: 15px;
}

.tmln_inr {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  z-index: 1;
  padding-bottom: 25px;

  figure {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    background: #efefef;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tml_lt {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .tml_lt_rt {
      padding-left: 20px;

      .tm_ordr {
        display: block;
        color: #a5a5a5;
        line-height: normal;
        margin-bottom: 2px;
        font-size: 14px;
      }

      h3 {
        font-size: 16px;
        line-height: normal;
        font-weight: 600;
      }
    }
  }

  .tml_rt {
    width: 20%;
    text-align: right;
  }

  &::before {
    content: "";
    position: absolute;
    left: 22px;
    top: 0px;
    bottom: 0;
    background: #dbdbdb;
    width: 2px;
    z-index: -1;
  }

  &:last-child::before {
    content: unset;
  }
}

.tmln_inr.active {
  figure {
    background: #00649e;

    img {
      filter: grayscale(1) brightness(10);
    }
  }

  &::before {
    background: #031844;
  }
}

.trp_dtl_otr {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .trp_dtl_rt {
    width: 30%;

    mat-form-field.mat-form-field {
      width: 100%;
    }

    mat-select {
      background: #eaeaea;
      padding: 10px;
    }
  }
}

/**create booking page**/
.bkcrt_dtl {
  max-height: calc(100vh - 230px);
  overflow-y: auto;
  margin-top: 20px;
  overflow-x: hidden;
  padding-right: 20px;

  .bk_chscst_dv {
    margin-bottom: 30px;

    mat-form-field.mat-form-field {
      width: 100%;
    }
  }
}

.booking_page_main {
  padding-left: 30px;
  .vecle_otr_inr {
    text-align: center;

    h3 {
      font-size: 18px;
      line-height: normal;
      margin-top: 10px;
    }

    figure {
      height: 70px;
      width: 70px;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      border: solid 1px transparent;
      border-radius: 10px;
      margin: 0 auto;
    }
  }
}

.vecle_otr_inr.selected {
  figure {
    background: #ffffff;
    border: solid 1px #00649e;
  }

  h3 {
    font-weight: bold;
  }
}

.pck_drp_inr {
  background: #fff;
  padding: 20px;
  border-radius: 14px;

  h3 {
    font-size: 18px;
    line-height: normal;
    font-weight: bold;
    margin-bottom: 15px;
  }

  .pck_lct_dv {
    width: 100%;
    border-bottom: solid 1px #ddd;
    background: url(assets/images/locatn_pin.svg);
    background-repeat: no-repeat;
    background-position: left center;
    padding-left: 30px;
    padding-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-bottom: 20px;

    .ad_stps {
      position: absolute;
      right: 0;
      font-size: 14px;
      font-weight: bold;
      line-height: normal;
      color: #202020;
    }
  }

  .pck_lct_dv.drp_lct_dv {
    padding-right: 80px;
  }

  .pck_lct_otr {
    .dtl {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;

      p {
        font-size: 14px;
        color: #000;
      }

      .btn {
        background: #999;
        color: #fff;
        font-size: 30px;
        border-radius: 5px;
        font-weight: 700;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 4px;
        width: 40px;
        line-height: 30px;
      }
    }

    .cstm_clps {
      margin-bottom: 20px;

      .sv_btn {
        text-align: right;
      }

      .pck_lct_dv {
        background: none;
        padding-left: 0px;
        padding-bottom: 0px;
      }
    }
  }
}

.ext_stp_tp {
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    font-size: 18px;
    line-height: normal;
    font-weight: bold;
  }

  a {
    font-size: 16px;
    line-height: normal;
    font-weight: 400;
    color: #a5a5a5;
  }
}

.extra_stops {
  margin-top: 20px;
  .ext_stp_lst {
    position: relative;
    border-bottom: solid 1px #ddd;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    .close {
      position: absolute;
      right: 0;
      opacity: 1;
    }

    h4 {
      font-size: 16px;
      font-weight: bold;
      line-height: normal;
      margin-right: 10px;
    }
  }
}

.ad_ints_dv {
  padding: 30px 0px;

  mat-form-field.mat-form-field {
    width: 100%;
  }
}

li.notes mat-form-field {
  width: 100% !important;
}

textarea.form_control,
textarea.form_control.mat-input-element {
  height: 100px;
}

textarea.form_control + .mat-form-field-label-wrapper label,
textarea.form_control.mat-input-element + .mat-form-field-label-wrapper label {
  top: 15px !important;
}

.ssde.sucs_bkng_otr {
  text-align: center;
}
.sucs_bkng_otr {
  h3 {
    font-size: 22px;
    font-weight: bold;
    line-height: normal;
    margin: 25px 0 6px;
  }

  p {
    margin: 0px 0 20px;
    font-size: 18px;
    line-height: normal;
    font-weight: 400;
  }

  button {
    width: 100%;
  }
}
ul.inte_otr figure img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

.flts_rgt {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;

  .srch_dv {
    width: 300px;
    display: flex;

    mat-form-field.mat-form-field {
      width: 100%;
    }

    .form_control,
    .form_control.mat-input-element {
      padding: 10px 15px;
      border-radius: 8px;
      border: 1px solid #f3f3f3;
    }
  }
}

table.table.table-responsive,
table {
  background: #fff;
  border-radius: 14px;
  overflow: hidden;
  box-shadow: $box_shadow;
}

.table td,
.table th,
table td,
table th {
  border: unset;
  padding: 10px 8px;
  vertical-align: middle;
}

.table th,
table th {
  background: #fff;
  font-weight: 600;
  line-height: normal;
  font-size: 14px;
  color: #202020;
  padding: 15px 10px;
  text-align: center;
}

.table td,
table td {
  line-height: normal;
  font-size: 14px;
  font-weight: 400;
  color: #202020;
  text-align: center;
}

.table tr:hover td,
table tr:hover td {
  background: #fff;
}

table.table tr:first-child th {
  border-bottom: solid 1px #f1ebeb;
}

.ad_sm ngx-intl-tel-input.ng-dirty .iti__selected-flag {
  width: 50px;
}

.usr_pic {
  height: 40px;
  width: 40px;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ffffff;
}

.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #00649e;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgb(0 32 51);
}

.action {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  .actn_icn {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 3px 3px #0000000f;
  }
  .clone_icn {
    background: #10858421;
    i.fa-solid.fa-clone {
      color: #108584;
    }
  }

  .edit_icn {
    background: #0080002e;
    i.fa-solid.fa-pen {
      color: #0080009c;
    }
  }

  .trash_icn {
    background: #ff00002e;
    i.fa-solid.fa-trash {
      color: #ff0000e3;
    }
  }
  i {
    font-size: 12px;
  }
}

.custad_wrp {
  background: #fff;
}

.custmr_inrwrp {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
}

.custad_wrp {
  background: #ffffff;
  height: calc(100vh - 54px);
}

.cstaed_lt {
  padding: 0px;
  border-radius: 14px;
  width: 35%;

  h2 {
    font-size: 22px;
    line-height: normal;
    font-weight: 600;
    padding: 20px;
  }

  .cstaed_inr {
    padding: 20px;
    position: relative;
    z-index: 1;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background: transparent;
      width: 3px;
      border-radius: 50px;
    }

    h3 {
      font-size: 14px !important;
      font-weight: 600;
      color: #202020;
      line-height: normal;
    }
  }

  .cstaed_inr:hover,
  .cstaed_inr.active {
    &::before {
      background: #00649e;
    }
  }
}

.cstaed_mn {
  height: calc(100vh - 174px);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 30px;
}

.pro_in {
  position: relative !important;
  z-index: 1;

  figure {
    width: 110px;
    height: 110px;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid #dedede;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  input {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.cstaed_rt {
  width: 62%;
  border-radius: 14px;
}

.cstm_tbl {
  margin: 20px 0px;

  table {
    width: 100%;

    tr th {
      white-space: nowrap;
    }

    tr td .action {
      justify-content: center;
      gap: 10px;
    }

    .evnts {
      span {
        display: block;
        white-space: nowrap;
      }
    }
  }
}

.cstaed_dtl_lft {
  width: 50%;
  padding-right: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}

.dtl_lft_tp,
.dtl_lft_bttm {
  width: 100%;
}

.cstaed_dtl_rt {
  width: 50%;
  border-left: solid 1px #eee;
  padding-left: 20px;
}

.prnl_pro {
  padding: 0px 20px 30px;
  span.upld_flr {
    background: #fff;
    padding: 10px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 6px 1px #0000007a;
    position: absolute;
    bottom: -16px;
    left: 38px;
    cursor: pointer;
  }
}

.pro_flds_inr {
  background: #46464617;
  padding: 10px 20px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;

  mat-form-field.mat-form-field {
    width: 100%;
  }

  .form_control,
  .form_control.mat-input-element {
    background: transparent;
    padding: 0;
    border-radius: 0;
  }

  i {
    font-size: 18px;
    color: #002033;
    padding-right: 15px;
  }

  .form_control + .mat-form-field-label-wrapper label,
  .form_control.mat-input-element + .mat-form-field-label-wrapper label {
    left: 0;
  }
}

.cstaed_dtl {
  height: calc(100vh - 240px);
  padding-right: 10px;
  overflow-y: auto;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

.update_pro {
  padding: 40px 0px 0px 20px;

  button {
    width: 100%;
  }
}

.clrs_ovrvw {
  padding: 20px;

  .clrovw_lst {
    position: relative;
    z-index: 1;
    color: #202020;
    line-height: normal;
    font-weight: 400;
    margin-bottom: 10px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      height: 12px;
      width: 12px;
      background: #000;
      border-radius: 50%;
    }
  }

  .clrovw_lst.completed:before {
    background: #4ebb7a;
  }

  .clrovw_lst.ongoing:before {
    background: #00b5e0;
  }

  .clrovw_lst.canceled:before {
    background: #ee3c3c;
  }
}

.cstaed_dtl h3 {
  font-size: 20px;
  line-height: normal;
  font-weight: 600;
}

.cstaed_dtl.cstaed_ads {
  padding: 0 20px;
  display: inherit;
}

.adrs_flds_inr {
  .form_control,
  .form_control.mat-input-element {
    background: #46464617;
  }

  mat-form-field.mat-form-field {
    width: 100%;
  }

  .adrs_flds_inr {
    margin-bottom: 20px;
  }
}

.cstaed_dtl_adds {
  margin-top: 30px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  height: calc(100vh - 310px);
}

.adrs_flds_inr {
  margin-bottom: 20px;
}

.cmbng_dv {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 12px;

  .adrs_flds_inr {
    width: 49%;
  }
  span {
    img {
      width: 15px;
      height: 15px;
    }
  }
}

.ads_lst {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;

  .ads_inr {
    width: 49%;
    background: #46464617;
    border-radius: 14px;
    padding: 20px;
    margin-bottom: 15px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    cursor: pointer;

    .ads_inr_rt {
      width: 80%;
      padding-left: 10px;

      h4 {
        font-size: 20px;
        line-height: normal;
        font-weight: 600;
        margin-bottom: 2px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 6px;
      }

      .ads_tmr {
        font-size: 14px;
        line-height: normal;
        font-weight: 400;
        color: #202020;
      }
    }
  }

  .ads_inr.active {
    background: #fff;
    box-shadow: $box_shadow;
    cursor: default;
  }
}

.date_hdr_dv {
  background: #ffffff;
  box-shadow: $box_shadow;
  border-radius: 10px;
  padding: 5px 10px 10px 50px;
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    background: url(assets/images/date_pickr_icn.svg);
    background-repeat: no-repeat;
    height: 40px;
    width: 40px;
    background-position: left 10px center;
  }

  h4 {
    font-size: 18px;
    line-height: normal;
    font-weight: 600;
    margin-bottom: 1px;
  }

  .mat-form-field-type-mat-date-range-input .mat-form-field-infix {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .mat-form-field-type-mat-date-range-input
    .mat-form-field-infix
    span.mat-form-field-label-wrapper
    label {
    left: 0;
  }

  span.mat-ripple.mat-button-ripple.mat-button-ripple-round {
    display: none;
  }

  span.mat-button-wrapper {
    opacity: 0;
  }

  button.mat-focus-indicator.mat-icon-button.mat-button-base {
    position: relative;
    top: -6px;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      border-top: solid 8px #ddd;
      border-left: solid 8px transparent;
      border-right: solid 8px transparent;
    }
  }

  span.mat-button-focus-overlay {
    display: none;
  }
}

.date_hdr_dvs {
  background: #ffffff;
  box-shadow: $box_shadow;
  border-radius: 10px;
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    background-repeat: no-repeat;
    height: 40px;
    width: 40px;
    background-position: left 10px center;
  }

  h4 {
    font-size: 18px;
    line-height: normal;
    font-weight: 600;
    margin-bottom: 1px;
  }

  .mat-form-field-type-mat-date-range-input .mat-form-field-infix {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .mat-form-field-type-mat-date-range-input
    .mat-form-field-infix
    span.mat-form-field-label-wrapper
    label {
    left: 0;
  }

  span.mat-ripple.mat-button-ripple.mat-button-ripple-round {
    display: none;
  }

  span.mat-button-wrapper {
    opacity: 0;
  }

  button.mat-focus-indicator.mat-icon-button.mat-button-base {
    position: relative;
    top: -6px;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      border-top: solid 8px #ddd;
      border-left: solid 8px transparent;
      border-right: solid 8px transparent;
    }
  }

  span.mat-button-focus-overlay {
    display: none;
  }

  .form_control,
  .form_control.mat-input-element {
    font-size: 14px;
    line-height: normal;
    /* background: #fff; */
    background: #ffffff;
    padding: 15px 15px;
    border-radius: 10px;
    width: 100%;
    margin: 0;
    resize: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    color: #202020;
    font-weight: 400;
    border: unset;
    width: 100% !important;
  }
}

.date_hdr_dvk {
  background: #ffffff;
  box-shadow: $box_shadow;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  padding: 5px 10px 10px 50px;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    background: url(assets/images/filter-solid.svg);
    background-repeat: no-repeat;
    height: 23px !important;
    width: 40px;
    background-position: left 10px center;
  }

  h4 {
    font-size: 18px;
    line-height: normal;
    font-weight: 600;
    margin-bottom: 1px;
  }

  .mat-form-field-type-mat-date-range-input .mat-form-field-infix {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .mat-form-field-type-mat-date-range-input
    .mat-form-field-infix
    span.mat-form-field-label-wrapper
    label {
    left: 0;
  }

  span.mat-ripple.mat-button-ripple.mat-button-ripple-round {
    display: none;
  }

  span.mat-button-wrapper {
    opacity: 0;
  }

  button.mat-focus-indicator.mat-icon-button.mat-button-base {
    position: relative;
    top: -6px;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      border-top: solid 8px #ddd;
      border-left: solid 8px transparent;
      border-right: solid 8px transparent;
    }
  }

  span.mat-button-focus-overlay {
    display: none;
  }

  .form_control,
  .form_control.mat-input-element {
    font-size: 14px;
    line-height: normal;
    background: #ffffff;
    padding: 15px 15px;
    border-radius: 10px;
    width: 100%;
    margin: 0;
    resize: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    color: #202020;
    font-weight: 400;
    border: unset;
    width: 100% !important;
  }
}

.right_slide {
  .date_hdr_dv::before {
    content: unset;
  }
}

.date_hdr_dv2 {
  &::before {
    height: 25px;
  }

  button.mat-focus-indicator.mat-icon-button.mat-button-base {
    top: 7px;
  }
}

.date_hdr_dv3 {
  background: #46464617;
  padding: 10px;

  .mat-form-field {
    width: 100%;
  }

  &::before {
    height: 45px;
    background: none;
  }

  button.mat-focus-indicator.mat-icon-button.mat-button-base {
    top: 0px;

    &::after {
      content: unset;
    }
  }

  span.mat-button-wrapper {
    opacity: 1;
  }

  .mat-select {
    padding: 0px;
  }
}

.scdl_nw_mn {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  .date_hdr_dv3 {
    width: 48%;
  }
}

.drvr_lstng {
  .table-responsive {
    margin-top: 20px;
  }
}

.drvrvw_mn {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin-top: 30px;

  .drvr_info_lt {
    width: 43%;

    .drvr_info_inr {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      figure {
        height: 60px;
        width: 60px;
        border-radius: 50%;
        overflow: hidden;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .drvr_cnt_rt {
        width: 90%;
        padding-left: 20px;

        h4 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 20px;
          font-weight: bold;
          line-height: normal;
          position: relative;

          .drv_staus {
            display: flex;
            background: #edf8f1;
            color: #36b368;
            border-radius: 14px;
            padding: 7px 20px 7px 25px;
            font-weight: 400;
            font-size: 15px;
            position: absolute;
            right: 0;
            top: 0;
            align-items: center;
            justify-content: center;

            &::before {
              content: "";
              position: relative;
              left: -5px;
              height: 10px;
              width: 10px;
              background: #36b368;
              border-radius: 50%;
            }
          }

          .drv_staus_off {
            color: #f50808;

            &::before {
              background: #f50808;
            }
          }
        }

        h5 {
          font-size: 16px;
          line-height: normal;
          font-weight: 400;
          margin-top: 2px;
        }
      }
    }

    .drvr_vclinfo {
      background: #f8f8f8;
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      padding: 20px 80px;
      border-radius: 14px;
      margin: 30px 0 20px;

      .vclinfo_inr {
        h4 {
          font-size: 20px;
          line-height: normal;
          font-weight: 600;
          margin-bottom: 5px;
        }

        span {
          font-size: 18px;
          line-height: normal;
          font-weight: 400;
          color: #a5a5a5;
        }
      }
    }

    .crntbk_sts {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 20px;

      p {
        font-size: 18px;
        line-height: normal;
        font-weight: 400;
        padding-right: 30px;

        span {
          color: #202020;
          padding-left: 10px;
          font-weight: 600;
        }
      }

      .bkng_fnlsts {
        background: #002033;
        color: #ffffff;
        display: inline-block;
        padding: 6px 15px;
        border-radius: 50px;
        font-size: 14px;
        line-height: normal;
        font-weight: 400;
      }
    }

    .cntdtl_inr {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 11px;
      font-size: 18px;
      line-height: normal;
      font-weight: 400;
      color: #202020;

      figure {
        margin-right: 8px;
      }
    }
  }

  .drvr_info_rt {
    width: 55%;
    border-radius: 14px;
    overflow: hidden;
  }
}

.drv_ens_sc {
  margin-top: 30px;

  .table-responsive {
    margin-top: 20px;
  }
}

.drv_prnl_otr {
  padding: 0 20px;
  width: 100%;

  .drv_prnlin {
    width: 100%;
  }

  .drv_prn_flds {
    .cmbng_dv {
      .pro_flds_inr {
        width: 49%;
        ngx-intl-tel-input {
          height: 19px;
        }

        .iti input,
        .iti input[type="text"],
        .iti input[type="tel"] {
          background: transparent;
          padding-top: 0;
          padding-bottom: 0;
          height: 100%;
        }
      }
    }
  }
}

.doc_inr {
  width: 49%;

  .pro_in {
    width: 100%;

    figure {
      width: 100%;
      height: 100%;
      border-radius: 14px;
      position: relative;
    }

    .upld_flr {
      right: 0;
      bottom: -20px;
      left: 0;
      margin: 0 auto;

      i.fa.fa-camera {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin: auto;
        box-shadow: 0px 0px 6px 1px #0000007a;
      }
    }
  }

  .form_control,
  .form_control.mat-input-element {
    background: #46464617;
  }

  .doc_inr {
    margin-bottom: 19px;
  }
}

.trpl_dv {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .doc_inr {
    width: 32%;
    margin-bottom: 19px;

    mat-form-field.mat-form-field {
      width: 100%;
    }
  }
}

.drv_prn_flds {
  .trpl_dv {
    margin-top: 40px;
  }
}

.dtepc_inr .mat-form-field-flex {
  background: #46464617;
  padding: 15px;
  border-radius: 10px;

  .mat-form-field-label-wrapper label {
    left: 0;
  }

  .mat-form-field-prefix .mat-icon-button,
  .mat-form-field-suffix .mat-icon-button {
    height: 19px;
  }
}

.zone_rgt {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
  margin: 0 0 10px;
  .form_control,
  .form_control.mat-input-element {
    padding: 10px 15px;
  }
}

.zone_data {
  position: relative;
}

.zn_inpt mat-form-field.mat-form-field {
  width: 100%;
  margin-bottom: 0px;
}

.zn_inpt.zone_mp_lct mat-form-field {
  width: 100%;
  margin-bottom: 0;
}

.adtm_sc h3 {
  margin-top: 0;
}

.ad_sm {
  mat-form-field.mat-form-field {
    width: 100%;
    margin-bottom: 10px;
  }

  mat-form-field.mat-form-field input {
    /* background: #F9F9F9; */
    background: #46464617;
  }

  button {
    width: auto;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }
}

.adcsmt_otr form {
  margin-top: 20px;
}

/**login page**/
.auth_sc {
  background: url(assets/images/login_bg.jpg);
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  height: 100vh;
  overflow-y: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: url(assets/images/lgn_vctor.png);
    background-position: bottom center;
    height: 500px;
    background-size: 100%;
    background-repeat: no-repeat;
    z-index: -1;
  }

  .signup_frm {
    form {
      margin-top: 15px;
    }

    p {
      a,
      a:not([href]):not([class]) {
        cursor: pointer;
        color: #00649e;
        line-height: normal;
        font-weight: 600;
      }
    }

    h2 {
      font-size: 30px;
      line-height: normal;
      font-weight: bold;
      margin-bottom: 2px;
    }
  }

  .auth_inr {
    width: 568px;
    background: #fff;
    padding: 40px 40px;
    border-radius: 14px;
    box-shadow: 0 10px 60px #0000002e;
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-height: 700px;
    overflow: auto;

    .signup_frm {
      width: 100%;
    }

    .frm_frp {
      margin-bottom: 10px;

      mat-form-field.mat-form-field {
        width: 100%;
      }

      .form_control,
      .form_control.mat-input-element {
        background: #f2fcfe;
      }

      label {
        margin-bottom: 5px;
      }
    }

    .cmbng_dv {
      .frm_frp {
        width: 49%;
      }
    }

    .psw_vw {
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;

      i {
        position: absolute;
        right: 10px;
        color: #008bf2;
        top: 15px;
      }

      .form_control,
      .form_control.mat-input-element {
        padding-right: 45px;
      }
    }
  }
}

.frm_btn {
  margin-top: 30px;

  button {
    width: 100%;
  }
}

.signup_frm.succs_msg {
  text-align: center;

  h2 {
    margin-top: 30px;
    font-size: 26px;
  }

  p {
    font-size: 18px;
    line-height: normal;
    color: #00649e;
  }
}

.dfdfffd {
  display: flex;
  padding: 20px 0 0;
  align-items: center;
  justify-content: center;

  button {
    margin: 0 15px 0px 0px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.modal_wrp {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 10px;
  width: 100%;
  li {
    width: 32%;
    mat-label {
      line-height: 40px;
    }
  }
}

/*---------------table---------------------*/
.slct .mat-select-arrow {
  color: var(--primaryColor);
}
/*----------------------------------------*/

.ng_inputel .dropdown-menu.country-dropdown.show {
  inset: 70% 20% auto -300% !important;
}

.ng_inputell .dropdown-menu.country-dropdown.show {
  inset: 70% 20% auto -540% !important;
}

.drv_ad {
  .form_control,
  .form_control.mat-input-element {
    background: #46464617;
  }
}

.fjkjf_otr {
  max-height: 300px;
  overflow-y: auto;
  padding-right: 20px;
  min-height: 131px;
}

.fjkjf {
  display: flex;
  justify-content: space-between;

  .fjkjf_inr {
    margin-right: 10px;
  }
}

.gmnoprint {
  left: 0px !important;
}

.sttng_dtl {
  .pro_flds_inr {
    width: 49%;
  }
}

.logn_wth_dtl {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;

  label {
    margin-bottom: 0;
  }
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent
  .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent
  .mat-ripple-element {
  background: #002033;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #00649e;
}

.logn_wth {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;

  label {
    margin-bottom: 0;
    display: inline-flex;
    align-items: center;
    gap: 10px;
  }
}

.wht_lbl_dv {
  height: unset;
  overflow-y: auto;

  .cmbng_dv {
    .form-group {
      width: 49%;

      .form_control,
      .form_control.mat-input-element {
        background: #46464617;
      }

      mat-form-field.mat-form-field {
        width: 100%;
      }
    }

    .logo_strfnt {
      width: 32%;
      margin-bottom: 20px;

      .pro_in {
        width: 100%;

        figure {
          width: 100%;
          height: 300px;
          border-radius: 14px;
          background: #f1f1f1;

          img {
            object-fit: scale-down;
          }
        }

        .upld_flr {
          bottom: -15px;
          left: 0;
          margin: 0 auto;
        }
      }
    }
  }

  label {
    font-size: 14px !important;
    font-weight: 600;
    color: #292929;
  }
}

.Team,
.qestion-dialog {
  width: 100% !important;
  max-width: 30vw !important;
  .mat-dialog-container {
    position: relative;
    overflow: inherit;
  }
}
.doc_class {
  width: 100% !important;
  max-width: 50vw !important;
  max-height: 32vw;
  overflow: auto;
  .mat-dialog-container {
    position: relative;
    overflow: inherit;
  }
  h2 {
    font-size: 18px;
    font-weight: 600;
    margin: 10px 0;
  }
}
.mat-form-field {
  width: 100%;
}
.clrs_sec {
  .clr_fm {
    display: flex;
    align-items: center;
    justify-content: space-between;

    li {
      width: 32%;
      margin-right: 15px;
      height: 288px;
    }
  }
}

.whte_lble {
  .save_bttn {
    padding: 20px 0 10px;
    text-align: center;
    width: 92px;

    button {
      width: 100%;
    }
  }
}

.lg_chk mat-radio-button {
  margin-right: 20px;
}

.jhd {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 20px 0;
  .trckng_wth {
    width: 24%;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #00000040;
    padding: 12px 10px;
    margin: 5px;
    label {
      padding: 0;
      margin-bottom: 10px;
    }
  }
}

.trckng_chck {
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    font-size: 14px;
    font-weight: bold;
    padding-right: 20px;
  }

  .lg_chk label {
    margin-bottom: 0;
    margin-right: 15px;
  }
}

.integ_lble {
  padding: 0 20px;
}

.inte_otr {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 12px;

  .inte_inr {
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    width: 32%;
    position: relative;
    border: solid 1px #ddd;

    &:nth-child(3n) {
      margin-right: 0;
    }

    h3 {
      font-size: 20px;
      line-height: normal;
      font-weight: 600;
      margin: 20px 0 10px;
    }

    .mtsld_dv {
      position: absolute;
      top: 20px;
      right: 20px;
    }

    a,
    a:not([href]):not([tabindex]) {
      color: #00649e;
      cursor: pointer;
    }
  }
}

ul.cstaed_lst li {
  box-shadow: $box_shadow;
  border-radius: 12px;
  margin-bottom: 12px;
}

.sttng_inrwrp {
  .cstaed_lt {
    position: sticky;
    width: 100%;
    top: 10px;
  }

  .cstaed_rt {
    width: 70%;
    background: #fff;
  }
}

.integ_lble {
  padding: 0 20px;
  height: calc(100vh - 90px);
  overflow-y: auto;
}

.usr_profle {
  a {
    color: $text_Color !important;
    line-height: normal;
    font-weight: 500;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    figure {
      height: 40px;
      width: 40px;
      overflow: hidden;
      border-radius: 50%;
      margin-right: 10px;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .dropdown-menu {
    left: unset;
    right: 0;
    min-width: 200px;

    li {
      a {
        color: $text_Color !important;
        padding: 4px 20px;
        font-size: 16px;
        line-height: normal;
        font-weight: 400;
      }
    }
  }
}

.myprfl_wrp {
  .cstaed_rt {
    background: #fff;
    padding: 30px;

    .cstaed_dtl_lft {
      width: 100%;
      padding-right: 0;
    }
  }

  .pro_flds_inr {
    width: 48%;
    border-radius: 14px;

    mat-form-field.mat-form-field {
      padding-left: 0;
    }
  }

  .ad_pro_flds {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;

    .mat-form-field {
      display: inline-block;
      position: relative;
      text-align: left;
      width: 300px;
    }
  }
}

.iti input,
.iti input[type="text"],
.iti input[type="tel"] {
  background: #46464617;
  border: unset;
  border-radius: 10px;
  padding-top: 14px;
  padding-bottom: 14px;
  height: auto !important;
  width: 100%;
  box-shadow: unset !important;
}

ngx-intl-tel-input {
  width: 100%;
  display: block;
}

.iti.iti--allow-dropdown {
  width: 100%;
}

nav.pagination {
  justify-content: flex-end;
}

.vecle_otr_inr img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.drv_prnl_otr {
  .vecle_otr_inr {
    figure {
      height: 100px;
      width: 110px;

      img {
        height: 50px;
      }
    }
  }

  .bk_chscst_dv {
    margin-bottom: 40px;
  }
}

.cstomr_inpt {
  .iti input,
  .iti input[type="text"],
  .iti input[type="tel"].form-control {
    height: auto !important;
    line-height: normal;
    padding-top: 0;
    padding-bottom: 0;
    background: none;
  }

  .iti__selected-flag {
    padding-left: 0px;
  }
}

.cstaed_dtl_lft.cstm_lftdv {
  width: 100%;
  padding: 0 30px;

  form {
    width: 100%;
  }
}

.update_pro {
  width: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.sucs_bkng_otr.adtm_sc.adtrm_otr {
  width: 300px;
  padding: 30px 0;

  .ad_sm {
    margin-top: 20px;
  }
}

.frgot_pw {
  text-align: right;

  a,
  a:not([href]):not([tabindex]) {
    color: #00649e;
  }
}

.pck_lct_dv input {
  border: none;
  width: 100%;
}

.ext_stp_lst input {
  border: none;
  width: 75%;
}

.gnrl_btm_btn button {
  width: 10%;
  margin: 20px 0px 10px;
}

.crt_bkng {
  .bkng_sc .bkng_lft {
    width: 30%;
    padding: 10px;
    background: #fff;
  }
}

.bkcrt_dtl .bk_chscst_dv2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  label {
    width: 100%;
  }

  .btn {
    width: 28%;
    height: 45px;
    font-size: 15px;

    &:hover,
    &:focus {
      color: #fff;
    }
  }
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--primaryColor) !important;
}
.mat-slide-toggle-thumb {
  border: 2px solid #fff !important;
}
.mat-slide-toggle-thumb {
  background-color: #d0d0d0 !important;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: var(--primaryColor) !important;
}

.mat-slide-toggle-bar {
  background-color: #d0d0d0 !important;
}
.slct span {
  font-size: 12px !important;
}
.blkr td {
  font-size: 12px !important;
}
.date_hdr_dv button.mat-focus-indicator.mat-icon-button.mat-button-base:after {
  border-top: solid 5px var(--primaryColor);
  border-left: solid 5px transparent;
  border-right: solid 5px transparent;
}
.mat-select-arrow {
  color: var(--primaryColor) !important;
}
.mat-tab-label.mat-tab-label-active {
  background: black;
  border-color: black;
  color: var(--primaryColor);
}
.drvr_lstng .table-responsive {
  margin-top: 5px;
  margin-bottom: 20px;
}
.mid_sec .mat-tab-header {
  top: 7px;
}
.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: var(--primaryColor);
}
.slotModal {
  width: 100%;
}
.slotModall {
  width: 30vw !important;
  position: fixed !important;
  right: 145px;
  max-height: 540px;
  top: 129px;
  .mat-dialog-container {
    background: white;
    padding: 10px;
    border-radius: 4px;
    overflow: auto !important;
  }
}
.documentModal {
  position: fixed !important;
  top: 40px;
  right: 0px;
  height: 100vh;
  width: 40vw;
}
.drv_ens_sc .mat-tab-header {
  bottom: -4px;
}
.picr .mat-checkbox-inner-container {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0 !important;
}
.picr .mat-checkbox-layout {
  gap: 5px;
  padding: 5px;
}
.adsdriver {
  width: 55vw !important;
  .mat-dialog-container {
    position: relative;
    overflow: inherit;
  }
}
.fx-wallet {
  width: 300 !important;
}
.sos_map,
.class {
  .mat-dialog-container {
    position: relative;
    overflow: inherit;
  }
}

.mat-error {
  font-size: 12px;
  padding: 5px 10px;
}
.detil .iti input {
  background: #fff !important;
  padding-top: 14px;
  padding-bottom: 14px;
  height: auto !important;
  width: 100%;
  border: none;
  font-size: 12px;
  box-shadow: 0 2px 20px #0000000d !important;
  border-radius: 5px;
  font-weight: 400;
  color: #0009;
}
.detil .dropdown-menu.country-dropdown {
  width: 300px;
}
button.btn-primary.green-bg {
  border: none !important;
}
.view_icn {
  background-color: #ff980026;
  i {
    color: #ff9800;
  }
}

.form-group .mat-select {
  border: none;
  font-size: 12px;
  padding: 10px;
  height: 40px;
  box-shadow: 0 2px 20px #0000000d;
  border-radius: 5px;
  font-weight: 400;
  color: #0009;
  background: #fff !important;
}
.mat-button-wrapper svg {
  width: 16px;
  height: 16px;
}

.drv_ens_sc .table-responsive {
  margin-top: 0px !important;
}
.btn:hover {
  color: #fff !important;
  text-decoration: none;
}
.mat-tab-label {
  border: solid 1px var(--primaryColor);
  background: var(--primaryColor);
  border-radius: 4px;
  padding: 9px 16px !important;
  font-size: 12px !important;
  font-weight: 600;
  opacity: 1 !important;
  color: black;
}
.mid_sec {
  position: relative;
  width: 100%;
  margin-top: 20px;
}
.date_hdr_dv2 .mat-date-range-input-start-wrapper .mat-date-range-input-inner {
  left: 4px !important;
}
.tm-slot .mat-expansion-panel {
  box-shadow: none;
}
.tm-slot .mat-expansion-indicator::after {
  display: none;
}
.mat-expansion-panel .mat-expansion-panel-header:hover {
  background: none !important;
}
.tm-slot .mat-expansion-panel-header {
  height: 35px;
  background: #fff;
}
.tm-slot .mat-expansion-panel-body {
  padding: 10px;
}
.cstm_frm {
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  h5 {
    font-size: 16px;
    font-weight: 400;
    color: #202020;
    margin-bottom: 8px;
  }

  li {
    width: 48%;
  }

  .ad_instrctn {
    width: 100%;

    mat-form-field {
      width: 100%;
    }
  }

  .btns {
    width: 100%;
    text-align: right;
    margin: 10px 0px;

    .btn {
      background: green;
      color: #fff;
      font-size: 16px;
      padding: 5px 10px;
    }

    .dlt_btn {
      background: red;
      margin-left: 10px;
    }
  }
}

.scdl_nw {
  mat-radio-group {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0px;

    mat-radio-button {
      margin-right: 15px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #00649e;
  }

  .mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: #00649e;
  }
}

.cstm_frm .btns {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.cstm_hd {
  padding: 10px;

  h1 {
    margin-left: 8px;
  }
}

.sttng_wrp,
.cstaed_mn {
  height: unset;
  margin-top: 15px;
}

.title-bar_lft {
  h2 {
    font-size: 21px;
  }
}

.alct_lst {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  margin-top: 30px;

  li {
    width: 24%;
    border: solid transparent 2px;
    border-radius: 10px;
    padding: 10px;
    background: #46464617;

    figure {
      height: 200px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .cntnt {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: space-between;
      h3 {
        font-size: 16px;
      }

      i {
        display: none;
        font-size: 20px;
        color: $primary_color;
      }
    }
  }

  .active {
    border: solid $primary_color 2px;
    background: #fff;

    .cntnt i {
      display: block;
    }
  }
}

.logn_wth.wbhk_evnt {
  flex-wrap: wrap;

  .logn_wth_dtl {
    width: 100%;
    padding: 0;
    flex-wrap: wrap;

    li {
      width: 100%;
      border-bottom: solid 1px #ededed;
      padding: 10px 0 10px;
    }
  }
}

.lg_chk.rqrd_dv {
  align-items: center;
  display: flex;
  padding-right: 0;

  label {
    padding-right: 15px;
  }
}

.wht_lb_flds {
  label {
    font-size: 14px;
  }

  width: 49%;
  .pro_flds_inr {
    width: 100%;
  }
}

@media (max-width: 1700px) {
  .mini_sidebar i.fa.fa-bars {
    left: 6%;
  }

  .bk_lck_otr .bk_lck_inr {
    font-size: 14px;
  }

  .fltr_inrdrp form .srch_dv {
    width: 100%;
  }

  .fltr_inrdrp .from-group {
    width: 56%;
  }

  .bk_lck_otr .bk_lck_inr .bk_dte {
    font-size: 14px;
  }

  .bkng_lstb_dtl .bkng_dtl_inr .bkdtl_tp .bk_id h3 {
    font-size: 16px;
  }

  .bkng_lstb_dtl .bkng_dtl_inr .bkdtl_tp .bk_id h4 {
    font-size: 14px;
  }

  .bk_stus button.btn {
    padding: 6px 10px;
  }

  .btn {
    font-size: 15px;
  }

  .drvrvw_mn .drvr_info_lt .drvr_vclinfo {
    padding: 20px 8px 3px;
    width: 106%;
    display: flex;
    flex-direction: column;
  }

  .drvrvw_mn .drvr_info_lt .drvr_vclinfo .vclinfo_inr h4 {
    font-size: 18px;
  }

  .drvrvw_mn .drvr_info_lt .drvr_vclinfo .vclinfo_inr span {
    font-size: 16px;
  }
}

.highlight {
  background-color: green;
}

.cross_fixed {
  position: absolute;
  top: 16px;
  right: 22px;
}

#map * {
  -moz-transition: none;
  -webkit-transition: none;
  -o-transition: all 0s ease;
  transition: none;
}

.flter_box {
  font-size: 13px;
  font-weight: 400;
  color: #000;
  line-height: 40px;

  .mat-form-field {
    background-color: #ffffff;
  }
}

.bkng_map {
  width: 100%;
  height: 100vh;
  position: relative;
}

.ct_ng_wrp {
  top: 62px;
  left: 0px;
  display: block;
  align-items: stretch;
  justify-content: space-between;
  height: calc(100vh - 62px);
  box-shadow: -10px 0px 3px 0px #ddd;
  width: 100%;
}

.bkng_wrp {
  overflow: hidden;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  height: calc(100vh - 42px);
  width: 100%;
  position: relative;
}

.sd_br {
  position: absolute;
  z-index: 9;
  cursor: pointer;
  top: 0px;
}

.lft_bar {
  .mat-drawer-inner-container {
    height: 100vh !important;
  }
}

.mat-drawer-opened {
  max-width: 100%;
  position: relative !important;
  width: 320px !important;
}
.hme_wpr .mat-drawer-opened {
  width: 263px !important;
}

.bk_mn .mat-drawer-side.mat-drawer-end {
  border-left: unset !important;
}

.bkng_lft.bkng_rgt {
  background: #f8f8f8;
  box-shadow: 0px 3px 99px #8a8a8a12;
  padding: 10px;
  position: relative;
  height: 100%;
}

.rht_cls i.fa.fa-angle-left,
i.fa.fa-list {
  background-color: #ffffff;
  color: #000;
  display: flex;
  font-size: 18px;
  border-radius: 7px 0px 0px 7px;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
}

.right_slide .mat-drawer {
  background-color: unset;
}

.right_slide .mat-drawer-container {
  background-color: unset !important;
}

.filters_outer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
}

.trial_pck {
  width: 17% !important;
  transform: translate(-65px, 0px);

  span {
    font-size: 16px;
    font-weight: 600;
    color: $text_Color;
  }
}

.veh-img {
  width: 40px;
  height: 40px;
  margin: 0 auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 7px;
  }
}

#dialog {
  width: 500px !important;
}

.custom-style {
  background-color: brown;
  color: white;
}

.custom-styles {
  background-color: rgb(246, 255, 0);
  color: rgb(0, 0, 0);
}

a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}

a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}

.image_class {
  max-width: 24vw !important;
  pointer-events: auto;
  position: static;
}

.mat-accordion {
  position: relative;
  bottom: 0px;
  left: 0;
  right: 0;
}

.mat-step-icon .mat-icon {
  font-size: 16px;
  height: 24px !important;
  width: 16px;
}

/*introduction css*/
.intro_wrp {
  background: url(assets/images/login_bg.jpg);
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  height: 100vh;
  overflow-y: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  .main_inr {
    max-width: 100%;
    width: 80%;
  }

  .frm_wrp {
    margin: 10px 0px;

    mat-form-field {
      width: 100%;
    }

    .mat-select {
      background: #46464617;
      padding: 15px 15px;
    }
  }

  .loc_wp {
    position: relative;
    margin: 10px 0px;

    svg {
      width: 25px;
      height: 25px;
      position: absolute;
      right: 15px;
      top: 12px;
      background: #bbd6e9;
      padding: 3px;
      cursor: pointer;
      border-radius: 2px;
      fill: #4e4a4a;
    }
  }
}

/*introduction css end*/
.ap_id span {
  width: 130px;
  background: #f8f8f8;
  padding: 7px 10px;
  color: #000000de;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 18px !important;
  font-weight: 500;
  border-radius: 5px;
  display: block;
}

.mn_fdx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  .mn_inr {
    width: 100%;
    box-shadow: 0px 2px 20px #0000001c;
    padding: 5px 15px;
    margin-bottom: 15px;
    border-radius: 10px;
  }

  .app_fdx {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    .social_lnks {
      display: flex;
      gap: 10px;
      padding-top: 20px;
      figure {
        width: 130px;
      }
    }
  }
}

.slick-prev:before,
.slick-next:before {
  color: red;
}

.btn_rw {
  display: flex;
  justify-content: right;
  gap: 10px;
  margin-top: 30px;
}

.bbbt {
  margin: 0 auto;
  width: 168px;
  padding-top: 28px;
}

.btn_rw {
  display: flex;
  justify-content: right;
  gap: 10px;
  margin-top: 30px;
}

.crtbkng button {
  width: 100%;
}

.bkng_add button {
  width: 260px;
}

.signup_frm h2 {
  font-size: 20px !important;
}

.signup_frm p {
  font-size: 16px !important;
}

li.dash_antk_inr span {
  font-size: 16px !important;
}

.bkng_fd .lft p {
  width: 200px;
}

.bkdtl_tp .cmpltd_btsn {
  padding-right: 59px;
}

.brdrn {
  border: unset !important;
}

.bk_tsh {
  position: absolute;
  top: 10px;
  right: 5px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0px 0px 7px 4px #00000014;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
}
.cmpltd_btsn .view_icns.shr_dt {
  right: -10px;
}

.pg_hd .mn_hd h1 {
  padding-left: 12px;
  font-weight: 600;
  margin-bottom: 10px;
}

.dasd {
  span {
    color: #000 !important;
    font-size: 14px !important;
  }

  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #dedede;
  }
}

.mat-select-arrow {
  color: black;
}

.ryt_btm {
  padding: 0px 10px;

  h3 {
    margin-bottom: 10px;
  }
}

.sub_wp {
  .role_slc {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 12px 0px;
    margin-top: 10px;

    mat-option {
      padding: 0 !important;
      font-size: 14px;
      font-weight: 500;
      color: #000 !important;
      height: 30px;
    }

    .loginform {
      width: 49%;

      .bck-btn {
        width: 25%;
        margin: 10px auto 0px;
      }
    }
    .role_sel {
      width: 49% !important;
      margin-left: auto;
    }
  }

  .mat-tab-label-container {
    margin-top: 20px !important;
  }
  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: var(--primaryColor) !important;
  }
  .mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: var(--primaryColor);
  }
  .table-responsive table {
    width: 100%;
  }

  .form-group {
    width: 100%;
  }

  .loginform .mat-form-field {
    width: 100% !important;
  }
}

.mat-select-arrow {
  color: black;
}

.dtl_lft_tp {
  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: var(--primaryColor);
  }

  .mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: var(--primaryColor);
  }
}

.infix .mat-form-field-infix {
  width: 100% !important;
}

.infix input {
  border: 1px solid #a3a3a3;
  padding: 4px 10px;
  border-radius: 3px;
}

.mat-list-text {
  white-space: nowrap;
}
.cntnt span.mat-radio-outer-circle {
  border-color: var(--primaryColor) !important;
}
.cntnt span.mat-radio-inner-circle {
  background-color: var(--primaryColor) !important;
}

.infix {
  .mat-chip.mat-standard-chip .mat-chip-remove {
    color: red;
    opacity: 1.4;
    border: none;
  }

  .mat-standard-chip .mat-chip-remove,
  .mat-standard-chip .mat-chip-trailing-icon {
    width: 18px;
    height: 18px;
    cursor: pointer;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}

.drv_prnl_otr .drv_prn_flds .cmbng_dv .pro_flds_inrtags {
  width: 100%;
}

.hd_input {
  position: relative;

  input {
    width: 130px;
    font-size: 18px;
    font-weight: 600;
    padding: 5px 10px;
    border: unset;
  }

  i.fa.fa-pencil {
    position: absolute;
    top: 10px;
    right: 5px;
  }

  i.fa.fa-sign-in {
    color: var(--primaryColor);
  }
}

.mn_hd {
  width: 100%;
  i.fa.fa-pencil {
    color: var(--secondaryColor);
    margin-left: 5px;
  }
}

.pg_hdbutton {
  display: flex;
  margin-bottom: 10px;
  flex-direction: row-reverse;
}

.right_slide .srch_inpt {
  padding: 8px 10px 8px 36px !important;
}

li.inte_inr:hover {
  box-shadow: 0px 0px 0px 2px #00000026;
  cursor: pointer;
}

.mtsld_dv span {
  background: linear-gradient(45deg, #4ab74e, #486c49e0);
  color: #ffffffc7;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px !important;
  font-weight: 600;
}

.add_zonenma {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  justify-content: center;
  margin: 15px 0;
  form {
    width: 100%;
  }
  .zn_inpt {
    width: 49%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    gap: 20px;
    mat-label {
      line-height: 40px;
    }
  }
  button {
    width: 30%;
  }
}

.logn_wth_dtl {
  .mat-list-base {
    display: flex;
    align-items: center;
  }
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #f3d739;
}

.template_class {
  width: 100%;
  max-height: 617px;
}

.cms_class,
.class {
  width: 50%;
  max-height: 40vw;
  overflow: auto;
  .mat-dialog-container {
    position: relative;
    overflow: inherit;
  }
}

.bank_class {
  max-height: 501px;
  overflow: auto;
  width: 42vw;
  .mat-dialog-container {
    position: relative;
    overflow: inherit;
  }
}

.fleet_class {
  width: 55%;
  max-height: 44vw;
  overflow: scroll;
  .mat-dialog-container {
    position: relative;
    overflow: inherit;
  }
}
.conductor_class {
  width: 55%;
  max-height: 25vw;
  overflow: auto;
  .mat-dialog-container {
    position: relative;
    overflow: inherit;
  }
}
.showDriverByVehicle {
  width: 50%;
}

.sos_map {
  mat-dialog-container {
    padding: 10px;
    div#map {
      width: 500px !important;
      min-height: 500px !important;
    }
  }
}

.sos_alert {
  position: relative !important;
  mat-dialog-container {
    max-width: 100%;
    padding: 10px;
    width: 400px;
    height: 370px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible;
    app-notification-modal {
      width: 100%;
    }
    & {
      transition: none;
    }
  }
}

.fullPay_class {
  width: 27%;
}
.prdf {
  width: 85%;
}

.subAdmin_class {
  width: 48%;
}

.ck .ck-editor__main {
  max-height: 217px;
  overflow-y: scroll;
}

.ck.ck-editor__main > .ck-editor__editable {
  height: 200px;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #f3d739;
}

/* sunanda */
.tm_lft figure {
  width: 50px;
  height: 50px;
  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.tm_mn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
}

.dta_bx {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 10px;
}

.team_crd {
  background: #ffffff;
  box-shadow: 0px 3px 24px #aaaaaa24;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 0px;
  padding: 10px 20px;
}

.tm_rt h2 {
  font-size: 16px;
  line-height: normal;
  font-weight: 500;
}

.dta_lst {
  h3 {
    font-size: 12px !important;
    font-weight: 400;
    color: #a5a5a5;
  }

  p {
    color: #000;
    font-size: 14px !important;
    font-weight: 400;
    line-height: 24px;
  }
}

.content_wraper {
  width: 89%;
  padding: 50px 0px 0px 10px;
  background: #f9f9f9;
  margin-left: auto;
  .bkng_map div#map {
    height: 100%;
  }
}

ul.tm_bx li mat-panel-title {
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  color: #000;
}

.sdbr_lt {
  height: 100% !important;
  width: 11.5%;
  padding-bottom: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: #fff;
  transition: all 0.2s ease;
  box-shadow: 2px 0 2.94px 0.06px rgb(4 26 55 / 4%);
  z-index: 100;

  @media (max-width: 1600px) {
    width: 14.5%;
  }
  .mat-list-base .mat-list-item {
    height: 42px;
  }
}

.sdbr_mn {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.pages_content_wrapper {
  position: relative;
  overflow: hidden;
}

.logomain {
  figure {
    margin: 10px auto;
    width: 100px;
    height: 50px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
.mini_sidebar .logomain figure {
  width: 60px;
}
.mini_sidebar .mat-nav-list .mat-list-item-content {
  justify-content: center;
}
.tm_lt {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  gap: 5px;
  padding: 25px 0px 0px;
  flex-direction: column;

  figure {
    width: 20px;
    height: 20px;
    margin-right: 10px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .lt_inr {
    width: 30px;
    height: 30px;
    margin: 0;
  }
}

.active {
  p {
    color: #000;
    font-weight: 500;
    line-height: normal;
  }
}

.rrst_sc {
  z-index: 999;
  position: absolute;
  top: 122px;
  width: 25%;
  border-radius: 10px;
  padding: 20px;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 99px #8a8a8a12;
}

.hm_mn {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.add-q-wrp {
  h2 {
    font-size: 26px;
    font-weight: 600;
    line-height: normal;
    color: #000;
  }
  mat-label {
    line-height: 40px;
  }
}

.lead {
  font-size: 13px !important;
  font-family: "Bw Modelica" !important;
  font-weight: 500;
  line-height: normal;
  margin-left: 3px;
  color: #000;
  &:hover {
    margin-left: 4px;
  }
}
.sd_b_pt {
  border-bottom: 1px solid #dedede33;
  filter: drop-shadow(0px 0px 0px #dedede1a);
  span i {
    filter: opacity(0.7);
    font-size: 16px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:last-child {
    border-bottom: unset;
  }
}

.nw_bkng {
  h2 img {
    background: rgba(251, 176, 59, 0.3);
    padding: 2px;
    border-radius: 5px;
  }

  .btn.auto_assign.crtd_btn {
    background: rgba(250, 175, 59, 1);
    border-radius: 20px;
  }

  .right_slide .btn {
    padding: 5px 25px !important;
  }
}

.bkng_lstb_dtl .bkng_dtl_inr .tm_rcd {
  border: unset;
  padding-bottom: 5px;
}

.tm_record {
  max-height: unset;

  .bkng_dtl_inr {
    width: 25%;
  }

  button.btn.auto_assign {
    background: rgba(250, 175, 59, 1);
    border-radius: 20px;
    padding: 5px 25px;
  }

  h2 img {
    background: rgba(251, 176, 59, 0.3);
    padding: 2px;
    border-radius: 5px;
  }

  .bkng_fd .lft p {
    color: #000;
  }

  .lft p,
  .bkd_lt p {
    color: #000000 !important;
  }

  .lft h2 {
    color: #aaaaaa;
  }
}

.asgn-ryt {
  right: 5px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0px 0px 7px 4px #00000014;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
}

/*sunanda */
section.all_sec.g_dv_sc {
  background: #1b1f24;
}

/*sunanda*/

ul.tm_bx {
  padding-bottom: 30px;
  .team_crd {
    margin-bottom: 10px;
  }
  .mat-accordion {
    width: 100%;
  }
  .mat-expansion-panel {
    width: 100%;
    box-shadow: unset !important;
    background: unset !important ;
  }
  .mat-expansion-panel-header,
  .mat-expansion-panel-body {
    padding: 0 10px;
  }
  .bkng_dtl_inr {
    margin: 0;
  }
  .tm_record .bkng_dtl_inr {
    width: 100%;
  }
  .cmpltd_btsn {
    padding: 0;
  }
  .bkng_fd .lft {
    width: 85%;
  }
  span.lnk_act {
    width: 40px;
    margin-left: auto;
    background: #faaf3b;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    i.fa.fa-angle-double-right {
      font-size: 20px;
      color: #fff;
    }
  }
}

.mini_sidebar {
  .sdbr_lt {
    width: 80px;
  }
  h5.lead {
    display: none;
  }
  .content_wraper {
    width: 96%;
  }
  i.fa.fa-bars {
    left: 6% !important;
  }
}

.frm_wrpp {
  .fdx {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-top: 20px;
    .lftdx {
      width: 24%;
      margin-top: 20px;
    }
    .midx {
      width: 48%;
      h2 {
        font-size: 22px !important;
        border: 2px solid #e9e9e9;
        padding: 10px;
        background: #e9e9e9;
        border-radius: 7px 7px 0px 0px;
      }

      form {
        padding: 10px;
        box-shadow: 0px 2px 20px #0000001c;
        border-radius: 7px;
        .mat-form-field {
          width: 100%;
        }
      }
    }
    .rgtx {
      width: 24%;
      h2 {
        font-size: 18px !important;
        border: 2px solid #e9e9e9;
        padding: 10px;
        background: #e9e9e9;
        border-radius: 7px 7px 0px 0px;
      }
      form {
        padding: 10px;
        box-shadow: 0px 2px 20px #0000001c;
        border-radius: 7px;
        .mat-form-field {
          width: 100%;
        }
      }
    }
  }
  .mat-expansion-panel {
    background: #e9e9e96b;
  }
  .fields_outr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;

    .fields_inr {
      width: 40%;
      margin-bottom: 10px;
      h5 {
        font-size: 16px !important;
        font-weight: 300;
        white-space: nowrap;
        color: #000;
        cursor: pointer;
      }
    }
  }
}
.drv_lst {
  width: 600px;
  max-height: 530px;
  height: 530px;
  .bkng_dtl_inr {
    width: 90%;
  }
  .bkng_lstb_dtl {
    overflow-x: hidden;
    .bkng_dtl_inr {
      margin: 5px 0px 15px 1px;
      overflow-x: hidden;
    }
  }
  .txt_fdx {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;
    width: 100%;
  }
}

.gm-style-iw-d {
  overflow: hidden !important;
  padding: 2px 14px 8px 3px;
}

.gm-style .gm-style-iw-c {
  position: absolute;
  box-sizing: border-box;
  overflow: hidden;
  top: 0;
  left: 0;
  transform: translate3d(-50%, -100%, 0);
  background-color: $primary_color;
  border-radius: 8px;
  padding: 23px 15px;
  box-shadow: -2px 4px 7px 1px rgb(0 0 0 / 30%);
}
/*15/11/22*/
.s_brs .usr_profle {
  a {
    justify-content: flex-end;
    width: 100%;
  }
}
.s_brs .navbar {
  display: flex;
  justify-content: flex-end;
}
.btn_asng {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  background: #fff;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding-bottom: 30px;
  .scd_bt {
    background: #ccc;
  }
}

.drv_lst app-driver-list {
  position: relative;
}
.drv_lst .txt_fdx {
  height: 100%;
}
.drv_lst mat-dialog-container {
  padding-bottom: 0px;
  overflow: hidden;
}

.bk_t_g {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 70px;
}
.dr_pop {
  background: #fff;
  padding: 10px;
  border-radius: 7px;
  cursor: pointer;
  box-shadow: 0px 0px 3px 1px #0000001f;
  margin: 0px;
  position: relative;
  cursor: pointer;
  height: 80px;
}
.drv_lst app-booking-listings {
  position: relative;
}
.bkng_lstb_dtl.tm_record.txt_fdx {
  align-items: flex-start;
}

.ld_chkbx mat-radio-group {
  display: flex;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  grid-gap: 15px;
}
.ld_chkbx mat-radio-group .mat-radio-label {
  white-space: unset;
}

.s_brs .trial_pck {
  transform: unset;
  text-align: right;
}

.bkng_lft.bkng_rgt {
  margin-bottom: 15px;
}
.lnk_act {
  width: 40px;
  margin-left: auto;
  background: #faaf3b;
  display: inline-block;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  color: #fff;
  padding: 3px;
  .fa {
    font-size: 20px;
  }
}
// .actives:after {
//   content: "";
//   top: 0;
//   transform: translateX(100%);
//   width: 49%;
//   position: absolute;
//   bottom: 0;
//   z-index: -1;
//   -webkit-animation: slide 2s infinite;
//   animation: slide 2s infinite;
//   background: linear-gradient(
//     90deg,
//     #50545469 0,
//     #50545469 50%,
//     rgba(255, 254, 253, 0.28) 99%,
//     rgba(64, 235, 241, 0)
//   );
// }
.actives {
  background: var(--primaryColor);
  position: relative;
}
.sd_b_pt:hover {
  background: #0000ff0f;
  position: relative;
}
// @keyframes slide {
//   0% {
//     transform: translateX(-100%);
//   }
//   to {
//     transform: translateX(100%);
//   }
// }
// @-webkit-keyframes slides {
//   0% {
//     transform: translateX(-100%);
//   }
//   to {
//     transform: translateX(100%);
//   }
// }
// @keyframes slides {
//   0% {
//     transform: translateX(-100%);
//   }
//   to {
//     transform: translateX(100%);
//   }
// }
.stg_bt {
  position: fixed;
  bottom: 0;
  cursor: pointer;
  .fa {
    background: #fff;
    box-shadow: 0 6px 10px 0 rgb(32 46 77 / 8%);
    padding: 10px;
    font-size: 20px;
  }
}
.tm_lt .mat-expansion-panel-header.mat-expanded {
  height: auto;
  padding: 10px;
  border: 1px solid;
}
ul.tm_bx .mat-expansion-panel-body {
  padding: 0 10px;
}
.tm_lt .mat-expansion-panel-body {
  padding: 0 !important;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  overflow-x: scroll;
}

.bdy_wrp.custad_wrp.sttng_wrp {
  max-height: 600px;
  position: fixed;
  top: 43px;
}
.bdy_wrp.custad_wrp.sttng_wrp .cstaed_mn {
  height: 100%;
}
.sbs_mn {
  width: 85%;
  margin-left: auto;
  .d_fdx {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
}
.sb_br_mn {
  width: 11%;
}

.bkng_map button.gm-control-active.gm-fullscreen-control {
  top: unset !important;
  right: 0px;
  bottom: 90px !important;
}

.bkng_map .gmnoprint {
  top: 50px !important;
  right: unset !important;
}
.slt_pt {
  display: flex;
  margin-bottom: 10px;
}
.pcup_cls .bkng_fd {
  position: relative;
  flex-wrap: wrap;
}
.pcup_cls .bkng_fd .lft {
  width: 100%;
  flex-wrap: wrap;
}
.pcup_cls .bkng_fd .ryt {
  text-align: right;
  position: absolute;
  right: 0;
  width: auto;
}
.pcup_cls .bkdtl_tp .cmpltd_btsn {
  padding-right: 0;
}
.tm_lt .mat-accordion .mat-expansion-panel.br_bkg {
  border: 1px solid #ccc;
}
.bkg_rd .bkng_dtl_inr {
  padding: 0;
  box-shadow: unset;
}

.cmn_cmnt .cstaed_lt {
  width: 20%;
}

.cmn_cmnt .cstaed_rt {
  width: 78%;
}
.pcup_cls.bk_shw .bkdtl_tp .cmpltd_btsn {
  padding-right: 60px;
}
.drv_prnl_otr .prn_nfo .drv_prn_flds .cmbng_dv .pro_flds_inr {
  width: 100%;
}
.doc_inr label {
  text-align: center;
}

.doc_inr .pro_in figure {
  width: 350px;
  height: 170px;
  margin: 0 auto;
}

.rpt_sbr,
.rpt_sbr .cstaed_mn {
  height: calc(100vh - 54px);
}
.ctm_fm form {
  box-shadow: 0px 2px 20px #0000001c;
  padding: 5px 15px;
  margin-bottom: 15px;
  border-radius: 10px;
}
.ctm_fm form .save_bttn {
  padding-bottom: 20px;
}

.srg_mns .add_zonenma {
  position: unset;
}
.srg_mns .zone_data {
  height: auto;
}
.srg_mns .add_zonenma {
  position: unset;
  margin-top: 40px;
  flex-wrap: wrap;
}
.srg_mns .add_zonenma form {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .zn_inpt {
    width: 49%;
  }
}
.sbt_bt .btn {
  margin: 0 auto;
}
.sbt_bt {
  width: 100%;
  text-align: center;
}
.vw_bkg {
  height: 100%;
}
body.settings {
  overflow: hidden;
}
.settings div#wrppr_fdx {
  min-height: 500px;
  overflow-y: scroll;
  max-height: calc(100vh - 90px);
}

.dwn_ld {
  position: absolute;
  bottom: -9px;
  right: 0px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0px 0px 7px 4px #00000014;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
}

.cm-data {
  padding: 10px;
}

.ad_sm {
  .iti--allow-dropdown .iti__flag-container,
  .iti--separate-dial-code .iti__flag-container {
    right: auto;
    left: 0;
    right: 0;
  }
  .dropdown-menu.country-dropdown.show.ng-star-inserted {
    width: 100%;
    left: 0 !important;
    right: 0 !important;
  }
}
.ad_ints_dv textarea.form_control + .mat-form-field-label-wrapper label,
.ad_ints_dv
  textarea.form_control.mat-input-element
  + .mat-form-field-label-wrapper
  label {
  top: 5px !important;
}
.date_hdr_dv2 .mat-date-range-input-start-wrapper {
  position: unset;
}

.date_hdr_dv2 .mat-date-range-input-start-wrapper .mat-date-range-input-inner {
  left: -14px;
}

.drv_wp .pg_hd {
  flex-wrap: wrap;
  .mn_hd {
    margin-bottom: 10px;
  }
}

.bkg_mn_lt .bkng_rgt {
  overflow: hidden;
}

.bkg_mn_lt .bkng_lstb_dtl {
  max-height: calc(100vh - 260px);
}

.frm_cst mat-form-field {
  width: 100%;
}

.frm_cst {
  overflow: hidden;
}

.btn_info {
  background: transparent;
  border: none;
}

.mom {
  tr {
    text-align: center;
  }
}

.alignSpace {
  gap: 20px;
  justify-content: flex-start;
  padding: 0px 17px;
}

.slot_wrp {
  .add_fdx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    p {
      font-size: 16px !important;
      font-weight: 500;
      color: #000 !important;
    }
  }

  .row.col-md-12 {
    padding: 0;
    align-items: flex-end;
  }
}

.tm_lt mat-expansion-panel-header {
  background: #fff;
}

.add_vhl ul.modal_wrp {
  flex-wrap: nowrap;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
}

.labl_fdx {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fleet_swt {
  width: 50vw !important;
}
.actn_icn {
  button.btn.btn-primary,
  .btn.btn-secondary {
    font-size: 12px;
    border-radius: 30px;
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }
}
.vl-wrp {
  flex-wrap: wrap !important;
  .flds {
    position: relative;
    i.fa.fa-minus {
      position: absolute;
      right: 12px;
      cursor: pointer;
    }
  }
}
.cst {
  max-height: 700px !important;
  overflow: auto !important;
}
.mat-date-range-input {
  font-size: 12px !important;
}
.showRating-dialog {
  width: 100%;
  max-width: 40vw !important;
}
/*plugin module*/
.pulg-fdx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  margin: 5px 0;
  .plug-in {
    width: 24%;
    border: 1px solid #00000014;
    height: 190px;
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0 0 2px 2px #0000000d;
    position: relative;
    .feature_top {
      margin-bottom: 30px;
      figure {
        width: 100%;
        img {
          width: 25%;
          object-fit: cover;
          object-fit: contain;
          height: 100%;
        }
      }
      p {
        position: absolute;
        top: 4px;
        right: 4px;
        color: #fff;
        background: var(--primaryColor);
        width: 50px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        font-weight: 500;
        border-radius: 3px;
      }
    }
    .feature_center {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h6 {
        font-weight: 700;
        line-height: normal;
        font-size: 16px;
      }
      li.tg-list-item mat-slide-toggle {
        margin-bottom: 10px;
      }
    }
    .feature_bottom {
      padding: 10px 0;
      p {
        font-size: 12px;
        line-height: normal;
        font-weight: 500;
      }
    }
  }
}

/*plugin module end*/

.page-item.active .page-link {
  background-color: var(--primaryColor);
  border-color: #007bff;
}
.page-link {
  color: #000;
}
.mat-tab-label .mat-tab-label-content {
  color: #fff;
  font-weight: 500;
  font-size: 14px;
}
.bon mat-dialog-actions {
  gap: 10px;
}
.wlk_wrp {
  position: relative;
  figure {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #000000ba;
    box-shadow: 0px 0px 4px 0px #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -14px;
    top: -15px;
    z-index: 100000000;
    cursor: pointer;
    color: #fff;
  }
}

.mn_inr .mat-tab-header {
  margin-bottom: 15px;
}

/*zone map*/
.zone_dataa {
  max-height: 600px;
  div#map {
    height: 500px;
  }
}
.bdy_wrp::-webkit-scrollbar {
  width: 12px;
  height: 8px;
  background-color: #f5f5f5;
}

.mtrx_tb .table-responsive {
  display: block;
  width: auto;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  .sotre_id_wrap .mat-dialog-content {
    max-height: unset !important;
  }
}

.bkcrt_dtl {
  .btns {
    margin: 0 !important;
  }
}

/*country flag css*/
.ad_sl {
  .pro_flds_inr {
    ngx-intl-tel-input {
      .dropdown-menu.country-dropdown {
        width: 300px;
        .iti__country-list {
          overflow-x: hidden;
          li {
            width: 100%;
          }
        }
      }
    }
  }
}

/**********************************************responsive screen start*****************************************************************************/

@media (max-width: 2000px) {
  div#wrppr_fdx app-settings-sidebar {
    width: 15%;
  }
  div#wrppr_fdx app-general,
  div#wrppr_fdx app-customize,
  div#wrppr_fdx app-auto-allocate,
  div#wrppr_fdx app-plugins,
  div#wrppr_fdx app-add-plugins,
  div#wrppr_fdx app-api,
  div#wrppr_fdx app-web-hooks,
  div#wrppr_fdx app-billing,
  div#wrppr_fdx app-tags,
  div#wrppr_fdx app-documents,
  div#wrppr_fdx app-bank-form,
  div#wrppr_fdx app-cms,
  div#wrppr_fdx app-sub-admin,
  div#wrppr_fdx app-walkthrough,
  div#wrppr_fdx app-forms,
  div#wrppr_fdx app-external-fleet,
  div#wrppr_fdx app-fleet-documents,
  div#wrppr_fdx app-airport-charge,
  div#wrppr_fdx app-b2b-managemant,
  div#wrppr_fdx app-question-answer,
  div#wrppr_fdx app-vehicle,
  div#wrppr_fdx app-tax,
  div#wrppr_fdx app-new-plugin {
    width: 83%;
  }
}

@media (max-width: 1800px) {
  .add_zonenma {
    bottom: 0;
  }

  .bkng_fd .lft {
    width: 78%;
  }

  .mat-drawer-opened {
    width: 410px !important;
  }

  .bk_tsh {
    width: 32px;
    height: 32px;
  }

  .bk_tsh img {
    max-width: 20px;
  }

  .bkng_lstb_dtl {
    margin-top: 10px;
  }

  h2,
  h3,
  h4,
  h5 {
    font-size: 14px !important;
  }

  .bkng_lstb_dtl .bkng_dtl_inr .bkdtl_tp h2 span {
    margin-top: 3px;
    color: #afafaf;
    font-weight: 400;
  }

  .bkng_fd .ryt h2 {
    font-size: 14px !important;
    font-weight: 500;
  }

  .bkng_lstb_dtl .bkng_dtl_inr .bkdtl_tp h2 {
    margin-bottom: 5px;
  }
}

@media (max-width: 1700px) {
  .add_zonenma {
    bottom: 0;
  }

  .bkdtl_tp h2 {
    font-size: 14px !important;
  }

  .bkng_lstb_dtl .bkng_dtl_inr .bkdtl_tp h2 span {
    font-size: 13px !important;
  }

  .mat-drawer-opened {
    width: 289px !important;
  }

  .bkng_fd .lft {
    width: 80%;
  }

  .bkng_fd .lft h2 {
    font-size: 13px !important;
  }

  .bkng_fd .ryt h2 {
    font-size: 13px !important;
  }

  .bkng_fd .lft p {
    line-height: 20px;
    font-size: 13px !important;
  }

  .bk_tsh img {
    max-width: 18px;
  }

  .bk_tsh {
    width: 26px;
    height: 26px;
    top: 5px;

    img {
      max-width: 18px;
    }
  }

  .bkng_lstb_dtl .bkng_dtl_inr {
    margin: 5px 0px 12px 10px;
  }

  .bkng_fd {
    padding: 8px 0px;
  }

  .brdrn {
    border-bottom: unset !important;
    padding: 8px 0px 0px !important;
  }

  button.btn.auto_assign {
    padding: 3px 6px;
    font-size: 13px;
    font-weight: 400;
  }

  .bkng_lstb_dtl .bkng_dtl_inr {
    padding: 10px;
  }

  .bkng_fd .lft p {
    line-height: 22px;
  }
}

@media (max-width: 1600px) {
  div#wrppr_fdx app-settings-sidebar {
    width: 18%;
  }

  div#wrppr_fdx app-general,
  div#wrppr_fdx app-customize,
  div#wrppr_fdx app-auto-allocate,
  div#wrppr_fdx app-plugins,
  div#wrppr_fdx app-add-plugins,
  div#wrppr_fdx app-api,
  div#wrppr_fdx app-web-hooks,
  div#wrppr_fdx app-billing,
  div#wrppr_fdx app-tags,
  div#wrppr_fdx app-documents,
  div#wrppr_fdx app-bank-form,
  div#wrppr_fdx app-cms,
  div#wrppr_fdx app-sub-admin,
  div#wrppr_fdx app-walkthrough,
  div#wrppr_fdx app-forms,
  div#wrppr_fdx app-external-fleet,
  div#wrppr_fdx app-fleet-documents,
  div#wrppr_fdx app-airport-charge,
  div#wrppr_fdx app-b2b-managemant,
  div#wrppr_fdx app-question-answer,
  div#wrppr_fdx app-vehicle,
  div#wrppr_fdx app-tax,
  div#wrppr_fdx app-add-role-configuration,
  div#wrppr_fdx app-new-plugin {
    width: 80%;
  }
}

@media (max-width: 1500px) {
  .bkg_mn_lt .bkng_lstb_dtl {
    max-height: calc(100vh - 220px);
  }
  .sbs_mn {
    width: 79%;
  }
  .sb_br_mn {
    width: 17%;
  }
  .flts_rgt .srch_dv {
    width: 280px;
  }
  div#wrppr_fdx app-settings-sidebar {
    width: 24%;
  }
  app-status-configuration {
    width: 78%;
  }
  .add_zonenma {
    width: 90%;
  }

  .add_zonenma {
    bottom: 0;
  }

  .bkng_wrp .ryt_btm {
    max-height: 423px;
    overflow-y: scroll;
  }

  .bkng_fd .lft {
    width: 85%;
  }

  .mat-drawer-opened {
    width: 270px !important;
  }

  .fltr_inrdrp form {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
  }

  .fltr_inrdrp form .srch_dv {
    width: 100%;
  }

  .fltr_inrdrp .from-group {
    width: 46%;
  }

  .vecle_otr_inr figure {
    height: 70px;
    width: 70px;
  }

  nav.navbar.navbar-expand-md .nv_hd_rt .navbar-nav li {
    margin: 0 5px;
  }

  nav.navbar.navbar-expand-md .nv_hd_rt .navbar-nav li a {
    font-size: 14px;
    font-weight: 600;
    color: #000;
  }

  .bkdtl_tp h2 {
    font-size: 14px !important;
    font-weight: 500;

    span {
      font-size: 13px !important;
      font-weight: 400 !important;
    }
  }

  .bkng_lstb_dtl .bkng_dtl_inr {
    padding: 8px;
  }

  .bkng_fd .ryt h2 {
    font-size: 13px !important;
  }

  button.btn.auto_assign {
    font-size: 12px;
  }

  .pg_hd .mn_hd h1 {
    padding-left: 12px;
    font-size: 18px !important;
  }
}

@media (max-width: 1600px) {
  .content_wraper {
    width: 87%;
  }
}

@media (max-width: 1400px) {
  div#wrppr_fdx app-settings-sidebar {
    width: 22%;
  }
  div#wrppr_fdx app-general,
  div#wrppr_fdx app-customize,
  div#wrppr_fdx app-auto-allocate,
  div#wrppr_fdx app-plugins,
  div#wrppr_fdx app-add-plugins,
  div#wrppr_fdx app-api,
  div#wrppr_fdx app-web-hooks,
  div#wrppr_fdx app-billing,
  div#wrppr_fdx app-tags,
  div#wrppr_fdx app-documents,
  div#wrppr_fdx app-bank-form,
  div#wrppr_fdx app-cms,
  div#wrppr_fdx app-sub-admin,
  div#wrppr_fdx app-walkthrough,
  div#wrppr_fdx app-forms,
  div#wrppr_fdx app-external-fleet,
  div#wrppr_fdx app-fleet-documents,
  div#wrppr_fdx app-airport-charge,
  div#wrppr_fdx app-b2b-managemant,
  div#wrppr_fdx app-question-answer,
  div#wrppr_fdx app-vehicle,
  div#wrppr_fdx app-tax,
  div#wrppr_fdx app-new-plugin {
    width: 75%;
  }
  .lead {
    font-size: 12px !important;
  }
  .pulg-fdx .plug-in {
    width: 32%;
  }
  .table td,
  table td,
  .table th,
  table th {
    font-size: 12px;
  }

  app-status-configuration {
    width: 76%;
  }
  h2,
  h3,
  h4,
  h5 {
    font-size: 14px !important;
  }
  ul.tm_bx .bkng_fd .lft {
    width: 100%;
  }
  .tm_record button.btn.auto_assign {
    width: 70px;
  }
  .mat-drawer-opened {
    width: 300px !important;
  }
  .add_zonenma {
    width: 90%;
  }
  body.mini_sidebar .content_wraper {
    width: 95%;
  }
  body.mini_sidebar i.fa.fa-bars {
    left: 10% !important;
  }
}

@media (max-width: 1200px) {
  div#wrppr_fdx app-settings-sidebar {
    width: 23%;
  }
  app-status-configuration {
    width: 75%;
  }
  .content_wraper {
    width: 85%;
  }
  .sdbr_lt {
    width: 17.5%;
  }
}

.NgxEditor {
  background: white;
  color: #000;
  background-clip: padding-box;
  border-radius: 4px;
  border: 1px solid rgba(0,0,0,.2);
  position: relative;
  max-height: 389px;
  overflow: auto;
}

.NgxEditor__Content p {
  margin: 0 0 10px;
  color: black;
}

/*****************************************************************************responsive screen end*******************************************************************************************************************/
